const Security = ({Header, Footer }) => {

    return(
      <div>
  <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
    <div className="flex flex-col" />
  </div>
  <div className="md:container md:mx-auto px-4 lg:px-8">
    {Header}
    <div className="dark:block w-3/4 w-48" />
    <div className="lg:flex gap-20 px-4 py-6 lg:pt-12 items-start">
      <div className="sticky top-0 sm:top-10 z-40 lg:w-3/12 pb-12">
        <div className="block lg:hidden bg-gray-900 p-4 rounded-lg -mx-2 flex items-center justify-between">
          <div className="pl-2 opacity-30">Security</div>
          <div className="w-fit" data-testid="flowbite-tooltip-target">
            <button
              type="button"
              className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
            >
              <span className="flex items-center rounded-md text-xs px-2 py-1">
                Quick Navigation
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="ml-1.5 h-3 w-3"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div
            data-testid="flowbite-tooltip"
            tabIndex={-1}
            className="z-30 w-fit rounded-lg divide-y divide-gray-100 shadow-sm transition-opacity duration-100 invisible opacity-0 bg-white text-gray-900 dark:bg-gray-900 border border-neutral-200 dark:border-gray-700 dark:border-opacity-70 dark:text-white rounded"
            id=":ro:"
            role="tooltip"
            style={{ position: "absolute", top: "4099.5px", left: 8 }}
          >
            <div className="text-sm text-gray-700 dark:text-gray-200">
              <ul className="py-1">
                <li className="flex items-center justify-start mx-2 mt-2 mb-1 py-2 px-2 text-sm text-gray-700 cursor-pointer hover:bg-neutral-100 hover:bg-opacity-50 dark:text-gray-200 dark:hover:bg-gray-850 dark:hover:bg-opacity-50 dark:hover:text-white">
                  <div className="w-64 sm:w-96">
                    <div className="space-y-1">
                      <div className="opacity-30 text-sm pb-2">Security:</div>
                      <a href="#" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Overview
                        </div>
                      </a>
                      <a href="#audits" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Audits
                        </div>
                      </a>
                      <a href="#bug-bounty" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Bug Bounty
                        </div>
                      </a>
                      <a href="#contracts" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Contracts
                        </div>
                      </a>
                      <div className="opacity-20 text-sm pb-2 pt-8">Docs</div>
                      <a href="/docs" className="block">
                        <div className="flex justify-between items-center bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Documentation
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={12}
                            height={12}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-plus"
                          >
                            <path d="M5 12h14" />
                            <path d="M12 5v14" />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="hidden lg:block">
          <div className="space-y-1">
            <div className="opacity-30 text-sm pb-2">Security:</div>
            <a href="#" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Overview
              </div>
            </a>
            <a href="#audits" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Audits
              </div>
            </a>
            <a href="#bug-bounty" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Bug Bounty
              </div>
            </a>
            <a href="#contracts" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Contracts
              </div>
            </a>
            <div className="opacity-20 text-sm pb-2 pt-8">Docs</div>
            <a href="/docs" className="block">
              <div className="flex justify-between items-center bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Documentation
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-plus"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5v14" />
                </svg>
              </div>
            </a>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-7/12">
        <h1 className="mb-6 text-2xl text-gray-600 dark:text-gray-400">
          Security Overview
        </h1>
        <div id="" />
        <p className="mb-4">
          As a commitment towards the safety of our users and partners, we want
          to be transparent about the changes and the status of the security
          audits of our smart contracts.
        </p>
        <div>
          <p className="mb-4">
            Before moving forward, we'd like to remind our users that security
            audits do not eliminate risks completely and that every user should
            read and agree to our legal disclaimer before they continue using
            this website.
          </p>
          <p className="mb-4">
            For security reports, please reach out to us on{" "}
            <a
              className="text-primary underline hover:no-underline underline-offset-2"
              href="https://discord.gg/velodrome"
            >
              Discord
            </a>
            , or to the contacts provided on our Github page.
          </p>
        </div>
        <div id="audits" />
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Audits
        </h2>
        <svg
          data-src="/partners/spearbit.svg"
          className="css-14nl7q6 injected-svg my-8 w-48"
          src=""
          viewBox="0 0 270 55"
          xmlns="http://www.w3.org/2000/svg"
        >
          <title xmlns="http://www.w3.org/2000/svg">Spearbit</title>
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M57.1118 31.9C57.3318 34.54 58.1678 36.784 59.7958 39.204C61.5118 41.536 64.7678 43.12 69.1238 43.12C72.4678 43.12 75.1518 42.24 77.1318 40.524C79.1558 38.764 80.1678 36.476 80.1678 33.66C79.9918 30.624 79.3758 28.644 77.6158 27.192C76.3838 26.136 75.8998 25.696 74.1398 24.992C73.2598 24.64 72.6438 24.42 72.2478 24.288L70.3118 23.672C69.3878 23.364 68.6398 23.144 68.1558 23.012C67.6718 22.88 67.0998 22.66 66.4398 22.352C64.5478 21.516 63.8438 20.724 63.6678 19.14C63.6678 17.204 65.6038 15.62 68.7718 15.62C71.8958 15.62 73.9198 17.072 74.7998 20.02L79.2878 19.096C78.0118 13.948 74.0958 11 68.7718 11C65.6918 11 63.2718 11.792 61.5118 13.42C59.7958 15.004 58.9158 16.896 58.9158 19.14C58.9158 21.692 59.7518 23.628 61.4678 24.904C63.1838 26.18 65.6918 27.28 68.9478 28.116C73.5678 29.304 75.3278 30.8 75.3278 33.66C75.3278 36.872 72.7758 38.5 69.1238 38.5C65.3398 38.368 63.5358 36.916 62.6118 34.804C61.9518 33.22 61.8638 32.472 61.6878 31.108L57.1118 31.9Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M91.261 42.46V30.58H97.729C100.413 30.58 102.657 29.656 104.417 27.852C106.221 26.004 107.101 23.76 107.101 21.076C107.101 18.48 106.177 16.236 104.329 14.432C102.525 12.584 100.325 11.66 97.729 11.66H86.421V42.46H91.261ZM91.261 16.28H97.729C100.193 16.28 102.261 18.04 102.261 21.076C102.261 23.76 100.369 25.96 97.729 25.96H91.261V16.28Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M133.286 42.46V37.84H119.074V28.556H132.01V23.936H119.074V16.28H133.286V11.66H114.234V42.46H133.286Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M142.575 42.46L144.819 35.64H156.127L158.327 42.46H163.299L152.607 11.66H148.383L137.691 42.46H142.575ZM150.451 18.568L154.763 31.504H146.227L150.451 18.568Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M173.248 42.46V29.788H177.648L183.676 42.46H188.912L182.576 29.084L183.148 28.82C184.512 28.248 187.988 25.652 187.988 20.724C187.988 18.172 187.064 16.016 185.26 14.3C183.456 12.54 181.344 11.66 178.836 11.66H168.408V42.46H173.248ZM173.248 16.28H178.836C181.168 16.28 183.148 17.996 183.148 20.812C183.148 24.596 180.112 25.168 178.836 25.168H173.248V16.28Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M207.178 42.46C209.774 42.46 211.974 41.668 213.778 40.04C215.582 38.324 216.506 35.992 216.506 33.132C216.418 30.228 215.318 28.248 214.13 27.104C213.294 26.224 213.074 26.092 212.898 25.96L213.514 25.344C214.35 24.508 215.362 22.396 215.362 20.152C215.362 17.732 214.526 15.752 212.898 14.124C211.27 12.496 209.246 11.66 206.826 11.66H194.462V42.46H207.178ZM199.302 16.28H206.826C208.762 16.28 210.522 17.644 210.522 20.152C210.522 23.496 208.014 23.936 206.826 23.936H199.302V16.28ZM199.302 28.556H207.178C209.642 28.556 211.666 30.184 211.666 33.132C211.666 37.268 208.586 37.84 207.178 37.84H199.302V28.556Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M241.107 42.46V37.84H233.847V16.28H241.107V11.66H221.791V16.28H229.007V37.84H221.791V42.46H241.107Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M260.296 42.46V16.28H269.712V11.66H246.04V16.28H255.456V42.46H260.296Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M36.058 39.697C34.7805 39.6117 33.4988 39.6112 32.2214 39.6955C32.1425 39.7007 32.0637 39.7063 31.9849 39.7121C26.445 40.1368 22.0199 42.2085 18.9752 45.7649L9.69018 41.1163C10.7121 36.548 9.71918 31.7643 6.73718 27.0747C6.69442 27.0078 6.6514 26.9411 6.6081 26.8745C5.91007 25.8018 5.14167 24.7765 4.30792 23.8053L36.0727 8.33654e-06L36.058 39.697ZM31.069 9.99816L31.0598 34.7731C26.1092 35.2396 21.6597 36.864 18.0703 39.7202L15.0713 38.2187C15.2065 33.6338 13.8404 29.0981 11.2451 24.8546L31.069 9.99816Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            d="M7.37158 46.7684L15.8397 51.0089L13.8488 54.9845L5.38072 50.744L7.37158 46.7684Z"
            fill="currentColor"
          />
        </svg>
        <p className="mb-4">
          Velodrome Finance was audited by our partners from{" "}
          <a
            className="text-primary underline hover:no-underline underline-offset-2"
            href="https://spearbit.io"
          >
            Spearbit
          </a>
          . The audit took place between{" "}
          <strong>7th and 20th of February in 2023</strong>, followed by a two
          week period for fixes. On <strong>22nd of May</strong> we requested a
          second audit from the same team in order to finalize any findings
          after the first round.
        </p>
        <p className="mb-4">
          The Spearbit audit completed on <strong>16th of June, 2023</strong>{" "}
          and the report was published on <strong>17th of July, 2023</strong>{" "}
          after it has been shared with Velodrome v1 forks.
        </p>
        <a
          href="https://github.com/spearbit/portfolio/raw/master/pdfs/Velodrome-Spearbit-Security-Review.pdf"
          className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg my-8 w-1/2"
        >
          <span className="flex items-center rounded-md text-sm px-3 py-1.5">
            <strong>Spearbit Audit Report</strong>
          </span>
        </a>
        <div id="bug-bounty" />
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Bug Bounty Programs
        </h2>
        <svg
          data-src="/partners/immunefi.svg"
          className="injected-svg my-8 w-48"
          src=""
          data-ember-extension={1}
          width={187}
          viewBox="0 0 187 42"
          height={42}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <g clipRule="evenodd" fillRule="evenodd">
              <path d="m10.4146 17.8578 12.9036 13.1352 12.7658-12.9949 10.4669 10.6547-12.1612 12.3795-3.1285-3.0998 9.116-9.2797-4.2932-4.3703-12.7658 12.995-12.9036-13.1352-4.24095 4.3171 9.25465 9.4208-3.1285 3.0998-12.2998-12.5206z" />
              <path d="m36.1364 24.142-12.9036-13.1351-12.7657 12.9949-10.46693978-10.6548 12.16113978-12.379406 3.1285 3.099806-9.116 9.2796 4.2933 4.3704 12.7657-12.995 12.9036 13.1352 4.241-4.3172-9.2547-9.42074 3.1285-3.09981 12.2998 12.52055z" />
              <path d="m21.3066 40.7237v-8.2757h4.2137v8.2757z" />
              <path d="m21.3066 9.55185v-8.27566h4.2137v8.27566z" />
            </g>
            <path d="m60.7498 10.738h-4.1988v21.1406h4.1988z" />
            <path d="m82.048 16.3167c-1.9086 0-3.729.8222-4.6392 2.6133-.9396-1.7911-2.7013-2.6133-4.7567-2.6133-1.8498 0-3.2591.7047-3.9932 1.9673l-.4404-1.6736h-3.8171v15.2682h4.1107v-8.5444c0-2.2608 1.1745-3.3472 2.9068-3.3472 1.6443 0 2.7601 1.0277 2.7601 3.5234v8.3682h3.9638v-8.4563c.0587-2.3783 1.2919-3.4353 2.9068-3.4353 1.6737 0 2.7601 1.0277 2.7601 3.3179v8.603h4.14v-9.4251c0-4.2282-2.8481-6.1661-5.9017-6.1661z" />
            <path d="m108.858 16.3167c-1.909 0-3.729.8222-4.639 2.6133-.94-1.7911-2.702-2.6133-4.7569-2.6133-1.8498 0-3.2592.7047-3.9932 1.9673l-.4405-1.6736h-3.817v15.2682h4.1107v-8.5444c0-2.2608 1.1744-3.3472 2.9068-3.3472 1.6443 0 2.7601 1.0277 2.7601 3.5234v8.3682h3.964v-8.4563c.058-2.3783 1.292-3.4353 2.907-3.4353 1.673 0 2.76 1.0277 2.76 3.3179v8.603h4.14v-9.4251c0-4.2282-2.848-6.1661-5.902-6.1661z" />
            <path d="m128.034 16.6104v8.3681c0 2.2609-1.057 3.6115-3.083 3.6409-1.938.0587-3.171-1.0864-3.171-3.4647v-8.5443h-3.905v9.0728c0 3.9051 2.26 6.489 6.166 6.489 1.82 0 3.141-.8222 3.934-1.9673l.206 1.6737h3.758v-15.2682z" />
            <path d="m144.002 16.3167c-2.29 0-3.817.8515-4.61 2.1141l-.235-1.8204h-3.817v15.2682h4.111v-8.0746c0-2.4664 1.174-3.9638 3.347-3.9638 1.997 0 3.201 1.2919 3.201 3.817v8.2214h4.14v-9.0729c0-3.9051-2.232-6.489-6.137-6.489z" />
            <path d="m168.116 24.0096c0-4.6686-3.025-7.6929-7.576-7.6929s-7.604 3.1711-7.604 7.9278c0 4.786 3.053 7.957 7.634 7.957 3.846 0 6.753-2.1434 7.428-5.3145h-4.14c-.469 1.3213-1.673 1.9966-3.171 1.9966-1.967 0-3.553-1.2625-3.611-3.5234h11.04zm-7.576-4.4924c1.791 0 3.289 1.0277 3.465 3.1417h-6.9c.176-2.114 1.674-3.1417 3.435-3.1417z" />
            <path d="m169.721 16.6104v3.5821h2.349v11.6861h4.14v-11.6861h3.494v-3.5821h-3.494v-1.5562c0-1.0864.293-1.9085 1.967-1.9085h1.527v-3.3473h-2.437c-3.847 0-5.197 1.8792-5.197 4.8447v1.9673z" />
            <path d="m182.419 14.2614h4.169l.059-4.463h-4.169zm.059 17.6172h4.11v-15.2682h-4.11z" />
          </g>
        </svg>
        <p className="mb-4">
          Velodrome Finance launched a bug bounty program on{" "}
          <strong>29th of June</strong> in collaboration with Immunefi.
        </p>
        <a
          href="https://immunefi.com/bounty/velodromefinance/"
          className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg my-8 w-1/2"
        >
          <span className="flex items-center rounded-md text-sm px-3 py-1.5">
            <strong>Velodrome on Immunefi</strong>
          </span>
        </a>
        <p className="mb-4">
          The Velodrome team's $100,000 bounty was matched by the Optimism
          Foundation. The program is offering up to <strong>$200,000</strong> in
          bounty for smart contract reports.
        </p>
        <div id="emergency" />
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Emergency Council
        </h2>
        <p className="mb-4">
          To support the health of the protocol and ecosystem, there is a
          Council consisting of members from the Velodrome team and prominent
          figures from within the Optimism.
        </p>
        <p className="mb-4">
          The Council can be spawned in case of emergency situations or by
          passing a governance proposal that requires the council's help to be
          executed.
        </p>
        <p className="mb-4">
          The Council address is at{" "}
          <strong>
            <code>0x838352F4E3992187a33a04826273dB3992Ee2b3f</code>
          </strong>{" "}
          and it:
        </p>
        <ul className="mb-8 list-inside list-disc">
          <li>
            can set a new <code>emergencyCouncil</code>
          </li>
          <li>can kill a gauge</li>
          <li>can revive a gauge</li>
          <li>can set a custom name or symbol for a pool</li>
          <li>
            can activate or deactivate (m)veNFTs (superseded by the governance
            once enabled)
          </li>
        </ul>
        <div id="contracts" />
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Contract Addresses
        </h2>
        <p className="mb-4">
          Velodrome Finance smart contracts can be found on Optimistic Etherscan
          at the links below. All contracts are immutable.
        </p>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          v2 Contracts
        </h3>
        <br />
        <div
          data-testid="table-element"
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
        >
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Contract Name</th>
                <th className="px-6 py-3">Address</th>
              </tr>
            </thead>
            <tbody className="font-mono">
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">ArtProxy</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x4A9eA0dd5649eC4B6745c60d1769e2184C1782DD">
                      0x4A9eA0dd5649eC4B6745c60d1769e2184C1782DD
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Distributor</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x9D4736EC60715e71aFe72973f7885DCBC21EA99b">
                      0x9D4736EC60715e71aFe72973f7885DCBC21EA99b
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">FactoryRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xF4c67CdEAaB8360370F41514d06e32CcD8aA1d7B">
                      0xF4c67CdEAaB8360370F41514d06e32CcD8aA1d7B
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Forwarder</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x06824df38D1D77eADEB6baFCB03904E27429Ab74">
                      0x06824df38D1D77eADEB6baFCB03904E27429Ab74
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">GaugeFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x8391fE399640E7228A059f8Fa104b8a7B4835071">
                      0x8391fE399640E7228A059f8Fa104b8a7B4835071
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">GaugeSinkDrain</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x987E7922367B23C4A5fa34C8C5B1385174A095d6">
                      0x987E7922367B23C4A5fa34C8C5B1385174A095d6
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">ManagedRewardsFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xcDd9585005095ac7447d1fDbC990C5CFB805cff0">
                      0xcDd9585005095ac7447d1fDbC990C5CFB805cff0
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Minter</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x6dc9E1C04eE59ed3531d73a72256C0da46D10982">
                      0x6dc9E1C04eE59ed3531d73a72256C0da46D10982
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">PoolFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xF1046053aa5682b4F9a81b5481394DA16BE5FF5a">
                      0xF1046053aa5682b4F9a81b5481394DA16BE5FF5a
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Router</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xa062aE8A9c5e11aaA026fc2670B0D65cCc8B2858">
                      0xa062aE8A9c5e11aaA026fc2670B0D65cCc8B2858
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">SinkConverter</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x585Af0b397AC42dbeF7f18395426BF878634f18D">
                      0x585Af0b397AC42dbeF7f18395426BF878634f18D
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">SinkDrain</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xda03Dc399aF3F1545748243aAFbC5050A63B17eC">
                      0xda03Dc399aF3F1545748243aAFbC5050A63B17eC
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">SinkManager</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x5aeE5F0E6C2055EbD776DB25F48f6c9A68ABcdaE">
                      0x5aeE5F0E6C2055EbD776DB25F48f6c9A68ABcdaE
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">SinkManagerFacilitator...</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x45fF00822E8235b86Cb605ac8295c14628cE78a4">
                      0x45fF00822E8235b86Cb605ac8295c14628cE78a4
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">VELO</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db">
                      0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Voter</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x41C914ee0c7E1A5edCD0295623e6dC557B5aBf3C">
                      0x41C914ee0c7E1A5edCD0295623e6dC557B5aBf3C
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">VotingEscrow</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xFAf8FD17D9840595845582fCB047DF13f006787d">
                      0xFAf8FD17D9840595845582fCB047DF13f006787d
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">VotingRewardsFactory</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x756E7C245C69d351FfFBfb88bA234aa395AdA8ec">
                      0x756E7C245C69d351FfFBfb88bA234aa395AdA8ec
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div
          data-testid="table-element"
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
        >
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Relay Contract Name</th>
                <th className="px-6 py-3">Address</th>
              </tr>
            </thead>
            <tbody className="font-mono">
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">RelayFactoryRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xe9F00f2e61CB0c6fb00A2e457546aCbF0fC303C2">
                      0xe9F00f2e61CB0c6fb00A2e457546aCbF0fC303C2
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">KeeperRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xfe97A8c1C90c829a246914f9576cbdA6D26A5AA2">
                      0xfe97A8c1C90c829a246914f9576cbdA6D26A5AA2
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">OptimizerRegistry</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0xC357369855289DE6e6cEA50C6FdCB327ec4357c9">
                      0xC357369855289DE6e6cEA50C6FdCB327ec4357c9
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <br />
        <div
          data-testid="table-element"
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
        >
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Misc Contract Name</th>
                <th className="px-6 py-3">Address</th>
              </tr>
            </thead>
            <tbody className="font-mono">
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">Prices Oracle</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x07F544813E9Fb63D57a92f28FbD3FF0f7136F5cE">
                      0x07F544813E9Fb63D57a92f28FbD3FF0f7136F5cE
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">VelodromeLibrary</td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x253ca289cd921ba4a18c053c00a80c9660d508f8">
                      0x253ca289cd921ba4a18c053c00a80c9660d508f8
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          v1 Contracts
        </h3>
        <br />
        <div
          data-testid="table-element"
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
        >
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th className="px-6 py-3">Contract Name</th>
                <th className="px-6 py-3">Address</th>
              </tr>
            </thead>
            <tbody className="font-mono">
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <p className="mb-4">Velo</p>
                </td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x3c8B650257cFb5f272f799F5e2b4e65093a11a05#code">
                      0x3c8B650257cFb5f272f799F5e2b4e65093a11a05
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <p className="mb-4">PairFactory</p>
                </td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x25CbdDb98b35ab1FF77413456B31EC81A6B6B746#code">
                      0x25CbdDb98b35ab1FF77413456B31EC81A6B6B746
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <p className="mb-4">Router</p>
                </td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x9c12939390052919aF3155f41Bf4160Fd3666A6f#code">
                      0x9c12939390052919aF3155f41Bf4160Fd3666A6f
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <p className="mb-4">VotingEscrow</p>
                </td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x9c7305eb78a432ced5C4D14Cac27E8Ed569A2e26#code">
                      0x9c7305eb78a432ced5C4D14Cac27E8Ed569A2e26
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <p className="mb-4">RewardsDistributor</p>
                </td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x5d5Bea9f0Fc13d967511668a60a3369fD53F784F#code">
                      0x5d5Bea9f0Fc13d967511668a60a3369fD53F784F
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <p className="mb-4">Voter</p>
                </td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x09236cfF45047DBee6B921e00704bed6D6B8Cf7e#code">
                      0x09236cfF45047DBee6B921e00704bed6D6B8Cf7e
                    </a>
                  </p>
                </td>
              </tr>
              <tr
                data-testid="table-row-element"
                className="odd:bg-white even:bg-gray-50 odd:dark:bg-gray-800 even:dark:bg-gray-700"
              >
                <td className="px-6 py-4">
                  <p className="mb-4">Minter</p>
                </td>
                <td className="px-6 py-4">
                  <p className="mb-4">
                    <a href="https://optimistic.etherscan.io/address/0x3460Dc71A8863710D1C907B8d9D5DBC053a4102d#code">
                      0x3460Dc71A8863710D1C907B8d9D5DBC053a4102d
                    </a>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    {Footer}
  </div>
</div>


)};

export default Security;