const Brand = ({Header, Footer }) => {

    return(
<div>
  <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
    <div className="flex flex-col" />
  </div>
  <div className="md:container md:mx-auto px-4 lg:px-8">
    {Header}
    <div className="md:bg-worm md:dark:bg-worm-light bg-contain lg:bg-auto bg-no-repeat bg-[center_top_-270px]">
      <div className="flex flex-col gap-12 py-24">
        <div className="mx-auto bg-white dark:bg-gray-100 p-6 rounded-full">
          <img src="velodrome.svg" className="h-12" alt="Velodrome Finance" />
        </div>
        <div className="mx-auto text-center space-y-4">
          <div className="text-xs sm:text-sm uppercase opacity-30">
            Velodrome BRAND KIT
          </div>
          <div className="md:w-96 text-3xl font-bold text-center">
            Guide and Logos for the Velodrome Brand
          </div>
        </div>
        <div className="xl:w-9/12 mx-auto pt-6 space-y-4">
          <div className="pb-20 text-lg text-center px-8 md:px-32 opacity-30">
            Velodrome Finance is a next-generation AMM that combines the best of
            Curve, Convex and Uniswap, designed to serve as Optimism's central
            liquidity hub. Velodrome NFTs vote on token emissions and receive
            incentives and fees generated by the protocol.
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
            <div className="shadow-sm rounded-lg xl:border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
              <div className="h-full flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 text-sm first:rounded-t-md last:rounded-b-md">
                <div>
                  <div className="bg-neutral-200/50 dark:bg-gray-900/30 py-12 flex justify-center rounded-t-md">
                    <div className="w-16 h-16 flex items-center justify-center bg-white dark:bg-gray-100 p-2 rounded-full">
                      <img
                        src="velodrome.svg"
                        className="h-9"
                        alt="Velodrome Finance"
                      />
                    </div>
                  </div>
                  <div className="p-6 text-base">Token</div>
                </div>
                <div className="p-6">
                  <div className="text-gray-600 dark:text-gray-400 text-xs">
                    <div className="flex justify-between">
                      <div className="text-gray-400 dark:text-gray-600">
                        Download
                      </div>
                      <div className="flex">
                        <div className="text-gray-400 dark:text-gray-600 pr-2">
                          Vector
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/token.svg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SVG
                          </a>
                        </div>
                        <div className="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                          Raster
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/token.png"
                            target="_blank"
                            rel="noreferrer"
                          >
                            PNG
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-sm rounded-lg xl:border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
              <div className="h-full flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 text-sm first:rounded-t-md last:rounded-b-md">
                <div>
                  <div className="bg-neutral-200/50 dark:bg-gray-900/30 py-12 flex justify-center rounded-t-md">
                    <div className="w-16 h-16 flex items-center justify-center">
                      <img
                        src="velodrome.svg"
                        className="h-12"
                        alt="Velodrome Finance"
                      />
                    </div>
                  </div>
                  <div className="p-6 text-base">Logo</div>
                </div>
                <div className="p-6">
                  <div className="text-gray-600 dark:text-gray-400 text-xs">
                    <div className="flex justify-between">
                      <div className="text-gray-400 dark:text-gray-600">
                        Download
                      </div>
                      <div className="flex">
                        <div className="text-gray-400 dark:text-gray-600 pr-2">
                          Vector
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/symbol.svg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SVG
                          </a>
                        </div>
                        <div className="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                          Raster
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/symbol.png"
                            target="_blank"
                            rel="noreferrer"
                          >
                            PNG
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-sm rounded-lg xl:border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
              <div className="h-full flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 text-sm first:rounded-t-md last:rounded-b-md">
                <div>
                  <div className="bg-neutral-200/50 dark:bg-gray-900/30 py-12 flex justify-center rounded-t-md">
                    <div className="flex items-center h-16">
                      <img
                        src="velodrome.svg"
                        className="mr-3 h-9"
                        alt="Velodrome Finance"
                      />
                      <span className="hidden lg:inline">
                        <img
                          src="wordmark.svg"
                          className="h-7 dark:hidden pb-1"
                          alt="Velodrome Finance"
                        />
                        <img
                          src="wordmark_white.svg"
                          className="hidden h-7 dark:inline pb-1"
                          alt="Velodrome Finance"
                        />
                      </span>
                    </div>
                  </div>
                  <div className="p-6 text-base">Wordmark</div>
                </div>
                <div className="p-6">
                  <div className="text-gray-600 dark:text-gray-400 text-xs">
                    <div className="flex justify-between pb-2.5">
                      <div className="text-gray-400 dark:text-gray-600">
                        Download Dark
                      </div>
                      <div className="flex">
                        <div className="text-gray-400 dark:text-gray-600 pr-2">
                          Vector
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/logo-dark.svg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SVG
                          </a>
                        </div>
                        <div className="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                          Raster
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/logo-dark.png"
                            target="_blank"
                            rel="noreferrer"
                          >
                            PNG
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                      <div className="text-gray-400 dark:text-gray-600">
                        Download Light
                      </div>
                      <div className="flex">
                        <div className="text-gray-400 dark:text-gray-600 pr-2">
                          Vector
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/logo-light.svg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SVG
                          </a>
                        </div>
                        <div className="text-gray-400 dark:text-gray-600 pl-4 pr-2">
                          Raster
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="brand-kit/logo-light.png"
                            target="_blank"
                            rel="noreferrer"
                          >
                            PNG
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-sm rounded-lg xl:border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
              <div className="h-full flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 text-sm first:rounded-t-md last:rounded-b-md">
                <div>
                  <div className="bg-neutral-200/50 dark:bg-gray-900/30 py-12 flex justify-center rounded-t-md">
                    <div className="w-16 h-16 flex items-center justify-center bg-primary p-2 rounded-full"></div>
                  </div>
                  <div className="p-6 text-base">Primary Color</div>
                </div>
                <div className="p-6">
                  <div className="text-gray-600 dark:text-gray-400 text-xs">
                    <div className="flex justify-between pb-2.5">
                      <div className="text-gray-400 dark:text-gray-600">
                        HEX
                      </div>
                      <div className="flex">
                        <div>#EE2524</div>
                      </div>
                    </div>
                    <div className="flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                      <div className="text-gray-400 dark:text-gray-600">
                        RGB
                      </div>
                      <div className="flex gap-3">
                        <div>
                          <span className="opacity-50">R</span> 238
                        </div>
                        <div>
                          <span className="opacity-50">G</span> 37
                        </div>
                        <div>
                          <span className="opacity-50">B</span> 36
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-sm rounded-lg xl:border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
              <div className="h-full flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 text-sm first:rounded-t-md last:rounded-b-md">
                <div>
                  <div className="bg-neutral-200/50 dark:bg-gray-900/30 py-12 flex justify-center rounded-t-md">
                    <div className="text-4xl font-bold pt-3 h-16">Typeface</div>
                  </div>
                  <div className="p-6 text-base">Primary Font</div>
                </div>
                <div className="p-6">
                  <div className="text-gray-600 dark:text-gray-400 text-xs pb-2.5" />
                  <div className="flex gap-1 text-gray-600 dark:text-gray-400 text-xs pb-2.5">
                    <div className="text-gray-400 dark:text-gray-600">
                      <span className="font-bold text-gray-600 dark:text-gray-400">
                        Inter Family
                      </span>
                      from
                    </div>
                    <div>
                      <a
                        className="underline hover:no-underline cursor-pointer"
                        href="https://fonts.google.com/specimen/Inter"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Google Fonts
                      </a>
                    </div>
                  </div>
                  <div className="text-xs flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                    <div className="text-gray-400 dark:text-gray-600">
                      Licensed under the{" "}
                      <a
                        className="underline hover:no-underline cursor-pointer"
                        href="https://scripts.sil.org/cms/scripts/page.php?site_id=nrsi&id=OFL"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Open Font License
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-sm rounded-lg xl:border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
              <div className="h-full flex flex-col justify-between bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 text-sm first:rounded-t-md last:rounded-b-md">
                <div className="bg-neutral-200/50 dark:bg-gray-900/30 py-8 flex justify-center rounded-t-md">
                  <div className="py-4">
                    <img
                      src="/svg/icn-brand-worm-light.svg"
                      className="dark:hidden h-16"
                      alt="Velodrome Finance"
                    />
                    <img
                      src="/svg/icn-brand-worm-dark.svg"
                      className="hidden dark:inline h-16"
                      alt="Velodrome Finance"
                    />
                  </div>
                </div>
                <div className="p-6">
                  <div className="text-gray-600 dark:text-gray-400 text-xs">
                    <div className="flex justify-between pb-1.5">
                      <div className="text-gray-400 dark:text-gray-600">
                        Cyclist
                      </div>
                      <div className="flex">
                        <div className="text-gray-400 dark:text-gray-600 pr-2">
                          Vector
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="/brand-kit/ill-cyclist.svg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SVG
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between py-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                      <div className="text-gray-400 dark:text-gray-600">
                        Worm Graphics
                      </div>
                      <div className="flex">
                        <div className="text-gray-400 dark:text-gray-600 pr-2">
                          Vector
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="/brand-kit/ill-worm.svg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SVG
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="flex justify-between pt-2.5 border-t border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                      <div className="text-gray-400 dark:text-gray-600">
                        Globe Graphics
                      </div>
                      <div className="flex">
                        <div className="text-gray-400 dark:text-gray-600 pr-2">
                          Vector
                        </div>
                        <div>
                          <a
                            className="underline hover:no-underline cursor-pointer"
                            href="/brand-kit/ill-globe.svg"
                            target="_blank"
                            rel="noreferrer"
                          >
                            SVG
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="text-gray-600 dark:text-gray-400 text-xs space-y-1">
                    <div className="pt-12">For more illustrations,</div>
                    <div>
                      please reach out on{" "}
                      <a
                        className="underline hover:no-underline cursor-pointer"
                        href="https://discord.gg/velodrome"
                        target="_blank"
                        rel="noreferrer"
                      >
                        Discord
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    {Footer}
  </div>
</div>


)};

export default Brand;