import {
  useEffect,
  useState,
} from 'react';

import {
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { tokenList } from '../utils/tokendata';

const isValidHexAddress = (address) => {
  const hexRegex = /^[0-9a-fA-F]+$/;
  return hexRegex.test(address);
};

const TokenList = ({ Header, Footer }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [searchQuery, setSearchQuery] = useState(queryParams.get('query') || '');

  // Update URL when searchQuery changes
  useEffect(() => {
    if (searchQuery !== '') {
      queryParams.set('query', searchQuery);
      navigate({ search: queryParams.toString() });
    }
  }, [searchQuery, navigate, queryParams]);

  const filteredTokens = tokenList.filter((token) => {
    const lowercaseQuery = searchQuery.toLowerCase();
    return (
      token.name.toLowerCase().includes(lowercaseQuery) ||
      token.address.toLowerCase().includes(lowercaseQuery) ||
      (isValidHexAddress(searchQuery) && token.address.toLowerCase().startsWith(lowercaseQuery))
    );
  });
  
    return(
      <div>
  <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
    <div className="flex flex-col" />
  </div>
  <div className="md:container md:mx-auto px-4 lg:px-8">
    {Header}
    <div className="flex justify-between my-8 bg-gradient-to-r from-[#FFFFFF] via-[#FFFFFF] to-[#FDFDFC] dark:from-[#251B2B] dark:to-[#492532] rounded-lg shadow-sm">
      <div className="flex flex-col lg:flex-row gap-8 justify-between items-start 2xl:items-center w-full p-8 rounded-lg border border-white border-opacity-5 lg:bg-worm dark:lg:bg-worm-light bg-no-repeat bg-[right_-200px_top_20px] ">
        <p className="text-sm md:text-base md:w-8/12">
          The tokens listed here represent our <em>tokenlist</em>!
          <br className="mb-4" />
          The liquidity pools for these tokens receive emissions and incentives,
          are fetched directly from our smart contracts, and the price for every
          token is fetched from our on-chain oracle in real-time.
        </p>
        <a
          href="/join"
          className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
        >
          <span className="flex items-center rounded-md text-sm px-4 py-2">
            List Your Token
          </span>
        </a>
      </div>
    </div>
    <div className="flex flex-col gap-4 sm:flex-row justify-between pt-12 pb-6 sm:items-center">
      <div className="text-xl">{tokenList.length} Listed Tokens </div>
      <div className="w-full sm:w-80">
        <div className="flex">
          <div className="relative w-full">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                className="h-5 w-5 text-gray-500 dark:text-gray-400"
              >
                <circle cx={11} cy={11} r={8} />
                <path d="m21 21-4.3-4.3" />
              </svg>
            </div>
            <input
              className="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30 border-gray-900 border-opacity-10 focus:border-opacity-30 bg-transparent text-gray-900 dark:text-white dark:border-white dark:border-opacity-10 dark:focus:border-opacity-30 dark:bg-transparent outline-0 pl-10 rounded-lg p-2.5 text-sm"
              placeholder="Symbol or address..."
              defaultValue=""
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
    <div className="text-sm divide-y divide-gray-100 dark:divide-gray-900 shadow-sm rounded-lg border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">

      {searchQuery === "" && tokenList.map((token, index) => 
            <div key={index} className="flex flex-col gap-6 md:flex-row md:gap-4 md:items-center justify-between border-t border-neutral-200 dark:border-gray-850 p-3 sm:p-6 bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 first:rounded-t-md last:rounded-b-md">
            <div className="flex gap-6">
              <img
                src={token.logoSrc}
                alt={token.name+' logo'}
                className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
              />
              <div className="space-y-1">
                <div>{token.name}</div>
                <div className="text-xs text-gray-600 dark:text-gray-400">
                  <div className="font-mono truncate ... w-48 lg:w-fit">
                  {token.address} • <strong>18</strong>{" "}
                    decimals
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-16 md:pl-0 flex flex-col gap-4 md:flex-row md:justify-end md:gap-12">
              <div className="space-y-1 md:text-right">
                <div> ~${(token.value).toLocaleString(undefined, { maximumFractionDigits: 5})}</div>
                <div className="text-xs text-gray-400 dark:text-gray-600">
                  {token.oracleQuote}
                </div>
              </div>
              <div className="sm:w-48 space-y-1 md:text-right" />
            </div>
          </div>
      )}

      {searchQuery !== "" && filteredTokens.map((token, index) => 
            <div key={index} className="flex flex-col gap-6 md:flex-row md:gap-4 md:items-center justify-between border-t border-neutral-200 dark:border-gray-850 p-3 sm:p-6 bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 first:rounded-t-md last:rounded-b-md">
            <div className="flex gap-6">
              <img
                src={token.logoSrc}
                alt={token.name+' logo'}
                className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
              />
              <div className="space-y-1">
                <div>{token.name}</div>
                <div className="text-xs text-gray-600 dark:text-gray-400">
                  <div className="font-mono truncate ... w-48 lg:w-fit">
                  {token.address} • <strong>18</strong>{" "}
                    decimals
                  </div>
                </div>
              </div>
            </div>
            <div className="pl-16 md:pl-0 flex flex-col gap-4 md:flex-row md:justify-end md:gap-12">
              <div className="space-y-1 md:text-right">
                <div> ~${(token.value).toLocaleString(undefined, { maximumFractionDigits: 5})}</div>
                <div className="text-xs text-gray-400 dark:text-gray-600">
                  {token.oracleQuote}
                </div>
              </div>
              <div className="sm:w-48 space-y-1 md:text-right" />
            </div>
          </div>
      )}
    </div>
    {Footer}
  </div>
</div>

)};

export default TokenList;