const Docs = ({Header, Footer }) => {

    return(
      <div>
  <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
    <div className="flex flex-col" />
  </div>
  <div className="md:container md:mx-auto px-4 lg:px-8">
    {Header}
    <div className="dark:block w-3/4" />
    <div className="lg:flex gap-20 px-4 py-6 lg:pt-12 items-start">
      <div className="sticky top-0 sm:top-10 z-40 lg:w-3/12 pb-12">
        <div className="hidden lg:block">
          <div className="space-y-1">
            <div className="opacity-20 text-sm pb-2">Docs:</div>
            <a href="#" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Intro
              </div>
            </a>
            <a href="#tokenomics" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Tokenomics
              </div>
            </a>
            <a href="#emissions" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Emissions
              </div>
            </a>
            <a href="#v2" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                V1 vs. V2
              </div>
            </a>
            <a href="#migration" className="block">
              <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Migration
              </div>
            </a>
            <div className="opacity-30 text-xs pb-2 pt-8">Security:</div>
            <a href="/security#contracts" className="block">
              <div className="flex justify-between items-center bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                Contracts
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width={12}
                  height={12}
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth={2}
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="lucide lucide-plus"
                >
                  <path d="M5 12h14" />
                  <path d="M12 5v14" />
                </svg>
              </div>
            </a>
          </div>
        </div>
        <div className="block lg:hidden bg-gray-900 p-4 rounded-lg -mx-2 flex items-center justify-between">
          <div className="pl-2 opacity-30">Docs</div>
          <div className="w-fit" data-testid="flowbite-tooltip-target">
            <button
              type="button"
              className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
            >
              <span className="flex items-center rounded-md text-xs px-2 py-1">
                Quick Navigation
                <svg
                  stroke="currentColor"
                  fill="none"
                  strokeWidth={2}
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  className="ml-1.5 h-3 w-3"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </span>
            </button>
          </div>
          <div
            data-testid="flowbite-tooltip"
            tabIndex={-1}
            className="z-30 w-fit rounded-lg divide-y divide-gray-100 shadow-sm transition-opacity duration-100 invisible opacity-0 bg-white text-gray-900 dark:bg-gray-900 border border-neutral-200 dark:border-gray-700 dark:border-opacity-70 dark:text-white rounded"
            id=":r0:"
            role="tooltip"
            style={{ position: "absolute", top: 8, left: 8 }}
          >
            <div className="text-sm text-gray-700 dark:text-gray-200">
              <ul className="py-1">
                <li className="flex items-center justify-start mx-2 mt-2 mb-1 py-2 px-2 text-sm text-gray-700 cursor-pointer hover:bg-neutral-100 hover:bg-opacity-50 dark:text-gray-200 dark:hover:bg-gray-850 dark:hover:bg-opacity-50 dark:hover:text-white">
                  <div className="w-64 sm:w-96">
                    <div className="space-y-1">
                      <div className="opacity-20 text-sm pb-2">Docs:</div>
                      <a href="#" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Intro
                        </div>
                      </a>
                      <a href="#tokenomics" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Tokenomics
                        </div>
                      </a>
                      <a href="#emissions" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Emissions
                        </div>
                      </a>
                      <a href="#v2" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          V1 vs. V2
                        </div>
                      </a>
                      <a href="#migration" className="block">
                        <div className="bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Migration
                        </div>
                      </a>
                      <div className="opacity-30 text-xs pb-2 pt-8">
                        Security:
                      </div>
                      <a href="/security#contracts" className="block">
                        <div className="flex justify-between items-center bg-neutral-200 hover:bg-white dark:bg-gray-700/10 hover:dark:bg-gray-700/20 rounded px-3 py-1.5">
                          Contracts
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={12}
                            height={12}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-plus"
                          >
                            <path d="M5 12h14" />
                            <path d="M12 5v14" />
                          </svg>
                        </div>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="w-full lg:w-7/12">
        <h1 className="mb-6 text-2xl text-gray-600 dark:text-gray-400">
          About Velodrome
        </h1>
        <p className="mb-4">
          Velodrome Finance is a next-generation AMM that combines the best of
          Curve, Convex and Uniswap, designed to serve as Optimism's central
          liquidity hub. Velodrome's flywheel allows protocols to build deep
          liquidity in a capital-efficient manner by directing $VELO emissions
          to their pools.
        </p>
        <p className="mb-4">
          Velodrome first launched on <strong>2nd of June, 2022</strong>.
          Velodrome V2, a full protocol redesign, launched on{" "}
          <strong>22nd of June, 2023</strong>.
        </p>
        <svg
          data-src="/svg/info-graph.svg"
          className="injected-svg text-gray-900 dark:hidden mt-12 w-full"
          viewBox="0 0 560 500"
          preserveAspectRatio="xMidYMid meet"
          src=""
          fill="none"
          height={402}
          width={554}
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path xmlns="http://www.w3.org/2000/svg" d="M0 0h554v402H0z" />
          <g xmlns="http://www.w3.org/2000/svg" opacity=".5" filter="url(#a-0)">
            <path
              d="M265.898 66.257a165.966 165.966 0 0 1-15.834 320.57l-.358-1.694a164.232 164.232 0 0 0 130.043-152.577A164.234 164.234 0 0 0 265.374 67.908l.524-1.65Z"
              fill="#000"
            />
          </g>
          <g xmlns="http://www.w3.org/2000/svg" opacity=".5" filter="url(#b-0)">
            <path
              d="M50.943 244.336A165.966 165.966 0 0 1 181.39 62.076l.358 1.695A164.235 164.235 0 0 0 52.663 244.129l-1.72.207Z"
              fill="#000"
            />
          </g>
          <g xmlns="http://www.w3.org/2000/svg" opacity=".5" filter="url(#c-0)">
            <path
              d="M262.552 52.138a171.505 171.505 0 0 1 187.093 128.826l-1.578.393A169.879 169.879 0 0 0 262.748 53.753l-.196-1.615Z"
              fill="#000"
            />
          </g>
          <path
            xmlns="http://www.w3.org/2000/svg"
            opacity=".1"
            d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            id="d"
            d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
            fill="none"
            strokeWidth={1}
            stroke="#EE2524"
            strokeDasharray={3274}
            strokeDashoffset={3274}
          >
            <animate
              attributeName="stroke-dashoffset"
              dur="7s"
              values="3274;1900"
              restart="always"
              repeatCount="indefinite"
            />
          </path>
          <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="111.316" y="301.144">
                LOW SLIPPAGE
              </tspan>
            </text>
            <circle cx="87.42" cy="296.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="33.765" y="143.144">
                FEES
              </tspan>
            </text>
            <circle cx="79.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="259.891" y="143.144">
                VELO EMISSIONS
              </tspan>
            </text>
            <circle cx="381.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="481.42" y="268.144">
                LIQUIDITY
              </tspan>
            </text>
            <circle cx="460.42" cy="263.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="446.42" y="105.144">
                INCENTIVES
              </tspan>
            </text>
            <circle cx="430.42" cy="100.781" r={7} />
          </g>
          <g xmlns="http://www.w3.org/2000/svg">
            <path
              id="slippage"
              d="M62.0291 213.5C64.6734 303.971 138.845 376.5 229.956 376.5C240.051 376.5 249.938 375.61 259.544 373.903L259.369 372.918C249.821 374.615 239.992 375.5 229.956 375.5C139.397 375.5 65.6735 303.419 63.0295 213.5H62.0291Z"
              fill="none"
              strokeWidth={1}
              stroke="#EE2524"
              strokeDasharray={0}
              strokeDashoffset={577}
            >
              <animate
                attributeName="stroke-dasharray"
                dur="7s"
                values="0;15;4;2;0"
                restart="always"
                repeatCount="indefinite"
              />
            </path>
          </g>
          <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="111.316" y="301.144">
                LOW SLIPPAGE
              </tspan>
            </text>
            <circle cx="87.42" cy="296.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="33.765" y="143.144">
                FEES
              </tspan>
            </text>
            <circle cx="79.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="259.891" y="143.144">
                VELO EMISSIONS
              </tspan>
            </text>
            <circle cx="381.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="481.42" y="268.144">
                LIQUIDITY
              </tspan>
            </text>
            <circle cx="460.42" cy="263.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="446.42" y="105.144">
                INCENTIVES
              </tspan>
            </text>
            <circle cx="430.42" cy="100.781" r={7} />
          </g>
          <g xmlns="http://www.w3.org/2000/svg">
            <circle r={8} fill="#fff" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-3 12C3.627 12 9 6.627 9 0S3.627-12-3-12-15-6.627-15 0s5.373 12 12 12Zm-2.707-7.707a1 1 0 1 0 1.414 1.414L.525.89a1 1 0 0 0 0-1.414l-4.818-4.818a1 1 0 1 0-1.414 1.414l4.11 4.111-4.11 4.11Z"
              fill="#EE2524"
            />
            <animateMotion
              dur="7s"
              repeatCount="indefinite"
              rotate="auto"
              calcMode="spline"
              keyPoints="0;0.051;0.22;0.323;0.412"
              keyTimes="0; 0.1; 0.5; 0.75; 1"
              keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
            >
              <mpath xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#d" />
            </animateMotion>
          </g>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={143}
            y={28}
            width={145}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={167} y="52.364">
              veVELO VOTERS
            </tspan>
          </text>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={1}
            y={206}
            width={105}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={25} y="230.364">
              TRADERS
            </tspan>
          </text>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={431}
            y={149}
            width={123}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={455} y="173.364">
              PROTOCOLS
            </tspan>
          </text>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={222}
            y={357}
            width={72}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#fff"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={246} y="381.364">
              LP’s
            </tspan>
          </text>
          <defs xmlns="http://www.w3.org/2000/svg">
            <filter
              id="a-0"
              x="234.706"
              y="51.257"
              width="161.975"
              height="350.57"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect1_foregroundBlur_1_2282"
              />
            </filter>
            <filter
              id="b-0"
              x="34.748"
              y="47.077"
              width="161.999"
              height="212.26"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect1_foregroundBlur_1_2282"
              />
            </filter>
            <filter
              id="c-0"
              x="247.552"
              y="35.888"
              width="217.093"
              height="160.469"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect1_foregroundBlur_1_2282"
              />
            </filter>
          </defs>
        </svg>
        <svg
          data-src="/svg/info-graph-dark.svg"
          className="injected-svg text-white hidden dark:block mt-12 w-full"
          viewBox="0 0 560 500"
          preserveAspectRatio="xMidYMid meet"
          src=""
          fill="none"
          height={402}
          width={554}
          xmlnsXlink="http://www.w3.org/1999/xlink"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path xmlns="http://www.w3.org/2000/svg" d="M0 0h554v402H0z" />
          <g xmlns="http://www.w3.org/2000/svg" opacity=".5" filter="url(#a-1)">
            <path
              d="M265.898 66.257a165.966 165.966 0 0 1-15.834 320.57l-.358-1.694a164.232 164.232 0 0 0 130.043-152.577A164.234 164.234 0 0 0 265.374 67.908l.524-1.65Z"
              fill="#000"
            />
          </g>
          <g xmlns="http://www.w3.org/2000/svg" opacity=".5" filter="url(#b-1)">
            <path
              d="M50.943 244.336A165.966 165.966 0 0 1 181.39 62.076l.358 1.695A164.235 164.235 0 0 0 52.663 244.129l-1.72.207Z"
              fill="#000"
            />
          </g>
          <g xmlns="http://www.w3.org/2000/svg" opacity=".5" filter="url(#c-1)">
            <path
              d="M262.552 52.138a171.505 171.505 0 0 1 187.093 128.826l-1.578.393A169.879 169.879 0 0 0 262.748 53.753l-.196-1.615Z"
              fill="#000"
            />
          </g>
          <path
            xmlns="http://www.w3.org/2000/svg"
            opacity=".1"
            d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
            fill="currentColor"
          />
          <path
            xmlns="http://www.w3.org/2000/svg"
            id="d"
            d="M63.5 208.5c0-92.232 74.768-167 167-167s167 74.768 167 167c0 75.599-50.233 139.466-119.143 160.042l-12.287 4.008 1.828.383A168.714 168.714 0 0 0 302.5 376.5c92.784 0 168-75.216 168-168s-75.216-168-168-168c-10.447 0-20.671.953-30.59 2.779l.18.983A167.967 167.967 0 0 1 302.5 41.5c92.232 0 167 74.768 167 167s-74.768 167-167 167c-11.139 0-22.022-1.09-32.55-3.17l8.693-2.83c69.322-20.699 119.857-84.947 119.857-161 0-92.784-75.216-168-168-168s-168 75.216-168 168 75.216 168 168 168c10.095 0 19.982-.89 29.587-2.597l-.174-.985A167.98 167.98 0 0 1 230.5 375.5c-92.232 0-167-74.768-167-167Z"
            fill="none"
            strokeWidth={1}
            stroke="#EE2524"
            strokeDasharray={3274}
            strokeDashoffset={3274}
          >
            <animate
              attributeName="stroke-dashoffset"
              dur="7s"
              values="3274;1900"
              restart="always"
              repeatCount="indefinite"
            />
          </path>
          <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="111.316" y="301.144">
                LOW SLIPPAGE
              </tspan>
            </text>
            <circle cx="87.42" cy="296.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="33.765" y="143.144">
                FEES
              </tspan>
            </text>
            <circle cx="79.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="259.891" y="143.144">
                VELO EMISSIONS
              </tspan>
            </text>
            <circle cx="381.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="481.42" y="268.144">
                LIQUIDITY
              </tspan>
            </text>
            <circle cx="460.42" cy="263.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="446.42" y="105.144">
                INCENTIVES
              </tspan>
            </text>
            <circle cx="430.42" cy="100.781" r={7} />
          </g>
          <g xmlns="http://www.w3.org/2000/svg">
            <path
              id="slippage"
              d="M62.0291 213.5C64.6734 303.971 138.845 376.5 229.956 376.5C240.051 376.5 249.938 375.61 259.544 373.903L259.369 372.918C249.821 374.615 239.992 375.5 229.956 375.5C139.397 375.5 65.6735 303.419 63.0295 213.5H62.0291Z"
              fill="none"
              strokeWidth={1}
              stroke="#EE2524"
              strokeDasharray={0}
              strokeDashoffset={577}
            >
              <animate
                attributeName="stroke-dasharray"
                dur="7s"
                values="0;15;4;2;0"
                restart="always"
                repeatCount="indefinite"
              />
            </path>
          </g>
          <g xmlns="http://www.w3.org/2000/svg" fill="currentColor">
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="111.316" y="301.144">
                LOW SLIPPAGE
              </tspan>
            </text>
            <circle cx="87.42" cy="296.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="33.765" y="143.144">
                FEES
              </tspan>
            </text>
            <circle cx="79.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="259.891" y="143.144">
                VELO EMISSIONS
              </tspan>
            </text>
            <circle cx="381.42" cy="137.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="481.42" y="268.144">
                LIQUIDITY
              </tspan>
            </text>
            <circle cx="460.42" cy="263.781" r={7} />
            <text
              xmlSpace="preserve"
              style={{ whiteSpace: "pre" }}
              fontFamily="Inter"
              fontSize={12}
              fontWeight={500}
              letterSpacing=".04em"
            >
              <tspan x="446.42" y="105.144">
                INCENTIVES
              </tspan>
            </text>
            <circle cx="430.42" cy="100.781" r={7} />
          </g>
          <g xmlns="http://www.w3.org/2000/svg">
            <circle r={8} fill="#fff" />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M-3 12C3.627 12 9 6.627 9 0S3.627-12-3-12-15-6.627-15 0s5.373 12 12 12Zm-2.707-7.707a1 1 0 1 0 1.414 1.414L.525.89a1 1 0 0 0 0-1.414l-4.818-4.818a1 1 0 1 0-1.414 1.414l4.11 4.111-4.11 4.11Z"
              fill="#EE2524"
            />
            <animateMotion
              dur="7s"
              repeatCount="indefinite"
              rotate="auto"
              calcMode="spline"
              keyPoints="0;0.051;0.22;0.323;0.412"
              keyTimes="0; 0.1; 0.5; 0.75; 1"
              keySplines="0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1; 0.5 0 0.5 1"
            >
              <mpath xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#d" />
            </animateMotion>
          </g>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={143}
            y={28}
            width={145}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#000"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={167} y="52.364">
              veVELO VOTERS
            </tspan>
          </text>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={1}
            y={206}
            width={105}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#000"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={25} y="230.364">
              TRADERS
            </tspan>
          </text>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={431}
            y={149}
            width={123}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#000"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={455} y="173.364">
              PROTOCOLS
            </tspan>
          </text>
          <rect
            xmlns="http://www.w3.org/2000/svg"
            x={222}
            y={357}
            width={72}
            height={40}
            rx={20}
            fill="currentColor"
          />
          <text
            xmlns="http://www.w3.org/2000/svg"
            fill="#000"
            xmlSpace="preserve"
            style={{ whiteSpace: "pre" }}
            fontFamily="Inter"
            fontSize={12}
            fontWeight="bold"
            letterSpacing="0em"
          >
            <tspan x={246} y="381.364">
              LP’s
            </tspan>
          </text>
          <defs xmlns="http://www.w3.org/2000/svg">
            <filter
              id="a-1"
              x="234.706"
              y="51.257"
              width="161.975"
              height="350.57"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect1_foregroundBlur_1_2282"
              />
            </filter>
            <filter
              id="b-1"
              x="34.748"
              y="47.077"
              width="161.999"
              height="212.26"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect1_foregroundBlur_1_2282"
              />
            </filter>
            <filter
              id="c-1"
              x="247.552"
              y="35.888"
              width="217.093"
              height="160.469"
              filterUnits="userSpaceOnUse"
              colorInterpolationFilters="sRGB"
            >
              <feFlood floodOpacity={0} result="BackgroundImageFix" />
              <feBlend
                in="SourceGraphic"
                in2="BackgroundImageFix"
                result="shape"
              />
              <feGaussianBlur
                stdDeviation="7.5"
                result="effect1_foregroundBlur_1_2282"
              />
            </filter>
          </defs>
        </svg>
        <ol className="mb-8 list-inside list-decimal">
          <li>
            The protocol is designed to enable token swaps and generate fees
            (from
            <strong>Traders</strong>) by attracting liquidity.
          </li>
          <li>
            Every epoch liquidity providers (<strong>LPs</strong>) receive{" "}
            <code>$VELO</code> token emissions proportionally to the votes the
            pools accumulate. Only staked (in the protocol gauges) liquidity
            receive emissions.
          </li>
          <li>
            Participants can lock their <code>$VELO</code> to be able to vote on
            the next epoch distribution of emissions, becoming{" "}
            <strong>veVELO Voters</strong>.
          </li>
          <li>
            <strong>veVELO Voters</strong> are rewarded (proportionally to
            locked amounts) for their votes with 100% of the protocol trading
            fees from the previous epoch and any additional voters incentives
            from the current epoch.
          </li>
        </ol>
        <hr
          className="border-gray-700/10 dark:border-gray-700/40 my-20"
          id="tokenomics"
        />
        <h1 className="mb-6 text-2xl text-gray-600 dark:text-gray-400">
          Tokenomics
        </h1>
        <p className="mb-4">
          Velodrome Finance uses two tokens to manage its utility and
          governance:
        </p>
        <ul className="mb-8 list-inside list-disc">
          <li>
            <code>$VELO</code> — ERC-20 utility token of the protocol
          </li>
          <li>
            <code>$veVELO</code> — ERC-721 governance token in the form of an
            NFT (non-fungible token)
          </li>
        </ul>
        <p className="mb-4">
          <code>$VELO</code> is distributed to liquidity providers through
          emissions.
        </p>
        <p className="mb-4">
          <code>$veVELO</code> is used for governance. Any <code>$VELO</code>{" "}
          holder can vote-escrow their tokens and receive a <code>$veVELO</code>{" "}
          (also known as Lock or veNFT) in exchange. Additional tokens can be
          added to the <code>$veVELO</code> NFT at any time.
        </p>
        <p className="mb-4">
          The lock period (also known as vote-escrowed period, hence the{" "}
          <em>ve</em> prefix) can be up to 4 years, following the linear
          relationship shown below:
        </p>
        <ul className="mb-8 list-inside list-disc">
          <li>
            100 <code>$VELO</code> locked for 4 years will become 100{" "}
            <code>$veVELO</code>
          </li>
          <li>
            100 <code>$VELO</code> locked for 1 year will become 25{" "}
            <code>$veVELO</code>
          </li>
        </ul>
        <p className="mb-4">
          The longer the vesting time, the higher the voting power (voting
          weight).
        </p>
        <p className="mb-4">
          Additionally, with Velodrome v2 the Lock (veNFT) can be set into a
          permanent maximum lock time. These <em>permalocked</em> Locks (veNFTs)
          are always treated by the protocol as being locked for the maximum
          duration of 4 years, and their voting power does not decay.
        </p>
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Initial Distribution
        </h2>
        <p className="mb-4">
          The initial distribution of <code>$VELO</code> and{" "}
          <code>$veVELO</code> was done for users and protocols likeliest to
          contribute to protocol mission to become the liquidity base layer of
          the Optimism ecosystem.
        </p>
        <br />
        <div
          data-testid="table-element"
          className="relative overflow-x-auto shadow-md sm:rounded-lg"
        >
          <table className="w-full text-left text-sm text-gray-500 dark:text-gray-400">
            <thead className="bg-gray-50 text-xs uppercase text-gray-700 dark:bg-gray-700 dark:text-gray-400 bg-primary text-white dark:bg-50">
              <tr>
                <th className="px-6 py-3 w-3/4">VELO</th>
                <th className="px-6 py-3" />
                <th className="px-6 py-3" />
              </tr>
            </thead>
            <tbody>
              <tr data-testid="table-row-element" className="">
                <td className="px-6 py-4">Community</td>
                <td className="px-6 py-4">240M</td>
                <td className="px-6 py-4">60%</td>
              </tr>
              <tr data-testid="table-row-element" className="">
                <td className="px-6 py-4">Partner Protocols</td>
                <td className="px-6 py-4">96M</td>
                <td className="px-6 py-4">24%</td>
              </tr>
              <tr data-testid="table-row-element" className="">
                <td className="px-6 py-4">Velodrome Foundation</td>
                <td className="px-6 py-4">40M</td>
                <td className="px-6 py-4">10%</td>
              </tr>
              <tr data-testid="table-row-element" className="">
                <td className="px-6 py-4">Optimism</td>
                <td className="px-6 py-4">20M</td>
                <td className="px-6 py-4">5%</td>
              </tr>
              <tr data-testid="table-row-element" className="">
                <td className="px-6 py-4">Genesis Liquidity Pool</td>
                <td className="px-6 py-4">4M</td>
                <td className="px-6 py-4">1%</td>
              </tr>
            </tbody>
          </table>
        </div>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Community
        </h3>
        <p className="mb-4">
          240M (60%) <code>$VELO</code> tokens were distributed to the people
          who have played the biggest role in incubating Velodrome and those
          likeliest to contribute to its long-term success, including:
        </p>
        <ul className="mb-8 list-inside list-disc">
          <li>
            <code>$WEVE</code> holders (27%, 108M <code>$VELO</code>)
          </li>
          <li>
            <code>$OP</code> network users (18%, 72M <code>$VELO</code>)
            <ul className="mb-8 list-inside list-disc">
              <li>
                3755 <code>$VELO</code>/wallet — Addresses qualified as{" "}
                <a href="https://community.optimism.io/docs/governance/airdrop-1/#optimism-early-adopters">
                  Repeat Optimism Users
                </a>
              </li>
            </ul>
          </li>
          <li>
            Cross-chain DeFi users (15%, 60M <code>$VELO</code>):
            <ul className="mb-8 list-inside list-disc">
              <li>
                3500 <code>$VELO</code>/wallet — Curve Protocol wallets with a
                1450+ days (maximum) <code>$veCRV</code> lock time
              </li>
              <li>
                3000 <code>$VELO</code>/wallet — Convex Protocol lockers of{" "}
                <code>$vlCVX</code> since new lock contract deployment
              </li>
              <li>
                3000 <code>$VELO</code>/wallet — Treasure DAO Genesis Mine{" "}
                <code>$MAGIC</code> stakers for 1- and 3-month periods
              </li>
              <li>
                2000 <code>$VELO</code>/wallet — Platypus Protocol stakers with{" "}
                <code>$vePTP</code> and <code>$PTP</code> balance
              </li>
              <li>
                500 <code>$VELO</code>/wallet — Redacted Cartel participants in
                genesis Dutch auction who didn't sell their <code>$BTRFLY</code>
              </li>
              <li>
                500 <code>$VELO</code>/wallet — Eminence Finance wallets
                affected with EMN, eAAVE, eLINK, eYFI, eSNX or eCRV{" "}
                <a href="https://github.com/banteg/your-eminence/blob/master/shot/01-balances.toml">
                  balance
                </a>
              </li>
            </ul>
          </li>
        </ul>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Protocols
        </h3>
        <p className="mb-4">
          The airdrop of 72M (18%) <code>$veVELO</code> is aimed at attracting
          and engaging 10-15 protocols most likely to contribute to Velodrome
          and Optimism's long-term success.
        </p>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Grants
        </h3>
        <p className="mb-4">
          24M (6%) <code>$veVELO</code> were reserved to distribute to partner
          protocols after the launch. This was used to engage partners in the
          ecosystem through grants.
        </p>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Foundation
        </h3>
        <p className="mb-4">
          The Foundation received an initial allocation that was used to vote to
          drive emissions to key protocol pairs such as <code>$VELO-$USDC</code>{" "}
          and support ongoing protocol development. The total Foundation
          allocation was 40M (10%) in <code>$VELO</code> and{" "}
          <code>$veVELO</code>.
        </p>
        <p className="mb-4">
          The Foundation vested 100% of its initial allocation in the form of
          <code>$veVELO</code> and uses it to vote via the Velodrome Protocol in
          perpetuity.
        </p>
        <p className="mb-4">
          To help cover ongoing expenses and all the upcoming development
          efforts, 3% of the emissions are going to the Foundation address.
          These emissions are used to lock and increase the Foudnation's
          '$veVELO' holdings, distribute as incentives via the protocol, or
          engage in various efforts to further the distribution and adoption of
          the protocol.
        </p>
        <p className="mb-4">Team compensation breakdown:</p>
        <ul className="mb-8 list-inside list-disc">
          <li>
            15,520,816 <code>$VELO</code> was set aside for contributor
            compensation. 0.5% of total emissions, taken from emissions to the
            Foundation described above, will be added to this total for dilution
            control during the 12-month compensation period. All team
            compensation has been distributed as of June 2023 with 15,345,334.2
            VELO bought back from team members and the remainder distributed as
            '$VELO'.
          </li>
        </ul>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Optimism
        </h3>
        <p className="mb-4">
          Optimism has a vested interest in ensuring that Velodrome achieves its
          mission of serving as an ecosystem public good. Optimism received 20M
          (5%)
          <code>$veVELO</code> in the initial distribution, to support that
          interest.
        </p>
        <h3 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Genesis Liquidity Pools
        </h3>
        <p className="mb-4">
          Genesis Pools distributed 4M (1%) <code>$VELO</code> to liquidity
          providers of foundational token pairs to provide better liquidity and
          user experience from launch day. Genesis pool emissions were first
          directed to the
          <code>$VELO</code>-<code>$USDC</code> pool and started a few days
          before the first epoch votes were cast.
        </p>
        <hr
          className="border-gray-700/10 dark:border-gray-700/40 my-20"
          id="emissions"
        />
        <h1 className="mb-6 text-2xl text-gray-600 dark:text-gray-400">
          Emissions
        </h1>
        <p className="mb-4">
          The initial supply of <code>$VELO</code> is 400M.
        </p>
        <p className="mb-4">
          Weekly emissions started at 15M <code>$VELO</code> (3.75% of the
          initial supply) on June 4th 2022 and decay at 1% per week (epoch).
        </p>
        <p className="mb-4">
          With the release of v2 on 22nd of June 2023, the emissions were reset
          back to 15M.
        </p>
        <p className="mb-4">
          <code>$veVELO</code> holders receive a rebase proportional to epoch LP
          emissions and the ratio of <code>$veVELO</code> to <code>$VELO</code>{" "}
          supply, thus reducing vote power dilution for <code>$veVELO</code>!
        </p>
        <br />
        <p className="mb-4">
          The weekly rebase amount is calculated with the following formula:
        </p>
        <blockquote className="my-4 border-l italic opacity-70 pl-4 text-gray-600 dark:text-gray-400">
          <p className="mb-4">
            (veVELO.totalSupply ÷ VELO.totalsupply)³ × 0.5 × Emissions
          </p>
        </blockquote>
        <p className="mb-4">
          <code>$veVELO</code> supply does not affect weekly emissions
          distributed to liquidity providers.
        </p>
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Emission Schedule
        </h2>
        <br />
        <div style={{ height: 600, width: "100%" }}>
          <div id="reactgooglegraph-1" style={{ height: 600, width: "100%" }}>
            <div style={{ position: "relative" }}>
              <div
                dir="ltr"
                style={{ position: "relative", width: 541, height: 600 }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: 0,
                    top: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  aria-label="A chart."
                >
                  <svg
                    width={541}
                    height={600}
                    aria-label="A chart."
                    style={{ overflow: "hidden" }}
                  >
                    <defs id="_ABSTRACT_RENDERER_ID_2">
                      <clipPath id="_ABSTRACT_RENDERER_ID_3">
                        <rect x={104} y={115} width={334} height={371} />
                      </clipPath>
                    </defs>
                    <rect
                      x={0}
                      y={0}
                      width={541}
                      height={600}
                      stroke="none"
                      strokeWidth={0}
                      fill="#ebe9e4"
                    />
                    <g>
                      <text
                        textAnchor="start"
                        x={104}
                        y="71.05"
                        fontFamily="Arial"
                        fontSize={13}
                        fontWeight="bold"
                        stroke="none"
                        strokeWidth={0}
                        fill="#666666"
                      >
                        $VELO Emissions (M)
                      </text>
                      <rect
                        x={104}
                        y={60}
                        width={334}
                        height={13}
                        stroke="none"
                        strokeWidth={0}
                        fillOpacity={0}
                        fill="#ffffff"
                      />
                    </g>
                    <g>
                      <rect
                        x={104}
                        y={81}
                        width={334}
                        height={13}
                        stroke="none"
                        strokeWidth={0}
                        fillOpacity={0}
                        fill="#ffffff"
                      />
                      <g>
                        <rect
                          x={104}
                          y={81}
                          width={110}
                          height={13}
                          stroke="none"
                          strokeWidth={0}
                          fillOpacity={0}
                          fill="#ffffff"
                        />
                        <g>
                          <text
                            textAnchor="start"
                            x={135}
                            y="92.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#666666"
                          >
                            LP Emissions
                          </text>
                        </g>
                        <path
                          d="M104,87.5L130,87.5"
                          stroke="#79f8db"
                          strokeWidth={3}
                          fillOpacity={1}
                          fill="none"
                        />
                      </g>
                      <g>
                        <rect
                          x={235}
                          y={81}
                          width={124}
                          height={13}
                          stroke="none"
                          strokeWidth={0}
                          fillOpacity={0}
                          fill="#ffffff"
                        />
                        <g>
                          <text
                            textAnchor="start"
                            x={266}
                            y="92.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#666666"
                          >
                            veRebase (@…
                          </text>
                          <rect
                            x={266}
                            y={81}
                            width={93}
                            height={13}
                            stroke="none"
                            strokeWidth={0}
                            fillOpacity={0}
                            fill="#ffffff"
                          />
                        </g>
                        <path
                          d="M235,87.5L261,87.5"
                          stroke="#2180df"
                          strokeWidth={3}
                          fillOpacity={1}
                          fill="none"
                        />
                      </g>
                      <path
                        d="M397,94L397,81L384,88Z"
                        stroke="none"
                        strokeWidth={0}
                        fill="#cccccc"
                      />
                      <g>
                        <text
                          textAnchor="middle"
                          x={411}
                          y="92.05"
                          fontFamily="Arial"
                          fontSize={13}
                          stroke="none"
                          strokeWidth={0}
                          fill="#0011cc"
                        >
                          1/2
                        </text>
                      </g>
                      <path
                        d="M425,81L438,88L425,94Z"
                        stroke="none"
                        strokeWidth={0}
                        fill="#0011cc"
                      />
                    </g>
                    <g>
                      <rect
                        x={104}
                        y={115}
                        width={334}
                        height={371}
                        stroke="none"
                        strokeWidth={0}
                        fillOpacity={0}
                        fill="#ffffff"
                      />
                      <g clipPath="url(https://velodrome.finance/docs#_ABSTRACT_RENDERER_ID_3)">
                        <g>
                          <rect
                            x={104}
                            y={485}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={439}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={393}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={346}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={300}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={254}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={208}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={161}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={115}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={462}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={416}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={369}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={323}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={277}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={231}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={184}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={138}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={485}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={444}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={403}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={362}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={321}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={279}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={238}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={197}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={156}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={115}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#cccccc"
                          />
                          <rect
                            x={104}
                            y={464}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={423}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={382}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={341}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={300}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={259}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={218}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={177}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                          <rect
                            x={104}
                            y={136}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#dfddda"
                          />
                        </g>
                        <g>
                          <rect
                            x={104}
                            y={485}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#333333"
                          />
                          <rect
                            x={104}
                            y={485}
                            width={334}
                            height={1}
                            stroke="none"
                            strokeWidth={0}
                            fill="#333333"
                          />
                        </g>
                        <g>
                          <path
                            d="M137.8,138.625C137.8,138.625,182.2,238.44791666674377,204.39999999999998,275.0625C226.59999999999997,311.67708333332564,248.8,335.95833333332564,271,358.3125C293.2,380.66666666667436,315.4,395.69791666667436,337.59999999999997,409.1875C359.79999999999995,422.67708333332564,404.2,439.25,404.2,439.25"
                            stroke="#79f8db"
                            strokeWidth={3}
                            fillOpacity={1}
                            fill="none"
                          />
                          <path
                            d="M137.8,485.5C137.8,485.5,182.2,445.03125,204.39999999999998,439.25C226.59999999999997,433.46875,248.8,446.95833333332564,271,450.8125C293.2,454.66666666667436,315.4,458.52083333332564,337.59999999999997,462.375C359.79999999999995,466.2291666666674,404.2,473.9375,404.2,473.9375"
                            stroke="#2180df"
                            strokeWidth={3}
                            fillOpacity={1}
                            fill="none"
                          />
                          <path
                            d="M137.8,403.27777777777777C137.8,403.27777777777777,182.2,317.62962962962274,204.39999999999998,279.94444444444446C226.59999999999997,242.2592592593278,248.8,201.1481481482167,271,177.16666666666669C293.2,153.18518518511667,315.4,146.33333333333337,337.59999999999997,136.05555555555554C359.79999999999995,125.77777777777777,404.2,115.5,404.2,115.5"
                            stroke="#ea1000"
                            strokeWidth={3}
                            fillOpacity={1}
                            fill="none"
                          />
                        </g>
                      </g>
                      <g />
                      <g>
                        <g>
                          <text
                            textAnchor="middle"
                            x="137.8"
                            y="505.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#222222"
                          >
                            1
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="middle"
                            x="204.39999999999998"
                            y="505.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#222222"
                          >
                            50
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="middle"
                            x={271}
                            y="505.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#222222"
                          >
                            100
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="middle"
                            x="337.59999999999997"
                            y="505.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#222222"
                          >
                            150
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="middle"
                            x="404.2"
                            y="505.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#222222"
                          >
                            200
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="490.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            0
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="443.8"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            2
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="397.55"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            4
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="351.3"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            6
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="305.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            8
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="258.8"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            10
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="212.55"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            12
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="166.3"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            14
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="end"
                            x={91}
                            y="120.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            16
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="490.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            0
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="448.9389"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            200
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="407.8278"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            400
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="366.7167"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            600
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="325.60560000000004"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            800
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="284.4944"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            1,000
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="243.38330000000002"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            1,200
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="202.2722"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            1,400
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="161.1611"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            1,600
                          </text>
                        </g>
                        <g>
                          <text
                            textAnchor="start"
                            x={451}
                            y="120.05"
                            fontFamily="Arial"
                            fontSize={13}
                            stroke="none"
                            strokeWidth={0}
                            fill="#444444"
                          >
                            1,800
                          </text>
                        </g>
                      </g>
                    </g>
                    <g>
                      <g>
                        <text
                          textAnchor="middle"
                          x={271}
                          y="558.05"
                          fontFamily="Arial"
                          fontSize={13}
                          fontStyle="italic"
                          stroke="none"
                          strokeWidth={0}
                          fill="#000000"
                        >
                          Week
                        </text>
                        <rect
                          x={104}
                          y={547}
                          width={334}
                          height={13}
                          stroke="none"
                          strokeWidth={0}
                          fillOpacity={0}
                          fill="#ffffff"
                        />
                      </g>
                      <g>
                        <text
                          textAnchor="middle"
                          x="43.05"
                          y="300.5"
                          fontFamily="Arial"
                          fontSize={13}
                          fontStyle="italic"
                          transform="rotate(-90 43.05 300.5)"
                          stroke="none"
                          strokeWidth={0}
                          fill="#666666"
                        >
                          Epoch Distribution
                        </text>
                        <path
                          d="M31.999999999999993,486L32.000000000000014,115L45.000000000000014,115L44.99999999999999,486Z"
                          stroke="none"
                          strokeWidth={0}
                          fillOpacity={0}
                          fill="#ffffff"
                        />
                      </g>
                      <g>
                        <text
                          textAnchor="middle"
                          x="517.05"
                          y="300.5"
                          fontFamily="Arial"
                          fontSize={13}
                          fontStyle="italic"
                          transform="rotate(-90 517.05 300.5)"
                          stroke="none"
                          strokeWidth={0}
                          fill="#666666"
                        >
                          Total Supply
                        </text>
                        <path
                          d="M505.99999999999994,486L506,114.99999999999997L519,114.99999999999997L519,486Z"
                          stroke="none"
                          strokeWidth={0}
                          fillOpacity={0}
                          fill="#ffffff"
                        />
                      </g>
                    </g>
                    <g />
                  </svg>
                  <div
                    aria-label="A tabular representation of the data in the chart."
                    style={{
                      position: "absolute",
                      left: "-10000px",
                      top: "auto",
                      width: 1,
                      height: 1,
                      overflow: "hidden"
                    }}
                  >
                    <table>
                      <thead>
                        <tr>
                          <th>Week</th>
                          <th>LP Emissions</th>
                          <th>veRebase (@50% locking rate)</th>
                          <th>Total Supply</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1</td>
                          <td>15</td>
                          <td>0</td>
                          <td>400</td>
                        </tr>
                        <tr>
                          <td>50</td>
                          <td>9.1</td>
                          <td>2</td>
                          <td>1,000</td>
                        </tr>
                        <tr>
                          <td>100</td>
                          <td>5.5</td>
                          <td>1.5</td>
                          <td>1,500</td>
                        </tr>
                        <tr>
                          <td>150</td>
                          <td>3.3</td>
                          <td>1</td>
                          <td>1,700</td>
                        </tr>
                        <tr>
                          <td>200</td>
                          <td>2</td>
                          <td>0.5</td>
                          <td>1,800</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div
                aria-hidden="true"
                style={{
                  display: "none",
                  position: "absolute",
                  top: 610,
                  left: 850,
                  whiteSpace: "nowrap",
                  fontFamily: "Arial",
                  fontSize: 13
                }}
              >
                0/0
              </div>
              <div />
            </div>
          </div>
        </div>
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Emission Rewards
        </h2>
        <p className="mb-4">
          Liquidity providers (LPs) receive <code>$VELO</code> emissions as
          rewards for staking their liquidity positions.
        </p>
        <p className="mb-4">
          Emissions are distributed to liquidity pools proportionally to the{" "}
          <code>$veVELO</code> voting power they receive each epoch. LPs receive
          a share of the <code>$VELO</code> distributed per pool proportionally
          to the size of their positions and time staked.
        </p>
        <p className="mb-4">
          These rewards are streamed and available for claiming as they accrue.
        </p>
        <hr
          className="border-gray-700/10 dark:border-gray-700/40 my-20"
          id="v2"
        />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Version 1
        </h2>
        <p className="mb-4">
          Velodrome is a protocol designed to enable token swaps by attracting
          liquidity (see the simplified diagram below). The protocol rewards
          liquidity providers (LPs) with VELO token emissions (from the Minter),
          which are distributed to liquidity pools proportionally to the votes
          each pool receives (casted on a weekly basis by Voters). Liquidity
          providers must stake their deposited liquidity (in a Gauge) to receive
          VELO tokens. VELO holders can lock their tokens to vote on the
          distribution of emissions.
        </p>
        <svg
          data-src="/svg/v1-simplified.svg"
          viewBox="0 0 780 220"
          className="injected-svg bg-neutral-200 dark:bg-neutral-200 rounded my-16 w-full"
          src=""
          height={224.0}
          width={762.0}
          baseProfile="full"
          version="1.1"
         
          throwIfNamespace={false}
        >
          <desc xmlns="http://www.w3.org/2000/svg">
            #direction: right #gutter: 20 #edgeMargin: 15 #gravity: 3 #fontSize:
            10 #lineWidth: 1 #ranker: longest-path [&lt;lollipop id=minter&gt;
            Minter] -&gt;$VELO [&lt;state id=voter&gt;Voter] -o) [&lt;table
            id=gauges&gt; Gauges| vAMM-X/Y || ... || sAMM-A/B] -&gt;$VELO
            [&lt;database id=lps&gt;LPs] [&lt;actor id=voters&gt;Voters] -o)
            [voter]
          </desc>
          <g
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={1.0}
            text-align="left"
            font="12pt Helvetica, Arial, sans-serif"
            fontSize="10pt"
            fontFamily="Helvetica"
            fontWeight="bold"
            fontStyle="normal"
          >
            <g
              fontFamily="Helvetica"
              fontSize="10pt"
              fontWeight="bold"
              fontStyle="normal"
              strokeWidth={1.0}
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke="#33322E"
            >
              <g stroke="transparent" fill="transparent">
                <rect
                  x={0.0}
                  y={0.0}
                  height={224.0}
                  width={762.0}
                  stroke="none"
                />
              </g>
              <g transform="translate(8, 8)" fill="#33322E">
                <g
                  transform="translate(20, 20)"
                  fill="#eee8d5"
                  fontFamily="Helvetica"
                  fontSize="10pt"
                  fontWeight="normal"
                  fontStyle="normal"
                >
                  <text x="220.7" y={37.0} stroke="none">
                    $VELO
                  </text>
                  <path
                    d="M66.0 22.5 L107 22.5 L127 22.5 L127 22.5 L147 22.5 L147 22.5 L167 22.5 L167 22.5 L187 22.5 L187 22.5 L207.7254021187333 40.61295647351483 L207.7 40.6 "
                    fill="none"
                  />
                  <path d="M199.2 40.2 L207.7 40.6 L206.2 32.2 L212.7 45.0 Z" />
                  <path
                    d="M286.0 74.5 L306 74.5 L326 74.5 L326 74.5 L346 74.5 L346 74.5 L366 74.5 L366 74.5 L386 74.5 L386 74.5 L391.3333333333333 74.5 L391.3 74.5 "
                    fill="none"
                  />
                  <path
                    d="M391.3 66.5 L393.6 66.8 L395.7 67.8 L397.4 69.3 L398.6 71.2 L399.3 73.4 L399.3 75.6 L398.6 77.8 L397.4 79.7 L395.7 81.2 L393.6 82.2 L391.3 82.5"
                    fill="none"
                  />
                  <path d="M399.3 74.5 L406.0 74.5" fill="none" />
                  <ellipse cx="391.3" cy="74.5" rx={4.0} ry={4.0} />
                  <text x="584.3" y="66.5" stroke="none">
                    $VELO
                  </text>
                  <path
                    d="M516.0 74.5 L536 74.5 L556 74.5 L556 74.5 L576 74.5 L576 74.5 L596 74.5 L596 74.5 L616 74.5 L616 74.5 L629.3333333333334 74.5 L629.3 74.5 "
                    fill="none"
                  />
                  <path d="M622.7 79.8 L629.3 74.5 L622.7 69.2 L636.0 74.5 Z" />
                  <path
                    d="M87.0 126.5 L107 126.5 L127 126.5 L127 126.5 L147 126.5 L147 126.5 L167 126.5 L167 126.5 L187 126.5 L187 126.5 L201.70165389198252 113.65149575826737 L201.7 113.7 "
                    fill="none"
                  />
                  <path
                    d="M196.4 107.6 L198.3 106.4 L200.5 105.7 L202.8 105.7 L205.0 106.4 L206.9 107.6 L208.4 109.3 L209.4 111.4 L209.7 113.6 L209.4 115.9 L208.4 117.9 L207.0 119.7"
                    fill="none"
                  />
                  <path d="M207.7 108.4 L212.7 104.0" fill="none" />
                  <ellipse cx="201.7" cy="113.7" rx={4.0} ry={4.0} />
                  <g data-name="minter">
                    <g fill="#eee8d5" stroke="#33322E" data-name="minter">
                      <circle r={6.0} cx="43.5" cy="22.5" data-name="minter" />
                    </g>
                    <g
                      transform="translate(15.5, 22)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="minter"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="minter"
                      >
                        <text
                          x={20.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="minter"
                        >
                          Minter
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="voter">
                    <g fill="#eee8d5" stroke="#33322E" data-name="voter">
                      <rect
                        x={222.0}
                        y={60.0}
                        rx="14.5"
                        ry="14.5"
                        height={29.0}
                        width={49.0}
                        data-name="voter"
                      />
                    </g>
                    <g
                      transform="translate(222, 60)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="voter"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="voter"
                      >
                        <text
                          x="16.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="voter"
                        >
                          Voter
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="gauges">
                    <g fill="#eee8d5" stroke="#33322E" data-name="gauges">
                      <rect
                        x={421.0}
                        y="16.5"
                        height={116.0}
                        width={80.0}
                        data-name="gauges"
                      />
                      <path
                        d="M421.0 45.5 L421.0 45.5"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M421.0 45.5 L501.0 45.5"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M421.0 74.5 L501.0 74.5"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M421.0 103.5 L501.0 103.5"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M501.0 45.5 L501.0 132.5"
                        fill="none"
                        data-name="gauges"
                      />
                    </g>
                    <g
                      transform="translate(421, 16.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="bold"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="gauges"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="gauges"
                        >
                          Gauges
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(421, 45.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges"
                      >
                        <text x={0.0} y="11.3" stroke="none" data-name="gauges">
                          vAMM-X/Y
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(421, 74.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges"
                      >
                        <text x={0.0} y="11.3" stroke="none" data-name="gauges">
                          ...
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(421, 103.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges"
                      >
                        <text x={0.0} y="11.3" stroke="none" data-name="gauges">
                          sAMM-A/B
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="lps">
                    <g fill="#eee8d5" stroke="#33322E" data-name="lps">
                      <rect
                        x={651.0}
                        y={60.0}
                        height={29.0}
                        width={40.0}
                        stroke="none"
                        data-name="lps"
                      />
                      <path
                        d="M651.0 60.0 L651.0 89.0"
                        fill="none"
                        data-name="lps"
                      />
                      <path
                        d="M691.0 60.0 L691.0 89.0"
                        fill="none"
                        data-name="lps"
                      />
                      <ellipse
                        cx={671.0}
                        cy={60.0}
                        rx={20.0}
                        ry={6.0}
                        data-name="lps"
                      />
                      <path
                        d="M691.0 89.0 L691.0 89.3 L690.9 89.6 L690.8 89.9 L690.6 90.2 L690.4 90.5 L690.1 90.8 L689.8 91.1 L689.4 91.3 L689.0 91.6 L688.6 91.9 L688.1 92.1 L687.5 92.4 L686.9 92.6 L686.3 92.9 L685.7 93.1 L685.0 93.3 L684.2 93.5 L683.5 93.7 L682.7 93.9 L681.9 94.0 L681.0 94.2 L680.1 94.3 L679.2 94.5 L678.3 94.6 L677.4 94.7 L676.4 94.8 L675.5 94.8 L674.5 94.9 L673.5 95.0 L672.5 95.0 L671.5 95.0 L670.5 95.0 L669.5 95.0 L668.5 95.0 L667.5 94.9 L666.5 94.8 L665.6 94.8 L664.6 94.7 L663.7 94.6 L662.8 94.5 L661.9 94.3 L661.0 94.2 L660.1 94.0 L659.3 93.9 L658.5 93.7 L657.8 93.5 L657.0 93.3 L656.3 93.1 L655.7 92.9 L655.1 92.6 L654.5 92.4 L653.9 92.1 L653.4 91.9 L653.0 91.6 L652.6 91.3 L652.2 91.1 L651.9 90.8 L651.6 90.5 L651.4 90.2 L651.2 89.9 L651.1 89.6 L651.0 89.3 L651.0 89.0"
                        data-name="lps"
                      />
                    </g>
                    <g
                      transform="translate(651, 64)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="bold"
                      fontStyle="normal"
                      data-name="lps"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="lps"
                      >
                        <text
                          x={12.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="lps"
                        >
                          LPs
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="voters">
                    <g fill="#eee8d5" stroke="#33322E" data-name="voters">
                      <circle r={4.0} cx="43.5" cy={112.0} data-name="voters" />
                      <path
                        d="M43.5 116.0 L43.5 124.0"
                        fill="none"
                        data-name="voters"
                      />
                      <path
                        d="M39.5 120.0 L47.5 120.0"
                        fill="none"
                        data-name="voters"
                      />
                      <path
                        d="M39.5 128.0 L43.5 124.0 L47.5 128.0"
                        fill="none"
                        data-name="voters"
                      />
                    </g>
                    <g
                      transform="translate(15, 124)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="voters"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="voters"
                      >
                        <text
                          x="20.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="voters"
                        >
                          Voters
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <p className="mb-4">
          The protocol is 100% immutable, so changes to the protocol mechanics
          are not supported. As a result, the Minter will distribute tokens
          based on the same rules in perpetuity.
        </p>
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Version 2
        </h2>
        <p className="mb-4">
          A second version of the protocol was launched in order to enable major
          features such as:
        </p>
        <ul className="mb-8 list-inside list-disc">
          <li>decentralization</li>
          <li>concentrated liquidity support</li>
          <li>dynamic fees</li>
          <li>dynamic emissions rate</li>
          <li>Velodrome Relay</li>
          <li>
            and enhanced performance and user experience with the new dApp
          </li>
        </ul>
        <p className="mb-4">
          To allow the new functionality, the protocol was redesigned and
          rewritten from the ground up (see the simplified diagram for changes).
        </p>
        <svg
          data-src="/svg/v2-simplified.svg"
          viewBox="0 1000 900 420"
          className="injected-svg bg-neutral-200 dark:bg-neutral-200 rounded my-16 w-full h-auto py-4"
          src=""
          height={2463.0}
          width="878.3"
          baseProfile="full"
          version="1.1"
         
        >
          <desc xmlns="http://www.w3.org/2000/svg">
            #direction: down #gutter: 20 #edgeMargin: 15 #gravity: 3 #fontSize:
            10 #lineWidth: 1 [&lt;lollipop id=minter&gt; Minter-V1] -&gt;
            [&lt;label id=_v1&gt;$VELO V1] -&gt; [&lt;state id=voter&gt;Voter]
            -o) [&lt;table id=gauges&gt; Gauges-V1| vAMM-X/Y || ... || sAMM-A/B]
            -&gt; [&lt;label id=_v2&gt;$VELO V1] -&gt; [&lt;database
            id=lps&gt;LPs] [gauges] --o) [&lt;state id=sinkgauge&gt;xAMM-SINK
            Gauge] [&lt;lollipop id=minter2&gt; Minter-V2] --&gt; [&lt;label
            id=_v3&gt;$VELO V2] --&gt; [&lt;state id=voter2&gt;Voter-V2] --o)
            [&lt;table id=gauges2&gt; Gauges-V2| vAMM-X/Y || ... || sAMM-A/B]
            [sinkgauge] --&gt; [&lt;label id=_v4&gt;$VELO V1] --&gt; [&lt;state
            id=sinker&gt;SinkManager] --&gt; [&lt;label id=_v5&gt;$VELO V1]
            --&gt; [&lt;state id=sinkve&gt;Sink veNFT-V1] --&gt; [&lt;label
            id=_v6&gt;$VELO V1] --&gt; [&lt;note&gt;100% Votes for xAMM-SINK
            Gauge] --&gt; [voter] [sinker] &lt;-- [&lt;label id=_v8&gt;
            $VELO-V1] &lt;-- [&lt;state id=convertor&gt; $VELO V1-V2 Convertor]
            --&gt; [&lt;label id=_v9&gt;$VELO V2] --&gt; [&lt;actor id=user&gt;
            Aggregators] [sinker] --o) [minter2] [gauges2] --&gt; [&lt;label
            id=_v7&gt;$VELO V2] --&gt; [&lt;database id=lps&gt;LPs] [&lt;actor
            id=voters&gt;Voters] --o) [voter2]
          </desc>
          <g
            xmlns="http://www.w3.org/2000/svg"
            strokeWidth={1.0}
            text-align="left"
            font="12pt Helvetica, Arial, sans-serif"
            fontSize="10pt"
            fontFamily="Helvetica"
            fontWeight="bold"
            fontStyle="normal"
          >
            <g
              fontFamily="Helvetica"
              fontSize="10pt"
              fontWeight="bold"
              fontStyle="normal"
              strokeWidth={1.0}
              strokeLinejoin="round"
              strokeLinecap="round"
              stroke="#33322E"
            >
              <g stroke="transparent" fill="transparent">
                <rect
                  x={0.0}
                  y={0.0}
                  height={2463.0}
                  width="878.3"
                  stroke="none"
                />
              </g>
              <g transform="translate(8, 8)" fill="#33322E">
                <g
                  transform="translate(20, 20)"
                  fill="#eee8d5"
                  fontFamily="Helvetica"
                  fontSize="10pt"
                  fontWeight="normal"
                  fontStyle="normal"
                >
                  <path
                    d="M531.8 45.0 L531.75 65 L531.75 85 L531.75 85 L531.75 105 L531.75 105 L531.75 125 L531.75 125 L531.75 145 L531.75 145 L531.75 158.33333333333334 L531.8 158.3 "
                    fill="none"
                  />
                  <path d="M526.4 151.7 L531.8 158.3 L537.1 151.7 L531.8 165.0 Z" />
                  <path
                    d="M531.8 224.0 L531.75 244 L531.75 264 L531.75 264 L531.75 284 L531.75 284 L531.75 304 L531.75 304 L531.75 324 L531.75 324 L531.75 337.3333333333333 L531.8 337.3 "
                    fill="none"
                  />
                  <path d="M526.4 330.7 L531.8 337.3 L537.1 330.7 L531.8 344.0 Z" />
                  <path
                    d="M568.6 403.0 L593.5 423 L593.5 443 L593.5 443 L593.5 463 L593.5 463 L593.5 483 L593.5 483 L593.5 503 L593.5 503 L593.5 508.3333333333333 L593.5 508.3 "
                    fill="none"
                  />
                  <path
                    d="M601.5 508.3 L601.2 510.6 L600.2 512.7 L598.7 514.4 L596.8 515.6 L594.6 516.3 L592.4 516.3 L590.2 515.6 L588.3 514.4 L586.8 512.7 L585.8 510.6 L585.5 508.3"
                    fill="none"
                  />
                  <path d="M593.5 516.3 L593.5 523.0" fill="none" />
                  <ellipse cx="593.5" cy="508.3" rx={4.0} ry={4.0} />
                  <path
                    d="M651.5 627.0 L767.25 689 L767.25 709 L767.25 709 L767.25 729 L767.25 729 L767.25 749 L767.25 749 L767.25 769 L767.25 769 L767.25 818.5 L767.25 818.5 L767.25 868 L767.25 868 L767.25 888 L767.25 888 L767.25 908 L767.25 908 L767.25 928 L767.25 928 L767.25 948 L767.25 948 L767.25 997.5 L767.25 997.5 L767.25 1047 L767.25 1047 L767.25 1067 L767.25 1067 L767.25 1087 L767.25 1087 L767.25 1107 L767.25 1107 L767.25 1127 L767.25 1127 L767.25 1176.5 L767.25 1176.5 L767.25 1226 L767.25 1226 L767.25 1246 L767.25 1246 L767.25 1266 L767.25 1266 L767.25 1286 L767.25 1286 L767.25 1306 L767.25 1306 L767.25 1355.5 L767.25 1355.5 L767.25 1405 L767.25 1405 L767.25 1425 L767.25 1425 L767.25 1445 L767.25 1445 L767.25 1465 L767.25 1465 L767.25 1485 L767.25 1485 L767.25 1546.5 L767.25 1546.5 L767.25 1608 L767.25 1608 L767.25 1628 L767.25 1628 L767.25 1648 L767.25 1648 L767.25 1668 L767.25 1668 L767.25 1688 L767.25 1688 L767.25 1737.5 L767.25 1737.5 L767.25 1787 L767.25 1787 L767.25 1807 L767.25 1807 L767.25 1827 L767.25 1827 L767.25 1847 L767.25 1847 L767.25 1867 L767.25 1867 L767.25 1960 L767.25 1960 L767.25 2053 L767.25 2053 L767.25 2073 L767.25 2073 L767.25 2093 L767.25 2093 L767.25 2113 L767.25 2113 L767.25 2133 L767.25 2133 L767.25 2146.3333333333335 L767.3 2146.3 "
                    fill="none"
                  />
                  <path d="M761.9 2139.7 L767.3 2146.3 L772.6 2139.7 L767.3 2153.0 Z" />
                  <path
                    d="M767.3 2212.0 L767.25 2232 L767.25 2252 L767.25 2252 L767.25 2272 L767.25 2272 L767.25 2292 L767.25 2292 L767.25 2312 L767.25 2312 L456.57958284619866 2362.7127011677603 L456.6 2362.7 "
                    fill="none"
                  />
                  <path d="M462.3 2356.4 L456.6 2362.7 L464.0 2366.9 L450.0 2363.8 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M535.5 627.0 L419.75 689 L419.75 709 L419.75 709 L419.75 729 L419.75 729 L419.75 749 L419.75 749 L419.75 769 L419.75 769 L419.75 774.3333333333334 L419.8 774.3 "
                      fill="none"
                    />
                  </g>
                  <path
                    d="M427.8 774.3 L427.4 776.6 L426.5 778.7 L425.0 780.4 L423.1 781.6 L420.9 782.3 L418.6 782.3 L416.4 781.6 L414.5 780.4 L413.0 778.7 L412.1 776.6 L411.8 774.3"
                    fill="none"
                  />
                  <path d="M419.8 782.3 L419.8 789.0" fill="none" />
                  <ellipse cx="419.8" cy="774.3" rx={4.0} ry={4.0} />
                  <g strokeDasharray="4 4">
                    <path
                      d="M182.0 1378.0 L182 1405 L182 1425 L182 1425 L182 1445 L182 1445 L182 1465 L182 1465 L182 1485 L182 1485 L182 1510.3333333333333 L182.0 1510.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M176.7 1503.7 L182.0 1510.3 L187.3 1503.7 L182.0 1517.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M182.0 1576.0 L182 1608 L182 1628 L182 1628 L182 1648 L182 1648 L182 1668 L182 1668 L182 1688 L182 1688 L159.4437685618998 1704.1232268041294 L159.4 1704.1 "
                      fill="none"
                    />
                  </g>
                  <path d="M161.8 1695.9 L159.4 1704.1 L168.0 1704.6 L154.0 1708.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M112.8 1767.0 L112.75 1787 L112.75 1807 L112.75 1807 L112.75 1827 L112.75 1827 L112.75 1847 L112.75 1847 L112.75 1867 L112.75 1867 L112.75 1872.3333333333333 L112.8 1872.3 "
                      fill="none"
                    />
                  </g>
                  <path
                    d="M120.8 1872.3 L120.4 1874.6 L119.5 1876.7 L118.0 1878.4 L116.1 1879.6 L113.9 1880.3 L111.6 1880.3 L109.4 1879.6 L107.5 1878.4 L106.0 1876.7 L105.1 1874.6 L104.8 1872.3"
                    fill="none"
                  />
                  <path d="M112.8 1880.3 L112.8 1887.0" fill="none" />
                  <ellipse cx="112.8" cy="1872.3" rx={4.0} ry={4.0} />
                  <g strokeDasharray="4 4">
                    <path
                      d="M419.8 848.0 L419.75 868 L419.75 888 L419.75 888 L419.75 908 L419.75 908 L419.75 928 L419.75 928 L419.75 948 L419.75 948 L419.75 961.3333333333334 L419.8 961.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M414.4 954.7 L419.8 961.3 L425.1 954.7 L419.8 968.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M419.8 1027.0 L419.75 1047 L419.75 1067 L419.75 1067 L419.75 1087 L419.75 1087 L419.75 1107 L419.75 1107 L419.75 1127 L419.75 1127 L419.75 1140.3333333333333 L419.8 1140.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M414.4 1133.7 L419.8 1140.3 L425.1 1133.7 L419.8 1147.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M411.9 1206.0 L406.5 1226 L406.5 1246 L406.5 1246 L406.5 1266 L406.5 1266 L406.5 1286 L406.5 1286 L406.5 1306 L406.5 1306 L406.5 1319.3333333333333 L406.5 1319.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M401.2 1312.7 L406.5 1319.3 L411.8 1312.7 L406.5 1326.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M406.5 1385.0 L406.5 1405 L406.5 1425 L406.5 1425 L406.5 1445 L406.5 1445 L406.5 1465 L406.5 1465 L406.5 1485 L406.5 1485 L406.5 1510.3333333333333 L406.5 1510.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M401.2 1503.7 L406.5 1510.3 L411.8 1503.7 L406.5 1517.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M406.5 1576.0 L406.5 1608 L406.5 1628 L406.5 1628 L406.5 1648 L406.5 1648 L406.5 1668 L406.5 1668 L406.5 1688 L406.5 1688 L406.5 1701.3333333333333 L406.5 1701.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M401.2 1694.7 L406.5 1701.3 L411.8 1694.7 L406.5 1708.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M406.5 1767.0 L406.5 1787 L406.5 1807 L406.5 1807 L406.5 1827 L406.5 1827 L406.5 1847 L406.5 1847 L406.5 1867 L406.5 1867 L368.02502446913195 1924.946116993858 L368.0 1924.9 "
                      fill="none"
                    />
                  </g>
                  <path d="M367.3 1916.4 L368.0 1924.9 L376.2 1922.3 L364.3 1930.5 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M323.3 1930.5 L277 1867 L277 1847 L277 1847 L277 1827 L277 1827 L277 1807 L277 1807 L277 1787 L277 1787 L277 1737.5 L277 1737.5 L277 1688 L277 1688 L277 1668 L277 1668 L277 1648 L277 1648 L277 1628 L277 1628 L277 1608 L277 1608 L277 1546.5 L277 1546.5 L277 1485 L277 1485 L277 1465 L277 1465 L277 1445 L277 1445 L277 1425 L277 1425 L277 1405 L277 1405 L277 1355.5 L277 1355.5 L277 1306 L277 1306 L277 1286 L277 1286 L277 1266 L277 1266 L277 1246 L277 1246 L277 1226 L277 1226 L277 1176.5 L277 1176.5 L277 1127 L277 1127 L277 1107 L277 1107 L277 1087 L277 1087 L277 1067 L277 1067 L277 1047 L277 1047 L277 997.5 L277 997.5 L277 948 L277 948 L277 928 L277 928 L277 908 L277 908 L277 888 L277 888 L277 868 L277 868 L277 818.5 L277 818.5 L277 769 L277 769 L277 749 L277 749 L277 729 L277 729 L277 709 L277 709 L277 689 L277 689 L277 596 L277 596 L277 503 L277 503 L277 483 L277 483 L277 463 L277 463 L277 443 L277 443 L277 423 L277 423 L485.705730336982 382.4467766371713 L485.7 382.4 "
                      fill="none"
                    />
                  </g>
                  <path d="M480.2 389.0 L485.7 382.4 L478.1 378.5 L492.3 381.2 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M490.7 1193.4 L628.25 1226 L628.25 1246 L628.25 1246 L628.25 1266 L628.25 1266 L628.25 1286 L628.25 1286 L628.25 1306 L628.25 1306 L628.25 1326 L628.3 1326.0 "
                      fill="none"
                    />
                  </g>
                  <path d="M498.5 1189.7 L490.7 1193.4 L496.0 1200.1 L484.3 1191.8 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M628.3 1391.7 L628.25 1405 L628.25 1425 L628.25 1425 L628.25 1445 L628.25 1445 L628.25 1465 L628.25 1465 L628.25 1485 L628.25 1485 L628.25 1517 L628.3 1517.0 "
                      fill="none"
                    />
                  </g>
                  <path d="M633.6 1398.3 L628.3 1391.7 L622.9 1398.3 L628.3 1385.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M628.3 1576.0 L628.25 1608 L628.25 1628 L628.25 1628 L628.25 1648 L628.25 1648 L628.25 1668 L628.25 1668 L628.25 1688 L628.25 1688 L628.25 1701.3333333333333 L628.3 1701.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M622.9 1694.7 L628.3 1701.3 L633.6 1694.7 L628.3 1708.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M628.3 1767.0 L628.25 1787 L628.25 1807 L628.25 1807 L628.25 1827 L628.25 1827 L628.25 1847 L628.25 1847 L628.25 1867 L628.25 1867 L628.25 1911.8333333333333 L628.3 1911.8 "
                      fill="none"
                    />
                  </g>
                  <path d="M622.9 1905.2 L628.3 1911.8 L633.6 1905.2 L628.3 1918.5 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M355.3 1189.9 L182 1226 L182 1246 L182 1246 L182 1266 L182 1266 L182 1286 L182 1286 L182 1306 L182 1306 L182 1318.3333333333333 L182.0 1318.3 "
                      fill="none"
                    />
                  </g>
                  <path
                    d="M190.0 1318.3 L189.7 1320.6 L188.7 1322.7 L187.2 1324.4 L185.3 1325.6 L183.1 1326.3 L180.9 1326.3 L178.7 1325.6 L176.8 1324.4 L175.3 1322.7 L174.3 1320.6 L174.0 1318.3"
                    fill="none"
                  />
                  <path d="M182.0 1326.3 L182.0 1333.0" fill="none" />
                  <ellipse cx={182.0} cy="1318.3" rx={4.0} ry={4.0} />
                  <g strokeDasharray="4 4">
                    <path
                      d="M112.8 2033.0 L112.75 2053 L112.75 2073 L112.75 2073 L112.75 2093 L112.75 2093 L112.75 2113 L112.75 2113 L112.75 2133 L112.75 2133 L112.75 2146.3333333333335 L112.8 2146.3 "
                      fill="none"
                    />
                  </g>
                  <path d="M107.4 2139.7 L112.8 2146.3 L118.1 2139.7 L112.8 2153.0 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M112.8 2212.0 L112.75 2232 L112.75 2252 L112.75 2252 L112.75 2272 L112.75 2272 L112.75 2292 L112.75 2292 L112.75 2312 L112.75 2312 L373.4507919103642 2361.5956841516822 L373.5 2361.6 "
                      fill="none"
                    />
                  </g>
                  <path d="M365.9 2365.6 L373.5 2361.6 L367.9 2355.1 L380.0 2362.8 Z" />
                  <g strokeDasharray="4 4">
                    <path
                      d="M43.5 1588.0 L43.5 1608 L43.5 1628 L43.5 1628 L43.5 1648 L43.5 1648 L43.5 1668 L43.5 1668 L43.5 1688 L43.5 1688 L59.54795158806287 1699.4710989690846 L59.5 1699.5 "
                      fill="none"
                    />
                  </g>
                  <path
                    d="M64.2 1693.0 L65.8 1694.5 L67.0 1696.5 L67.5 1698.7 L67.4 1701.0 L66.7 1703.1 L65.3 1705.0 L63.5 1706.4 L61.4 1707.2 L59.2 1707.5 L56.9 1707.0 L54.9 1706.0"
                    fill="none"
                  />
                  <path d="M66.1 1704.1 L71.5 1708.0" fill="none" />
                  <ellipse cx="59.5" cy="1699.5" rx={4.0} ry={4.0} />
                  <g data-name="minter">
                    <g fill="#eee8d5" stroke="#33322E" data-name="minter">
                      <circle r={6.0} cx="531.8" cy="22.5" data-name="minter" />
                    </g>
                    <g
                      transform="translate(535.75, 7.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="minter"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="minter"
                      >
                        <text
                          x="30.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="minter"
                        >
                          Minter-V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v1">
                    <g
                      transform="translate(491.75, 180)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v1"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v1"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v1"
                        >
                          $VELO V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="voter">
                    <g fill="#eee8d5" stroke="#33322E" data-name="voter">
                      <rect
                        x="507.3"
                        y={359.0}
                        rx="14.5"
                        ry="14.5"
                        height={29.0}
                        width={49.0}
                        data-name="voter"
                      />
                    </g>
                    <g
                      transform="translate(507.25, 359)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="voter"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="voter"
                      >
                        <text
                          x="16.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="voter"
                        >
                          Voter
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="gauges">
                    <g fill="#eee8d5" stroke="#33322E" data-name="gauges">
                      <rect
                        x="550.5"
                        y={538.0}
                        height={116.0}
                        width={86.0}
                        data-name="gauges"
                      />
                      <path
                        d="M550.5 567.0 L550.5 567.0"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M550.5 567.0 L636.5 567.0"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M550.5 596.0 L636.5 596.0"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M550.5 625.0 L636.5 625.0"
                        fill="none"
                        data-name="gauges"
                      />
                      <path
                        d="M636.5 567.0 L636.5 654.0"
                        fill="none"
                        data-name="gauges"
                      />
                    </g>
                    <g
                      transform="translate(550.5, 538)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="bold"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="gauges"
                      >
                        <text
                          x={35.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="gauges"
                        >
                          Gauges-V1
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(550.5, 567)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges"
                      >
                        <text x={0.0} y="11.3" stroke="none" data-name="gauges">
                          vAMM-X/Y
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(550.5, 596)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges"
                      >
                        <text x={0.0} y="11.3" stroke="none" data-name="gauges">
                          ...
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(550.5, 625)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges"
                      >
                        <text x={0.0} y="11.3" stroke="none" data-name="gauges">
                          sAMM-A/B
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v2">
                    <g
                      transform="translate(727.25, 2168)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v2"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v2"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v2"
                        >
                          $VELO V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="lps">
                    <g fill="#eee8d5" stroke="#33322E" data-name="lps">
                      <rect
                        x={395.0}
                        y={2355.0}
                        height={29.0}
                        width={40.0}
                        stroke="none"
                        data-name="lps"
                      />
                      <path
                        d="M395.0 2355.0 L395.0 2384.0"
                        fill="none"
                        data-name="lps"
                      />
                      <path
                        d="M435.0 2355.0 L435.0 2384.0"
                        fill="none"
                        data-name="lps"
                      />
                      <ellipse
                        cx={415.0}
                        cy={2355.0}
                        rx={20.0}
                        ry={6.0}
                        data-name="lps"
                      />
                      <path
                        d="M435.0 2384.0 L435.0 2384.3 L434.9 2384.6 L434.8 2384.9 L434.6 2385.2 L434.4 2385.5 L434.1 2385.8 L433.8 2386.1 L433.4 2386.3 L433.0 2386.6 L432.6 2386.9 L432.1 2387.1 L431.5 2387.4 L430.9 2387.6 L430.3 2387.9 L429.7 2388.1 L429.0 2388.3 L428.2 2388.5 L427.5 2388.7 L426.7 2388.9 L425.9 2389.0 L425.0 2389.2 L424.1 2389.3 L423.2 2389.5 L422.3 2389.6 L421.4 2389.7 L420.4 2389.8 L419.5 2389.8 L418.5 2389.9 L417.5 2390.0 L416.5 2390.0 L415.5 2390.0 L414.5 2390.0 L413.5 2390.0 L412.5 2390.0 L411.5 2389.9 L410.5 2389.8 L409.6 2389.8 L408.6 2389.7 L407.7 2389.6 L406.8 2389.5 L405.9 2389.3 L405.0 2389.2 L404.1 2389.0 L403.3 2388.9 L402.5 2388.7 L401.8 2388.5 L401.0 2388.3 L400.3 2388.1 L399.7 2387.9 L399.1 2387.6 L398.5 2387.4 L397.9 2387.1 L397.4 2386.9 L397.0 2386.6 L396.6 2386.3 L396.2 2386.1 L395.9 2385.8 L395.6 2385.5 L395.4 2385.2 L395.2 2384.9 L395.1 2384.6 L395.0 2384.3 L395.0 2384.0"
                        data-name="lps"
                      />
                    </g>
                    <g
                      transform="translate(395, 2359)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="bold"
                      fontStyle="normal"
                      data-name="lps"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="lps"
                      >
                        <text
                          x={12.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="lps"
                        >
                          LPs
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="sinkgauge">
                    <g fill="#eee8d5" stroke="#33322E" data-name="sinkgauge">
                      <rect
                        x="351.3"
                        y={804.0}
                        rx="14.5"
                        ry="14.5"
                        height={29.0}
                        width={137.0}
                        data-name="sinkgauge"
                      />
                    </g>
                    <g
                      transform="translate(351.25, 804)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="sinkgauge"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="sinkgauge"
                      >
                        <text
                          x="60.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="sinkgauge"
                        >
                          xAMM-SINK Gauge
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="minter2">
                    <g fill="#eee8d5" stroke="#33322E" data-name="minter2">
                      <circle
                        r={6.0}
                        cx={182.0}
                        cy="1355.5"
                        data-name="minter2"
                      />
                    </g>
                    <g
                      transform="translate(186, 1340.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="minter2"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="minter2"
                      >
                        <text
                          x="30.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="minter2"
                        >
                          Minter-V2
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v3">
                    <g
                      transform="translate(142, 1532)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v3"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v3"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v3"
                        >
                          $VELO V2
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="voter2">
                    <g fill="#eee8d5" stroke="#33322E" data-name="voter2">
                      <rect
                        x="77.8"
                        y={1723.0}
                        rx="14.5"
                        ry="14.5"
                        height={29.0}
                        width={70.0}
                        data-name="voter2"
                      />
                    </g>
                    <g
                      transform="translate(77.75, 1723)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="voter2"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="voter2"
                      >
                        <text
                          x={27.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="voter2"
                        >
                          Voter-V2
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="gauges2">
                    <g fill="#eee8d5" stroke="#33322E" data-name="gauges2">
                      <rect
                        x="69.8"
                        y={1902.0}
                        height={116.0}
                        width={86.0}
                        data-name="gauges2"
                      />
                      <path
                        d="M69.8 1931.0 L69.8 1931.0"
                        fill="none"
                        data-name="gauges2"
                      />
                      <path
                        d="M69.8 1931.0 L155.8 1931.0"
                        fill="none"
                        data-name="gauges2"
                      />
                      <path
                        d="M69.8 1960.0 L155.8 1960.0"
                        fill="none"
                        data-name="gauges2"
                      />
                      <path
                        d="M69.8 1989.0 L155.8 1989.0"
                        fill="none"
                        data-name="gauges2"
                      />
                      <path
                        d="M155.8 1931.0 L155.8 2018.0"
                        fill="none"
                        data-name="gauges2"
                      />
                    </g>
                    <g
                      transform="translate(69.75, 1902)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="bold"
                      fontStyle="normal"
                      data-name="gauges2"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="gauges2"
                      >
                        <text
                          x={35.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="gauges2"
                        >
                          Gauges-V2
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(69.75, 1931)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges2"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges2"
                      >
                        <text
                          x={0.0}
                          y="11.3"
                          stroke="none"
                          data-name="gauges2"
                        >
                          vAMM-X/Y
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(69.75, 1960)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges2"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges2"
                      >
                        <text
                          x={0.0}
                          y="11.3"
                          stroke="none"
                          data-name="gauges2"
                        >
                          ...
                        </text>
                      </g>
                    </g>
                    <g
                      transform="translate(69.75, 1989)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="gauges2"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="gauges2"
                      >
                        <text
                          x={0.0}
                          y="11.3"
                          stroke="none"
                          data-name="gauges2"
                        >
                          sAMM-A/B
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v4">
                    <g
                      transform="translate(379.75, 983)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v4"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v4"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v4"
                        >
                          $VELO V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="sinker">
                    <g fill="#eee8d5" stroke="#33322E" data-name="sinker">
                      <rect
                        x="370.3"
                        y={1162.0}
                        rx="14.5"
                        ry="14.5"
                        height={29.0}
                        width={99.0}
                        data-name="sinker"
                      />
                    </g>
                    <g
                      transform="translate(370.25, 1162)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="sinker"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="sinker"
                      >
                        <text
                          x="41.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="sinker"
                        >
                          SinkManager
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v5">
                    <g
                      transform="translate(366.5, 1341)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v5"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v5"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v5"
                        >
                          $VELO V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="sinkve">
                    <g fill="#eee8d5" stroke="#33322E" data-name="sinkve">
                      <rect
                        x={352.0}
                        y={1532.0}
                        rx="14.5"
                        ry="14.5"
                        height={29.0}
                        width={109.0}
                        data-name="sinkve"
                      />
                    </g>
                    <g
                      transform="translate(352, 1532)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="sinkve"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="sinkve"
                      >
                        <text
                          x="46.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="sinkve"
                        >
                          Sink veNFT-V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v6">
                    <g
                      transform="translate(366.5, 1723)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v6"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v6"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v6"
                        >
                          $VELO V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="100% Votes for xAMM-SINK Gauge">
                    <g
                      fill="#eee8d5"
                      stroke="#33322E"
                      data-name="100% Votes for xAMM-SINK Gauge"
                    >
                      <path
                        d="M227.3 1945.5 L454.3 1945.5 L462.3 1953.5 L462.3 1974.5 L227.3 1974.5 L227.3 1945.5 Z"
                        data-name="100% Votes for xAMM-SINK Gauge"
                      />
                      <path
                        d="M454.3 1945.5 L454.3 1953.5 L462.3 1953.5"
                        fill="none"
                        data-name="100% Votes for xAMM-SINK Gauge"
                      />
                    </g>
                    <g
                      transform="translate(227.25, 1945.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="100% Votes for xAMM-SINK Gauge"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="left"
                        data-name="100% Votes for xAMM-SINK Gauge"
                      >
                        <text
                          x={0.0}
                          y="11.3"
                          stroke="none"
                          data-name="100% Votes for xAMM-SINK Gauge"
                        >
                          100% Votes for xAMM-SINK Gauge
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v8">
                    <g
                      transform="translate(588.25, 1341)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v8"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v8"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v8"
                        >
                          $VELO-V1
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="convertor">
                    <g fill="#eee8d5" stroke="#33322E" data-name="convertor">
                      <rect
                        x="544.3"
                        y={1532.0}
                        rx="14.5"
                        ry="14.5"
                        height={29.0}
                        width={168.0}
                        data-name="convertor"
                      />
                    </g>
                    <g
                      transform="translate(544.25, 1532)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="convertor"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="convertor"
                      >
                        <text
                          x={76.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="convertor"
                        >
                          $VELO V1-V2 Convertor
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v9">
                    <g
                      transform="translate(588.25, 1723)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v9"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v9"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v9"
                        >
                          $VELO V2
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="user">
                    <g fill="#eee8d5" stroke="#33322E" data-name="user">
                      <circle r={4.0} cx="628.3" cy="1945.5" data-name="user" />
                      <path
                        d="M628.3 1949.5 L628.3 1957.5"
                        fill="none"
                        data-name="user"
                      />
                      <path
                        d="M624.3 1953.5 L632.3 1953.5"
                        fill="none"
                        data-name="user"
                      />
                      <path
                        d="M624.3 1961.5 L628.3 1957.5 L632.3 1961.5"
                        fill="none"
                        data-name="user"
                      />
                    </g>
                    <g
                      transform="translate(580.75, 1957.5)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="user"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="user"
                      >
                        <text
                          x="39.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="user"
                        >
                          Aggregators
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="_v7">
                    <g
                      transform="translate(72.75, 2168)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="_v7"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="_v7"
                      >
                        <text
                          x={32.0}
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="_v7"
                        >
                          $VELO V2
                        </text>
                      </g>
                    </g>
                  </g>
                  <g data-name="voters">
                    <g fill="#eee8d5" stroke="#33322E" data-name="voters">
                      <circle
                        r={4.0}
                        cx="43.5"
                        cy={1532.0}
                        data-name="voters"
                      />
                      <path
                        d="M43.5 1536.0 L43.5 1544.0"
                        fill="none"
                        data-name="voters"
                      />
                      <path
                        d="M39.5 1540.0 L47.5 1540.0"
                        fill="none"
                        data-name="voters"
                      />
                      <path
                        d="M39.5 1548.0 L43.5 1544.0 L47.5 1548.0"
                        fill="none"
                        data-name="voters"
                      />
                    </g>
                    <g
                      transform="translate(15, 1544)"
                      fontFamily="Helvetica"
                      fontSize="10pt"
                      fontWeight="normal"
                      fontStyle="normal"
                      data-name="voters"
                    >
                      <g
                        transform="translate(8, 8)"
                        fill="#33322E"
                        text-align="center"
                        data-name="voters"
                      >
                        <text
                          x="20.5"
                          y="11.3"
                          stroke="none"
                          textAnchor="middle"
                          data-name="voters"
                        >
                          Voters
                        </text>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </svg>
        <p className="mb-4">
          Velodrome V2 is still 100% immutable and brings an important
          architectural foundation over the limited v1:
        </p>
        <ul className="mb-8 list-inside list-disc">
          <li>
            a liquidity pool factory registry, which allows us to add new
            liquidity pool types (eg. concentrated, multi-tokens, custom pools)
          </li>
          <li>
            updatable gauges factory, to allow us support maintenance for these
            new pool type gauges and reward contracts
          </li>
          <li>
            updatable rewards/incentives factory, to allows us in case of a
            security incident, to provide quick and long-term maintenance
          </li>
        </ul>
        <p className="mb-4">
          Velodrome V2 issued a new <code>$VELO</code> token, convertible 1-to-1
          with the V1 token.
        </p>
        <p className="mb-4">
          Both versions and tokens operate in parallel and keep the emissions
          running according to the expected schedule.
        </p>
        <p className="mb-4">
          The old token is captured and locked in perpetuity and the new token
          took over the emissions naturally following the same schedule and
          operational purpose.
        </p>
        <hr
          className="border-gray-700/10 dark:border-gray-700/40 my-20"
          id="migration"
        />
        <h1 className="mb-6 text-2xl text-gray-600 dark:text-gray-400">
          Migration
        </h1>
        <p className="mb-4">
          <strong>
            Please review the sections below before taking any V2 migration
            actions!
          </strong>
        </p>
        <br />
        <p className="mb-4">
          After V2 launches, users will see the new Nightride UI load on
          Velodrome’s official domain name https://velodrome.finance, while the
          V1 UI will be available at https://nightride.velodrome.finance.
        </p>
        <p className="mb-4">
          The Nightride UI will provide easy steps to complete all migration
          steps.
        </p>
        <p className="mb-4">
          We appreciate all users' efforts during this transition and will do
          our best to make it as seamless as possible.
        </p>
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          VELO Migration to V2
        </h2>
        <p className="mb-4">
          To migrate <code>VELO</code> tokens to V2, simply use the swap
          functionality on Nightride. V1 <code>VELO</code> will be convertible
          1:1 to V2 with 0 slippage using a special pool.
        </p>
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          Lock (veNFT) Migration to V2
        </h2>
        <p className="mb-4">
          Please note: the V2 UI contains a migration walkthrough on the
          'Dashboard' page. Do not submit a Lock (veNFT) vote on the V1 UI if
          you plan to migrate in the same epoch.
        </p>
        <p className="mb-4">
          Before completing the migration, please make sure you've claimed all
          voting rewards for your Locks (veNFTs) in V1!
        </p>
        <p className="mb-4">
          To migrate <code>veVELO</code>, first unstake all LP positiions and{" "}
          <em>reset</em> your votes. The reset functionality detaches the Lock
          (veNFT) from gauges and allows the V2 migration. V1{" "}
          <code>veVELO</code> is convertible 1:1 to V2.
        </p>
        <p className="mb-4">
          Once completed, holders will receive a <code>veVELO</code> NFT with
          the same lock time and underlying VELO. However, note that migrated
          NFTs will likely have a new ID number, as these follow a sequential
          order when created.
        </p>
        <p className="mb-4">
          One of the V2 veVELO improvements will be the ability to merge NFTs
          without losing a vote. Once converted to V2, veVELO holders will be
          able to consolidate their voting power into a single or a few NFTs any
          time!
        </p>
        <br />
        <h2 className="mb-2 mt-6 text-xl text-gray-600 dark:text-gray-400">
          LP migration to V2
        </h2>
        <p className="mb-4">
          Liquidity Providers are encouraged to migrate their liquidity once V2
          emissions start on June 29. Early movers may find attractive
          opportunities for providing liquidity as VELO V2 emissions flow to the
          new pools.
        </p>
        <p className="mb-4">
          Before doing any migration, please make sure you've claimed all
          emission rewards for V1!
        </p>
        <p className="mb-4">
          To migrate your liquidity positions, first unstake and withdraw your
          liquidity from V1. Then desposit your tokens into the equivalent V2
          liquidity pool and stake your position.
        </p>
        <p className="mb-4">
          A migration screen is available via the Nightride UI dashboard to help
          with the steps outlined here.
        </p>
        <div className="text-primary mt-48 mb-16 text-center">***</div>
        <p className="text-center mb-4 text-3xl font-bold leading-10">
          <em>
            "Don't buy upgrades,
            <br /> ride up grades."
          </em>
        </p>
        <p className="text-center mb-48 text-lg opacity-50">Eddy Merckx</p>
      </div>
    </div>
    {Footer}
  </div>
</div>

)};

export default Docs;