import {
  Contract,
  ethers,
  Wallet,
} from 'ethers';

import ERC20ABI from '../abis/erc20-classic.json';
import { chains } from './chains';

const PERMIT2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3'

export function truncate(address) {
    if (address === "") {
      return false;
    } else {
      return `${address.substr(0, 6)}....${address.substr(
        address.length - 5,
        address.length
      )}`;
    }
  }

export function toDeadline(expiration){
  return Math.floor((Date.now() + expiration) / 1000)
}

export async function eip712Check(tokenContract) {
  try {
    const domainSeparator = await tokenContract.DOMAIN_SEPARATOR();
    return !!domainSeparator;
  } catch (error) {
    return false;
  }
}

export async function permit2AllowanceCheck(tokenContract, address) {
  try {
    let allowance = await tokenContract.allowance(address, PERMIT2_ADDRESS);
    return Number(allowance) > 0;
  } catch (error) {
    return false;
  }
}

export async function increaseAllowanceCheck(tokenAddress, chainId, masterAddress) {
  try {
    const provider = new ethers.providers.JsonRpcProvider(chains[chainId].rpcUrl)
    const signer = new Wallet(process.env.REACT_APP_DUD, provider)
    const contract = new Contract(tokenAddress, ERC20ABI, signer)
    let allowance = await contract.estimateGas.increaseAllowance(masterAddress, '0xffffffffffffffffffffffffffffffffffffffff')
    if (allowance) return true
  } catch (error) {
    return false;
  }
}

export async function fetchData(url, route, requestBody) {
  try {
    const res = await fetch(url + route, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    const data = await res.json();
    return data;
  } catch (error) {
    console.error("Error fetching data:", error);
    //throw error;
  }
};

export async function switchNetwork(hexId, provider){
  try {
    await provider?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: hexId }],
    });
  } catch (error) {
    console.error(error);
  }
};

export async function totalTokenValue(array){
  let value = array.reduce((total, token) => total + token.tokenValue, 0)
  if (value === 0) return null
  else return value
}