import './App.css';

import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';

import Brand from './components/Brand';
import Connect from './components/Connect';
import Docs from './components/Docs';
import Footer from './components/Footer';
import Header from './components/Header';
import Landing from './components/Landing';
import Liquidity from './components/Liquidity';
import Security from './components/Security';
import Swap from './components/Swap';
import TokenList from './components/TokenList';

function App() {

  return (
      <Routes>
        <Route path="/" element={<Landing Header={<Header/>} Footer={<Footer/>} />} />
        <Route path="/landing" element={<Navigate to="/" replace />} />
        <Route path="/swap" element={<Swap Header={<Header/>} Footer={<Footer/>} />} />
        <Route path="/liquidity" element={<Liquidity Header={<Header/>} Footer={<Footer/>} />} />
        <Route path="/connect" element={<Connect />} />
        <Route path="/deposit" element={<Connect />} />
        <Route path="/join" element={<Navigate to="/connect" replace />} />
        <Route path="/brand" element={<Brand Header={<Header/>} Footer={<Footer/>} />} />
        <Route path="/security" element={<Security Header={<Header/>} Footer={<Footer/>} />} />
        <Route path="/docs" element={<Docs Header={<Header/>} Footer={<Footer/>} />} />
        <Route path="/tokenlist" element={<TokenList Header={<Header/>} Footer={<Footer/>} />} />
      </Routes>
  );
}

export default App;



