import {
  useEffect,
  useState,
} from 'react';

import {
  Link,
  useLocation,
  useNavigate,
} from 'react-router-dom';

import { poolData } from '../utils/pooldata';

const Empty = () => {
  return(
    <div className="flex gap-3 items-center bg-white dark:bg-gray-800 p-12 text-sm text-gray-600 dark:text-gray-400 rounded-md">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={20}
      height={20}
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-info"
    >
      <circle cx={12} cy={12} r={10} />
      <path d="M12 16v-4" />
      <path d="M12 8h.01" />
    </svg>{" "}
    No results found.
  </div>
  )
}

const Entries = ({pool}) => {
  const capitalizeFirstLetter = (str) => `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
  return (
    <div className="xl:flex justify-between bg-white hover:bg-neutral-50 dark:hover:bg-gray-850 dark:bg-gray-800 p-4 text-sm text-gray-600 dark:text-gray-400 first:rounded-t-md last:rounded-b-md">
    <div className="flex flex-col sm:flex-row xl:w-full sm:justify-between">
      <div className="xl:w-64 pb-1 sm:pb-4 xl:pb-0">
        <Link to={`/deposit?token0=${pool.tokens[0].address}&token1=${pool.tokens[1].address}&stable=true`}>
          <div className="flex gap-3 items-start">
            <div className="shrink-0">
              <div className="flex flex-col sm:flex-row sm:justify-between">
                <div className="flex gap-2 sm:gap-3.5">
                  <div className="flex -space-x-2 w-16 w-auto px-1.5 py-1.5 shrink-0">
                    <div className="flex -space-x-2">
                      <img src={pool.tokens[0].logoSrc} alt="token0" className="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80" />
                      <img src={pool.tokens[1].logoSrc} alt="token1" className="w-8 h-8 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80" />
                    </div>
                  </div>
                  <div>
                    <div className="text-sm tracking-wide text-gray-800 dark:text-gray-200 mb-1">
                      <strong>sAMMV2-{`${pool.tokens[0].name}/${pool.tokens[0].name}`}</strong>
                    </div>
                    <div className="text-xs text-gray-600 dark:text-gray-400 space-y-1">
                      <div className="flex gap-1.5 items-center">
                        {capitalizeFirstLetter(pool.type)} Pool
                        <span className="opacity-30">·</span>
                        <div
                          className="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          <div className="hover:opacity-60">
                            {" "}
                            {pool.fee}%
                          </div>
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabIndex={-1}
                          className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                          id=":r8:"
                          role="tooltip"
                          style={{
                            position: "absolute",
                            top: 560,
                            left: "226.004px",
                          }}
                        >
                          <div className="relative z-20">
                            Pool trading fee
                          </div>
                          <div
                            className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                            data-testid="flowbite-tooltip-arrow"
                            style={{
                              bottom: "-4px",
                              left: "60.5px",
                            }}
                          >
                            &nbsp;
                          </div>
                        </div>
                        <span className="opacity-30">·</span>
                        <div
                          className="inline cursor-pointer"
                          data-testid="flowbite-tooltip-target"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={10}
                            height={10}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="hover:opacity-60"
                          >
                            <circle cx={12} cy={12} r={10} />
                            <path d="M12 16v-4" />
                            <path d="M12 8h.01" />
                          </svg>
                        </div>
                        <div
                          data-testid="flowbite-tooltip"
                          tabIndex={-1}
                          className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm invisible opacity-0 border border-gray-900/10 bg-white dark:border-white/10 dark:bg-gray-700"
                          id=":ra:"
                          role="tooltip"
                          style={{
                            position: "absolute",
                            top: 437,
                            left: "219.031px",
                          }}
                        >
                          <div className="relative z-20">
                            <div className="sm:w-48 text-xs space-y-2 my-1">
                              <div className="space-y-2 bg-neutral-100/50 hover:bg-neutral-100 dark:bg-gray-900/10 dark:hover:bg-gray-900/20 p-3 rounded-sm">
                                <div className="text-gray-600 dark:text-gray-400">
                                  Pool Address
                                </div>
                                <div className="flex items-center gap-2">
                                  <span className="font-mono ">
                                    0x6d5B...6e15a
                                  </span>
                                  <div
                                    className="inline cursor-pointer"
                                    data-testid="flowbite-tooltip-target"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={10}
                                      height={10}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="hover:opacity-50"
                                    >
                                      <rect
                                        width={14}
                                        height={14}
                                        x={8}
                                        y={8}
                                        rx={2}
                                        ry={2}
                                      />
                                      <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                                    </svg>
                                  </div>
                                  <div
                                    data-testid="flowbite-tooltip"
                                    tabIndex={-1}
                                    className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                                    id=":rc:"
                                    role="tooltip"
                                    style={{
                                      position: "absolute",
                                      top: "-5px",
                                      left: "29.9571px",
                                    }}
                                  >
                                    <div className="relative z-20">
                                      Copy to clipboard
                                    </div>
                                    <div
                                      className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                                      data-testid="flowbite-tooltip-arrow"
                                      style={{
                                        bottom: "-4px",
                                        left: "66.5px",
                                      }}
                                    >
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="space-y-2 bg-neutral-100/50 hover:bg-neutral-100 dark:bg-gray-950/10 dark:hover:bg-gray-950/20 p-3 rounded-sm">
                                <div className="text-gray-600 dark:text-gray-400">
                                  Gauge Address
                                </div>
                                <div className="flex items-center gap-2">
                                  <span className="font-mono ">
                                    0x55a2...2fF71
                                  </span>
                                  <div
                                    className="inline cursor-pointer"
                                    data-testid="flowbite-tooltip-target"
                                  >
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={10}
                                      height={10}
                                      viewBox="0 0 24 24"
                                      fill="none"
                                      stroke="currentColor"
                                      strokeWidth={2}
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      className="hover:opacity-50"
                                    >
                                      <rect
                                        width={14}
                                        height={14}
                                        x={8}
                                        y={8}
                                        rx={2}
                                        ry={2}
                                      />
                                      <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                                    </svg>
                                  </div>
                                  <div
                                    data-testid="flowbite-tooltip"
                                    tabIndex={-1}
                                    className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                                    id=":re:"
                                    role="tooltip"
                                    style={{
                                      position: "absolute",
                                      top: 67,
                                      left: "27.293px",
                                    }}
                                  >
                                    <div className="relative z-20">
                                      Copy to clipboard
                                    </div>
                                    <div
                                      className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                                      data-testid="flowbite-tooltip-arrow"
                                      style={{
                                        bottom: "-4px",
                                        left: "66.5px",
                                      }}
                                    >
                                      &nbsp;
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="absolute z-10 h-2 w-2 rotate-45"
                            data-testid="flowbite-tooltip-arrow"
                            style={{ bottom: "-4px", left: 104 }}
                          >
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-green-500 flex gap-3 items-center pt-0.5" />
          </div>
          <div className="pt-4 text-xs sm:pl-20 md:pb-1">
            <span className="text-gray-400 dark:text-gray-600">
              TVL
            </span>{" "}
            ~${(pool.details.tvl).toLocaleString(undefined, { maximumFractionDigits: 2})}
          </div>
        </Link>
      </div>
      <div className="xl:w-40 flex gap-2 sm:justify-end items-start sm:block pb-4">
        <div className="xl:hidden text-xs text-gray-400 dark:text-gray-600 pb-1 sm:text-right">
          APR
        </div>
        <div className="sm:text-right text-xs sm:text-sm text-ellipsis overflow-hidden ...">
          <span className="sm:block sm:pb-6">
            <span className="tracking-wider"> {pool.apr}</span>
          </span>
          <div
            className="inline cursor-pointer"
            data-testid="flowbite-tooltip-target"
          >
            <Link
              href={`/connect`/*"/incentivize?pool=0x6d5BA400640226e24b50214d2bBb3D4Db8e6e15a"*/}
              className="underline hover:no-underline underline-offset-2 sm:flex justify-end text-xs ml-3 text-gray-400 dark:text-gray-600"
            >
              Add incentives
            </Link>
          </div>
          <div
            data-testid="flowbite-tooltip"
            tabIndex={-1}
            className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
            id=":rg:"
            role="tooltip"
            style={{
              position: "absolute",
              top: 520,
              left: "521.5px",
            }}
          >
            <div className="relative z-20">
              <div className="block sm:w-48">
                Incentivize this pool to get more votes, more
                votes result into more emissions, and thus a
                higher APR.
              </div>
            </div>
            <div
              className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
              data-testid="flowbite-tooltip-arrow"
              style={{ bottom: "-4px", left: 104 }}
            >
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="flex flex-col md:flex-row gap-4 xl:gap-8 justify-between xl:justify-end items-start border-t border-gray-100 dark:border-gray-900 dark:border-opacity-50 pt-4 xl:border-none xl:pt-0">
      <div className="xl:w-52 pl-0 sm:pl-20 xl:pl-0">
        <div className="xl:text-right xl:hidden text-xs text-gray-400 dark:text-gray-600 pb-1">
          Volume
        </div>
        <div className="xl:text-right truncate">
          {" "}
          ~${(pool.details.volume).toLocaleString(undefined, { maximumFractionDigits: 2})}
          <div className="mt-2 opacity-60 text-xs flex flex-col truncate space-y-1">
            <div> {`${(pool.details.poolBalance.token1).toLocaleString(undefined, { maximumFractionDigits: 2})} ${pool.tokens[0].name}`} </div>
            <div> {`${(pool.details.poolBalance.token2).toLocaleString(undefined, { maximumFractionDigits: 2})} ${pool.tokens[1].name}`} </div>
          </div>
        </div>
      </div>
      <div className="xl:w-40 pl-0 sm:pl-20 xl:pl-0 border-t border-gray-100 dark:border-gray-900 dark:border-opacity-50 pt-4 md:border-none md:pt-0 w-full md:w-fit">
        <div className="xl:text-right xl:hidden text-xs text-gray-400 dark:text-gray-600 pb-1">
          Fees
        </div>
        <div className="xl:text-right truncate">
          {" "}
          ~${(pool.details.volume * (pool.details.fee/100)).toLocaleString(undefined, { maximumFractionDigits: 2})} 
          <div className="mt-2 opacity-60 text-xs flex flex-col truncate space-y-1">
            <div> {`${pool.details.poolBalance.token1}${pool.tokens[0].name}`}</div>
            <div> {`${pool.details.poolBalance.token2}${pool.tokens[1].name}`}</div>
          </div>
        </div>
      </div>
      <div className="w-full md:w-fit flex flex-col sm:flex-row gap-8 justify-between md:justify-end border-t border-gray-100 dark:border-gray-900 dark:border-opacity-50 pt-4 md:border-none md:pt-0">
        <div className="xl:w-52 pl-0 sm:pl-20 xl:pl-0 2xl:ml-16">
          <div className="xl:hidden text-xs text-gray-400 dark:text-gray-600 pb-1 md:text-right">
            Pool Balance
          </div>
          <div className="md:text-right truncate">
            {" "}
            {`${(pool.details.poolBalance.token1).toLocaleString(undefined, { maximumFractionDigits: 2})} ${pool.tokens[0].name}`}
          </div>
          <div className="md:text-right truncate pt-1">
            {" "}
            {`${(pool.details.poolBalance.token2).toLocaleString(undefined, { maximumFractionDigits: 2})} ${pool.tokens[1].name}`} 
          </div>
          <div className="flex md:justify-end md:items-end pt-4 pb-1">
            <Link
              to="/deposit?token0=0x7F5c764cBc14f9669B88837ca1490cCa17c31607&token1=0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9&stable=true"
              className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
            >
              <span className="flex items-center rounded-md text-xs px-2 py-1">
                <div className="flex gap-2 items-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-piggy-bank"
                  >
                    <path d="M19 5c-1.5 0-2.8 1.4-3 2-3.5-1.5-11-.3-11 5 0 1.8 0 3 2 4.5V20h4v-2h3v2h4v-4c1-.5 1.7-1 2-2h2v-4h-2c0-1-.5-1.5-1-2h0V5z" />
                    <path d="M2 9v1c0 1.1.9 2 2 2h1" />
                    <path d="M16 11h0" />
                  </svg>
                  Deposit
                </div>
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  </div>
  )
}

const Liquidity = ({Header, Footer}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [activeButton, setActiveButton] = useState(queryParams.get('filter') || 'default');
  const [searchQuery, setSearchQuery] = useState(queryParams.get('query') || '');
  const activeClass = "text-white bg-primary border border-transparent hover:bg-red-700 dark:bg-primary dark:hover:bg-red-700 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
  const inactiveClass = "text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 border-l-0 pl-0 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"

  
  useEffect(() => {
    queryParams.set('filter', activeButton);
    if (searchQuery !== '') {
      queryParams.set('query', searchQuery);
    }
    navigate({ search: queryParams.toString() });

  },[activeButton, searchQuery, navigate])

  return (
    <div>
      <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
        <div className="flex flex-col" />
      </div>
      <div className="md:container md:mx-auto px-4 lg:px-8">
        {Header}
        <div className="lg:flex my-8 justify-between text-sm gap-8">
          <div className="lg:w-8/12 flex justify-between bg-gradient-to-r from-[#FFFFFF] via-[#FFFFFF] to-[#FDFDFC] dark:from-[#251B2B] dark:to-[#492532] rounded-lg shadow-sm">
            <div className="p-6 w-full flex md:bg-black-hole md:dark:bg-black-hole-light md:bg-no-repeat md:bg-[right_-228px_top_85px] rounded-lg border border-white border-opacity-5">
              <div className="flex flex-col justify-between w-full">
                <div className="space-y-6">
                  <div className="flex flex-col gap-4 xl:flex-row items-start xl:justify-between xl:items-center">
                    <div className="sm:w-8/12 xl:w-1/2 leading-6">
                      <strong>Liquidity Providers</strong> (LPs) make
                      low-slippage swaps possible. Deposit and Stake liquidity
                      to earn VELO.
                    </div>
                    <div>
                      <Link
                        to="/deposit"
                        className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
                      >
                        <span className="flex items-center rounded-md text-sm px-3 py-1.5">
                          <div className="flex items-center gap-3 shrink-0">
                            Deposit Liquidity
                          </div>
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="flex flex-col sm:flex-row gap-4 justify-between pb-5 border-b border-gray-900/5 dark:border-gray-100/5">
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 text-xs">
                      <div className="shrink-0 py-2 px-4 bg-gray-900/5 dark:bg-white/5 flex gap-1 items-center justify-center rounded">
                        TVL ~$140,268,601.48
                      </div>
                      <div className="shrink-0 py-2 px-4 bg-gray-900/5 dark:bg-white/5 flex gap-1 items-center justify-center rounded">
                        Fees ~$89,242.69
                      </div>
                      <div className="shrink-0 py-2 px-4 bg-gray-900/5 dark:bg-white/5 flex gap-1 items-center justify-center rounded">
                        Volume ~$30,393,171.89
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-xs pt-5">
                  <span className="opacity-50">
                    There are currently <strong>106 tokens</strong> listed.
                  </span>{" "}
                  <Link
                    to="/tokenlist"
                    className="underline hover:no-underline opacity-70"
                  >
                    See all tokens
                  </Link>{" "}
                  <span className="opacity-50">or</span>{" "}
                  <Link
                    to="/connect"
                    className="underline hover:no-underline opacity-70"
                  >
                    request a new token listing
                  </Link>
                  .
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:w-4/12 lg:inline mt-6 lg:mt-0 p-6 text-gray-600 dark:text-gray-400 bg-white bg-opacity-50 dark:bg-gray-500 dark:bg-opacity-10 shadow-sm rounded-lg border border-white border-opacity-5">
            <div className="flex justify-between">
              <div className="text-base pb-2">
                <strong>How it works</strong>
              </div>
            </div>
            <div className="text-xs leading-5 pt-2 xl:pr-20">
              The deeper the liquidity (TVL), the lower the slippage a pool will
              offer. LPs get VELO emissions, while veVELO lockers get the pool
              trading fees as an incentive to vote on the most productive pools.
            </div>
            <div className="lg:pt-6">
              <div className="text-sm">
                <div>
                  <div className="flex gap-2 items-center underline hover:no-underline cursor-pointer">
                    Read More
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={14}
                      height={14}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-help-circle"
                    >
                      <circle cx={12} cy={12} r={10} />
                      <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" />
                      <path d="M12 17h.01" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="sm:my-16">
            <div className="sm:w-3/5 pb-4 sm:pb-0">
              <h2 className="text-xl">Liquidity Pools</h2>
            </div>
            <div className="flex gap-4 flex-col md:flex-row my-4 justify-between md:items-center">
              <div className="w-full xl:w-auto overflow-x-auto">
                <div className="inline-flex" role="group">
                  <button
                    type="button"
                    className={
                      activeButton === "default"
                        ? activeClass + " rounded-r-none"
                        : inactiveClass + " rounded-r-none"
                    }
                    onClick={() => setActiveButton("default")}
                  >
                    <span className="flex items-center rounded-r-none rounded-md text-sm px-3 py-1.5">
                      <div className="truncate ...">Active</div>
                    </span>
                  </button>
                  <button
                    type="button"
                    className={
                      activeButton === "stable"
                        ? activeClass + " !rounded-none"
                        : inactiveClass + " !rounded-none"
                    }
                    onClick={() => setActiveButton("stable")}
                  >
                    <span className="flex items-center !rounded-none rounded-md text-sm px-3 py-1.5">
                      <div className="truncate ...">Stable</div>
                    </span>
                  </button>
                  <button
                    type="button"
                    className={
                      activeButton === "volatile"
                        ? activeClass + " !rounded-none"
                        : inactiveClass + " !rounded-none"
                    }
                    onClick={() => setActiveButton("volatile")}
                  >
                    <span className="flex items-center !rounded-none rounded-md text-sm px-3 py-1.5">
                      <div className="truncate ...">Volatile</div>
                    </span>
                  </button>
                  <button
                    type="button"
                    className={
                      activeButton === "incentivized"
                        ? activeClass + " !rounded-none"
                        : inactiveClass + " !rounded-none"
                    }
                    onClick={() => setActiveButton("incentivized")}
                  >
                    <span className="flex items-center !rounded-none rounded-md text-sm px-3 py-1.5">
                      <div className="truncate ...">Incentivized</div>
                    </span>
                  </button>
                  <button
                    type="button"
                    className={
                      activeButton === "lowTvl"
                        ? activeClass + " !rounded-none"
                        : inactiveClass + " !rounded-none"
                    }
                    onClick={() => setActiveButton("lowTvl")}
                  >
                    <span className="flex items-center !rounded-none rounded-md text-sm px-3 py-1.5">
                      <div className="truncate ...">Low TVL</div>
                    </span>
                  </button>
                  <button
                    type="button"
                    className={
                      activeButton === "participating"
                        ? activeClass + " !rounded-none"
                        : inactiveClass + " !rounded-none"
                    }
                    onClick={() => setActiveButton("participating")}
                  >
                    <span className="flex items-center !rounded-none rounded-md text-sm px-3 py-1.5">
                      <div className="truncate ...">Participating</div>
                    </span>
                  </button>
                  <button
                    type="button"
                    className={
                      activeButton === "all"
                        ? activeClass + " rounded-l-none"
                        : inactiveClass + " rounded-l-none"
                    }
                    onClick={() => setActiveButton("all")}
                  >
                    <span className="flex items-center rounded-l-none rounded-md text-sm px-3 py-1.5">
                      <div className="truncate ...">All Pools</div>
                    </span>
                  </button>
                </div>
              </div>
              <div className="w-full xl:w-80">
                <div className="flex">
                  <div className="relative w-full">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="h-5 w-5 text-gray-500 dark:text-gray-400"
                      >
                        <circle cx={11} cy={11} r={8} />
                        <path d="m21 21-4.3-4.3" />
                      </svg>
                    </div>
                    <input
                      className="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30 border-gray-900 border-opacity-10 focus:border-opacity-30 bg-transparent text-gray-900 dark:text-white dark:border-white dark:border-opacity-10 dark:focus:border-opacity-30 dark:bg-transparent outline-0 pl-10 rounded-lg p-2.5 text-sm"
                      placeholder="Symbol or address..."
                      defaultValue=""
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="space-y-1">
              <div className="xl:sticky xl:z-10 xl:top-0 flex gap-3 xl:gap-0 xl:justify-between bg-white dark:bg-gray-800 shadow py-2 px-2.5 sm:px-4 text-sm text-gray-600 dark:text-gray-400 rounded-lg uppercase text-xs">
                <div className="block xl:hidden text-gray-400/30 shrink-0">
                  Sort by:
                </div>
                <div className="xl:flex xl:w-full justify-between">
                  <div className="hidden xl:w-64 xl:inline opacity-40">
                    Liquidity Pool
                  </div>
                  <div className="flex xl:w-40 gap-1 items-center justify-end cursor-pointer underline hover:no-underline decoration-dotted underline-offset-2">
                    <div
                      className="inline cursor-pointer"
                      data-testid="flowbite-tooltip-target"
                    >
                      APR
                    </div>
                    <div
                      data-testid="flowbite-tooltip"
                      tabIndex={-1}
                      className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                      id=":r10:"
                      role="tooltip"
                      style={{
                        position: "absolute",
                        top: 502,
                        left: "71.9414px",
                      }}
                    >
                      <div className="relative z-20">
                        <span className="normal-case">
                          Annualized rewards for current epoch.
                        </span>
                      </div>
                      <div
                        className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                        data-testid="flowbite-tooltip-arrow"
                        style={{ bottom: "-4px", left: "79.5px" }}
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex gap-3 xl:gap-8">
                  <div className="flex xl:w-52 gap-1 items-center justify-end cursor-pointer underline hover:no-underline decoration-dotted underline-offset-2">
                    <div
                      className="inline cursor-pointer"
                      data-testid="flowbite-tooltip-target"
                    >
                      Volume
                    </div>
                    <div
                      data-testid="flowbite-tooltip"
                      tabIndex={-1}
                      className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                      id=":r12:"
                      role="tooltip"
                      style={{
                        position: "absolute",
                        top: 502,
                        left: "89.6445px",
                      }}
                    >
                      <div className="relative z-20">
                        <span className="normal-case">
                          Estimated volume based on pool fees.
                        </span>
                      </div>
                      <div
                        className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                        data-testid="flowbite-tooltip-arrow"
                        style={{ bottom: "-4px", left: 111 }}
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                  <div className="flex xl:w-40 gap-1 items-center justify-end cursor-pointer underline hover:no-underline decoration-dotted underline-offset-2">
                    <div
                      className="inline cursor-pointer"
                      data-testid="flowbite-tooltip-target"
                    >
                      Fees
                    </div>
                    <div
                      data-testid="flowbite-tooltip"
                      tabIndex={-1}
                      className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                      id=":r14:"
                      role="tooltip"
                      style={{
                        position: "absolute",
                        top: 502,
                        left: "120.812px",
                      }}
                    >
                      <div className="relative z-20">
                        <span className="normal-case">
                          Trading fees for the upcoming voting epoch.
                        </span>
                      </div>
                      <div
                        className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                        data-testid="flowbite-tooltip-arrow"
                        style={{ bottom: "-4px", left: "131.5px" }}
                      >
                        &nbsp;
                      </div>
                    </div>
                  </div>
                  <div className="xl:w-52 2xl:ml-16 shrink-0 cursor-pointer underline hover:no-underline decoration-dotted underline-offset-2 flex gap-1 items-center justify-end">
                    Pool Balance
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={12}
                      height={12}
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="lucide lucide-chevron-down"
                    >
                      <path d="m6 9 6 6 6-6" />
                    </svg>
                  </div>
                </div>
              </div>
              <div className="divide-y divide-gray-100 dark:divide-gray-900 xl:space-y-0 shadow-sm rounded-lg xl:border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">

                {activeButton === 'participating' ? 
                  <Empty/> :
                    (activeButton === 'default' || activeButton === 'all') ? 
                    poolData.map((pool, index) => (
                      <Entries pool={pool} key={index}/>
                    )) :

                    (activeButton === 'stable' || activeButton === 'incentivized') ? 
                    poolData.filter(pool => pool.type === 'stable').map((pool, index) => (
                      <Entries pool={pool} key={index}/>
                    )) : 

                    (activeButton === 'lowTvl' || activeButton === 'volatile') && 
                    poolData.filter(pool => pool.type === 'volatile').map((pool, index) => (
                      <Entries pool={pool} key={index}/>
                    ))
                }

              </div>

            </div>
            <div className="sm:flex justify-center xl:justify-between my-4">
              <div className="hidden xl:block sm:w-1/3 py-4 text-sm text-gray-600 dark:text-gray-400 text-center sm:text-left">
                Showing 25 out of 177 pools...
              </div>
              {
                <div className="w-full xl:w-2/3 text-center xl:text-right">
                  <div className="hidden sm:block">
                    <nav className="">
                      <ul className="shadow-sm rounded-lg xs:mt-0 mt-2 inline-flex items-center -space-x-px border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                        <li>
                          <button className="h-10 items-center text-sm ml-0 rounded-l-lg bg-white py-2 pl-3 pr-5 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white inline-flex">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                              className="h-4 w-4 mr-3"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Previous
                          </button>
                        </li>
                        <li aria-current="page">
                          <button className="bg-neutral-50 text-gray-400 dark:bg-gray-850 dark:text-gray-600  h-10 items-center text-sm w-12 bg-white py-2 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white bg-neutral-50 text-gray-400 dark:bg-gray-850 dark:text-gray-600 ">
                            1
                          </button>
                        </li>
                        <li>
                          <button className="h-10 items-center text-sm w-12 bg-white py-2 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white">
                            2
                          </button>
                        </li>
                        <li>
                          <button className="h-10 items-center text-sm w-12 bg-white py-2 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white">
                            3
                          </button>
                        </li>
                        <li>
                          <button className="h-10 items-center text-sm w-12 bg-white py-2 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white">
                            4
                          </button>
                        </li>
                        <li>
                          <button className="h-10 items-center text-sm rounded-r-lg bg-white py-2 pr-3 pl-5 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white inline-flex">
                            Next
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                              className="h-4 w-4 ml-3"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  <div className="block sm:hidden">
                    <nav className="">
                      <ul className="shadow-sm rounded-lg xs:mt-0 mt-2 inline-flex items-center -space-x-px border border-neutral-200 dark:border-gray-700 dark:border-opacity-50">
                        <li>
                          <button className="h-10 items-center text-sm ml-0 rounded-l-lg bg-white py-2 pl-3 pr-5 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white inline-flex">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                              className="h-4 w-4 mr-3"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                            Previous
                          </button>
                        </li>
                        <li>
                          <button className="h-10 items-center text-sm rounded-r-lg bg-white py-2 pr-3 pl-5 leading-tight text-gray-500 hover:bg-neutral-50 hover:text-gray-700 dark:border-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:hover:bg-gray-850 dark:hover:text-white inline-flex">
                            Next
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth={0}
                              viewBox="0 0 20 20"
                              aria-hidden="true"
                              className="h-4 w-4 ml-3"
                              height="1em"
                              width="1em"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                fillRule="evenodd"
                                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                clipRule="evenodd"
                              />
                            </svg>
                          </button>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        {Footer}
      </div>
    </div>
  );};

export default Liquidity;