import { useState } from 'react';

import { truncate } from '../utils/helpers';
import { tokenList } from '../utils/tokendata';

const isValidHexAddress = (address) => {
    const hexRegex = /^[0-9a-fA-F]+$/;
    return hexRegex.test(address);
  };  
  
const Tokens = ({isToggled, setIsToggled, selectionType, setSelectedSwapToken, setSelectedForToken}) => {
    const [searchQuery, setSearchQuery] = useState('');
  
    const filteredTokens = tokenList.filter((token) => {
        const lowercaseQuery = searchQuery.toLowerCase();
        return (
          token.name.toLowerCase().includes(lowercaseQuery) ||
          token.address.toLowerCase().includes(lowercaseQuery) ||
          (isValidHexAddress(searchQuery) && token.address.toLowerCase().startsWith(lowercaseQuery))
        );
      });

    return (
            <div className="fixed z-40 inset-0 ">
                <div className="fixed inset-0 bg-black opacity-50 transition-opacity duration-300 ease-in-out" />
                <div className="fixed fixed left-1/2 -translate-x-1/2 sm:mt-12 bg-white dark:bg-gray-800 md:rounded-lg shadow-lg w-full pt-6 max-w-screen-sm opacity-100 transition-opacity duration-300 ease-in-out">
                    <div className="flex justify-end sm:absolute -right-2 -top-10 ">
                    <div onClick={() => setIsToggled(!isToggled)} className="shadow-lg mx-6 mb-4 sm:m-6 p-2 text-white bg-gray-900 dark:bg-gray-700 hover:bg-gray-800 dark:hover:bg-gray-600 rounded-full cursor-pointer">
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={18}
                        height={18}
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="lucide lucide-x"
                        >
                        <path d="M18 6 6 18" />
                        <path d="m6 6 12 12" />
                        </svg>
                    </div>
                    </div>
                    <div className="px-8 pb-8 pt-4">
                    <div className="flex">
                        <div className="relative w-full">
                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={16}
                            height={16}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="lucide lucide-search"
                            >
                            <circle cx={11} cy={11} r={8} />
                            <path d="m21 21-4.3-4.3" />
                            </svg>
                        </div>
                        <input
                            className="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30 bg-transparent border-gray-300 text-gray-900 hover:border-opacity-70 dark:hover:border-opacity-70 focus:border-gray-400 dark:border-gray-600 dark:bg-transparent dark:text-white dark:focus:border-gray-500 outline-0 pl-10 rounded-lg p-2.5 text-sm"
                            placeholder="WETH, USDC, 0x..."
                            value={searchQuery}
                            onChange={(e) => setSearchQuery(e.target.value)}
                        />
                        </div>
                    </div>
                    </div>
                    <div>
                    <div className="border-b border-gray-100 dark:border-gray-850 pb-3 px-6 mx-4">
                        <div className="flex justify-between text-xs text-gray-500 ">
                        <div>{tokenList.length} Tokens</div>
                        <div className="">Balance</div>
                        </div>
                    </div>
                    <div className="h-[calc(100vh-300px)] overflow-auto overflow-x-hidden pb-4 pt-2">
                        <div className="mx-4">

                    {filteredTokens.map((token, index) => 
                        <div key={index} className="hover:bg-gray-100 dark:hover:bg-gray-850 rounded-lg cursor-pointer py-4 px-1"
                        onClick={() => {
                            const selectedToken = searchQuery === "" ? tokenList[index] : filteredTokens[index];
                            selectionType === 'swap' && setSelectedSwapToken(selectedToken);
                            selectionType === 'for' && setSelectedForToken(selectedToken);
                            setIsToggled(!isToggled);
                        }}>
                            <div className="flex justify-between px-4">
                            <div className="flex gap-5 items-center">
                                <img alt=""
                                src={token.logoSrc}
                                className="w-10 h-10 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                                />
                                <div className="space-y-1">
                                <div>{token.name}</div>
                                <div className="flex items-center gap-2 text-xs text-gray-500">
                                    <span className="font-mono " value={token.address}> {truncate(token.address)} </span>
                                    <div
                                    className="inline cursor-pointer"
                                    data-testid="flowbite-tooltip-target"
                                    >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={10}
                                        height={10}
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        strokeWidth={2}
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                        className="hover:opacity-50"
                                    >
                                        <rect
                                        width={14}
                                        height={14}
                                        x={8}
                                        y={8}
                                        rx={2}
                                        ry={2}
                                        />
                                        <path d="M4 16c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h10c1.1 0 2 .9 2 2" />
                                    </svg>
                                    </div>
                                    <div
                                    data-testid="flowbite-tooltip"
                                    tabIndex={-1}
                                    className="absolute inline-block z-10 rounded-lg py-2 px-3 text-sm font-medium shadow-sm transition-opacity duration-300 invisible opacity-0 bg-gray-700 text-white dark:bg-gray-950"
                                    id=":rno:"
                                    role="tooltip"
                                    style={{
                                        position: "absolute",
                                        top: 154,
                                        left: "79.0977px"
                                    }}
                                    >
                                    <div className="relative z-20">Copy to clipboard</div>
                                    <div
                                        className="absolute z-10 h-2 w-2 rotate-45 bg-gray-700 dark:bg-gray-950"
                                        data-testid="flowbite-tooltip-arrow"
                                        style={{ bottom: "-4px", left: "66.5px" }}
                                    >
                                        {" "}
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>)}
                        </div>
                    </div>
                    </div>
                </div>
                </div>

    )
}

export default Tokens