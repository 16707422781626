import { useState } from 'react';

import { Link } from 'react-router-dom';

const Footer = () => {
  const [isMirrorToggle, setMirrorToggle] = useState(false)
  const isDarkMode = false
  const toggleDarkMode = () => {
  const htmlTag = document.querySelector('html');
  if (htmlTag) {
    htmlTag.classList.toggle('dark', !htmlTag.classList.contains('dark'));
  }
};

  const mirrorToggle = () => {
    setMirrorToggle(!isMirrorToggle);
  }

  return(
    <div className="pb-6 pt-24 md:pb-16 px-2 sm:px-0 text-sm">
  <div className="flex justify-between md:items-center items-end">
    <div className="flex flex-col gap-8 md:flex-row md:gap-8 md:items-center">
      <div className="flex flex-col gap-2 md:flex-row md:gap-5">
        <a
          className="hover:underline"
          href="https://medium.com/@VelodromeFi"
          target="_blank"
          rel="noreferrer"
        >
          Articles
        </a>
        <a
          className="hover:underline"
          href="https://discord.gg/velodrome"
          target="_blank"
          rel="noreferrer"
        >
          Support
        </a>
      </div>
      <div className="flex gap-3 items-center pb-3 md:pb-0">
        <a
          href="https://twitter.com/VelodromeFi"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            data-src="/svg/icn-x.svg"
            className="injected-svg h-7 w-7 hover:opacity-80"
            src=""
            fill="none"
            viewBox="0 0 32 32"
            height={32}
            width={32}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              xmlns="http://www.w3.org/2000/svg"
              opacity="0.05"
              cx={16}
              cy={16}
              r={16}
              fill="currentColor"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M8.03893 9L14.2163 17.2596L8 23.9751H9.39916L14.8417 18.0955L19.2389 23.9751H24L17.4749 15.251L23.2611 9H21.8619L16.8498 14.4149L12.8 9H8.03893ZM10.0965 10.0305H12.2837L21.9422 22.9446H19.755L10.0965 10.0305Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <a href="https://discord.gg/velodrome" target="_blank" rel="noreferrer">
          <svg
            data-src="/svg/icn-discord.svg"
            className="injected-svg h-7 w-7 hover:opacity-80"
            src=""
            fill="none"
            viewBox="0 0 32 32"
            height={32}
            width={32}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              xmlns="http://www.w3.org/2000/svg"
              opacity="0.05"
              cx={16}
              cy={16}
              r={16}
              fill="currentColor"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M22.942 10.1969C21.6304 9.63054 20.2429 9.22805 18.816 9C18.638 9.29622 18.431 9.69578 18.287 10.0123C16.7714 9.79914 15.2296 9.79914 13.714 10.0123C13.5551 9.66643 13.3765 9.32852 13.179 9C11.7509 9.22842 10.3623 9.63187 9.04997 10.1996C6.43895 13.841 5.73095 17.3919 6.08495 20.892C7.60911 21.954 9.32157 22.7636 11.146 23.2848C11.5559 22.7642 11.9185 22.2133 12.23 21.6376C11.6375 21.4292 11.0662 21.1726 10.523 20.8708C10.666 20.773 10.806 20.6705 10.941 20.5653C14.232 21.9855 17.807 21.9855 21.059 20.5653C21.196 20.6705 21.336 20.773 21.477 20.8708C20.936 21.1716 20.363 21.43 19.767 21.6385C20.078 22.2145 20.4406 22.7655 20.851 23.2857C22.6769 22.7648 24.3905 21.9545 25.9151 20.8911C26.3301 16.8345 25.2061 13.3159 22.942 10.1969ZM12.678 18.7391C11.69 18.7391 10.88 17.8883 10.88 16.852C10.88 15.8157 11.673 14.9631 12.678 14.9631C13.683 14.9631 14.493 15.8139 14.476 16.852C14.477 17.8883 13.683 18.7391 12.678 18.7391ZM19.322 18.7391C18.334 18.7391 17.524 17.8883 17.524 16.852C17.524 15.8157 18.317 14.9631 19.322 14.9631C20.327 14.9631 21.137 15.8139 21.12 16.852C21.12 17.8883 20.327 18.7391 19.322 18.7391Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <a
          href="https://debank.com/official-account/113879"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            data-src="/svg/icn-debank.svg"
            className="injected-svg h-7 w-7 hover:opacity-80"
            src=""
            fill="none"
            viewBox="0 0 32 32"
            height={32}
            width={32}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              xmlns="http://www.w3.org/2000/svg"
              opacity="0.05"
              cx={16}
              cy={16}
              r={16}
              fill="currentColor"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M18.1545 24C20.8287 24 23 21.8515 23 19.2059C23 18.0263 22.5641 16.8805 21.7677 16.0042C22.5641 15.128 23 13.9905 23 12.8025C23 10.1569 20.8287 8.00843 18.1545 8H9.53653H9V8.01685C9.17605 8.00843 9.36048 8 9.53653 8C12.5293 8.00843 15.1868 9.26382 16.8635 11.2017H18.1545C19.0347 11.2017 19.7473 11.9179 19.7473 12.8025C19.7473 13.5493 19.2396 14.176 18.5518 14.3532C18.7153 15.2602 18.7326 16.6555 18.6036 17.661C19.2649 17.8557 19.7473 18.4696 19.7473 19.1975C19.7473 20.0821 19.0347 20.7983 18.1545 20.7983H16.8635C15.1952 22.7362 12.5377 24 9.54491 24C9.36048 24 9.18443 23.9916 9.00838 23.9831C9 23.9916 9 20.7983 9 20.7983V24H9.54491H18.1545Z"
              fill="currentColor"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              d="M9 8.00832C13.4599 8.00832 17.0731 11.5891 17.0731 16.0041C17.0731 20.4191 13.4599 24 9 24V20.7983C11.6743 20.7983 13.8455 18.6497 13.8455 16.0041C13.8455 13.3585 11.6743 11.21 9 11.21V8.00832Z"
              fill="currentColor"
            />
          </svg>
        </a>
        <a
          href="https://github.com/velodrome-finance"
          target="_blank"
          rel="noreferrer"
        >
          <svg
            data-src="/svg/icn-git.svg"
            className="injected-svg h-7 w-7 hover:opacity-80"
            src=""
            fill="none"
            viewBox="0 0 32 32"
            height={32}
            width={32}
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              xmlns="http://www.w3.org/2000/svg"
              opacity="0.05"
              cx={16}
              cy={16}
              r={16}
              fill="currentColor"
            />
            <path
              xmlns="http://www.w3.org/2000/svg"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.0083 6C10.474 6 6 10.5833 6 16.2535C6 20.786 8.86662 24.6226 12.8434 25.9805C13.3406 26.0826 13.5227 25.7599 13.5227 25.4885C13.5227 25.2508 13.5063 24.436 13.5063 23.587C10.7222 24.1983 10.1425 22.3647 10.1425 22.3647C9.69508 21.1764 9.03215 20.871 9.03215 20.871C8.12092 20.2429 9.09852 20.2429 9.09852 20.2429C10.1093 20.3108 10.6397 21.2954 10.6397 21.2954C11.5343 22.857 12.9759 22.4158 13.5559 22.1441C13.6386 21.482 13.9039 21.0237 14.1856 20.7691C11.9651 20.5314 9.62891 19.6487 9.62891 15.7102C9.62891 14.5898 10.0263 13.6731 10.6561 12.9602C10.5567 12.7056 10.2087 11.6529 10.7556 10.2439C10.7556 10.2439 11.6007 9.97228 13.5061 11.2964C14.3219 11.072 15.1632 10.9578 16.0083 10.9569C16.8533 10.9569 17.7148 11.0758 18.5102 11.2964C20.4159 9.97228 21.2609 10.2439 21.2609 10.2439C21.8079 11.6529 21.4596 12.7056 21.3603 12.9602C22.0066 13.6731 22.3876 14.5898 22.3876 15.7102C22.3876 19.6487 20.0514 20.5143 17.8143 20.7691C18.179 21.0916 18.4936 21.7026 18.4936 22.6703C18.4936 24.0453 18.4773 25.1489 18.4773 25.4883C18.4773 25.7599 18.6596 26.0826 19.1566 25.9808C23.1333 24.6224 26 20.786 26 16.2535C26.0163 10.5833 21.526 6 16.0083 6Z"
              fill="currentColor"
            />
          </svg>
        </a>
      </div>
    </div>
    <div className="flex gap-8 items-center">
      <div className={`hover:bg-gray-900/5 dark:hover:bg-gray-700/20 rounded-full cursor-pointer p-3 ${
              isDarkMode ? "dark" : ""
            }`}
            onClick={toggleDarkMode}
          >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="inline dark:hidden"
        >
          <path d="M12 3a6 6 0 0 0 9 9 9 9 0 1 1-9-9Z" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={20}
          height={20}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="hidden dark:inline"
        >
          <circle cx={12} cy={12} r={4} />
          <path d="M12 2v2" />
          <path d="M12 20v2" />
          <path d="m4.93 4.93 1.41 1.41" />
          <path d="m17.66 17.66 1.41 1.41" />
          <path d="M2 12h2" />
          <path d="M20 12h2" />
          <path d="m6.34 17.66-1.41 1.41" />
          <path d="m19.07 4.93-1.41 1.41" />
        </svg>
      </div>
    </div>
  </div>
  <div className="flex justify-between items-center border-t border-gray-900 border-opacity-5 dark:border-white dark:border-opacity-5 py-6 mt-3 mb-20">
    <div className="flex flex-col gap-2 md:flex-row md:gap-5">
      <Link to="/docs" className="hover:underline">
        Documentation
      </Link>
      <Link to="/security" className="hover:underline">
        Security
      </Link>
      <Link to="/brand" className="hover:underline">
        Brand Kit
      </Link>
      <Link to="/tokenlist" className="hover:underline">
        Tokens
      </Link>
    </div>
    <div className="self-start">
      <div className="w-fit" data-testid="flowbite-tooltip-target">
        <button
          type="button"
          onClick={mirrorToggle}
          className="text-gray-900 bg-transparent border border-gray-900 border-opacity-10 hover:bg-transpaorent hover:border-opacity-20 disabled:hover:bg-transparent dark:bg-transparent dark:text-white dark:border-white dark:border-opacity-10 dark:hover:bg-transparent dark:hover:border-white dark:hover:border-opacity-20 focus:!ring-2 group flex h-min items-center justify-center p-0.5 text-center font-medium focus:z-10 rounded-lg"
        >
          <span className="flex items-center rounded-md text-xs px-2 py-1">
            Mirrors
            <svg
              stroke="currentColor"
              fill="none"
              strokeWidth={2}
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="ml-1.5 h-3 w-3"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15 19l-7-7 7-7"
              />
            </svg>
          </span>
        </button>
      </div>
      <div
        data-testid="flowbite-tooltip"
        tabIndex={-1}
        className={`z-30 w-fit rounded-lg divide-y divide-gray-100 shadow-sm transition-opacity duration-100 ${
          isMirrorToggle ? "" : "invisible opacity-0"
        } bg-white text-gray-900 dark:bg-gray-900 border border-neutral-200 dark:border-gray-700 dark:border-opacity-70 dark:text-white rounded`}
        id=":r186:"
        role="tooltip"
        style={{ position: "absolute", top: 569, left: "503.352px" }}
      >
        <div className="text-sm text-gray-700 dark:text-gray-200">
          <ul className="py-1">
            <div className="px-4 py-3 max-w-min text-gray-500">
              <div className="border-b border-gray-100 dark:border-gray-800 w-48 pb-4 pr-10 text-xs">
                Available centralized (web2) and decentralized (web3) website
                mirrors.
              </div>
              <div className="pt-4 pb-4 text-xs">Centralized:</div>
              <div className="space-y-1">
                <div className="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
                  <a
                    href="https://velodrome.finance"
                    target="_blank"
                    className="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                    rel="noreferrer"
                  >
                    velodrome.finance
                  </a>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                    <polyline points="15 3 21 3 21 9" />
                    <line x1={10} x2={21} y1={14} y2={3} />
                  </svg>
                </div>
                <div className="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
                  <a
                    href="https://alt.velodrome.finance"
                    target="_blank"
                    className="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                    rel="noreferrer"
                  >
                    alt.velodrome.finance
                  </a>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                    <polyline points="15 3 21 3 21 9" />
                    <line x1={10} x2={21} y1={14} y2={3} />
                  </svg>
                </div>
              </div>
              <div className="pt-6 pb-4 text-xs">Decentralized (IPFS):</div>
              <div className="space-y-1">
                <div className="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
                  <a
                    href="https://velo.drome.eth"
                    target="_blank"
                    className="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                    rel="noreferrer"
                  >
                    velo.drome.eth
                  </a>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                    <polyline points="15 3 21 3 21 9" />
                    <line x1={10} x2={21} y1={14} y2={3} />
                  </svg>
                </div>
                <div className="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
                  <a
                    href="https://velo.drome.eth.limo"
                    target="_blank"
                    className="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                    rel="noreferrer"
                  >
                    velo.drome.eth.limo
                  </a>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                    <polyline points="15 3 21 3 21 9" />
                    <line x1={10} x2={21} y1={14} y2={3} />
                  </svg>
                </div>
                <div className="flex gap-2 items-center justify-between bg-gray-100 dark:bg-white/[.02] rounded px-2 py-1.5">
                  <a
                    href="https://velo.drome.eth.link"
                    target="_blank"
                    className="text-gray-600 dark:text-gray-400 no-underline hover:underline"
                    rel="noreferrer"
                  >
                    velo.drome.eth.link
                  </a>{" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={12}
                    height={12}
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth={2}
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="lucide lucide-external-link"
                  >
                    <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6" />
                    <polyline points="15 3 21 3 21 9" />
                    <line x1={10} x2={21} y1={14} y2={3} />
                  </svg>
                </div>
              </div>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="flex flex-col gap-6 lg:flex-row lg:gap-12 justify-between">
    <div className="flex flex-col gap-2 md:flex-row md:items-center">
      <div className="opacity-30">
        2023 © Velodrome Finance, v2.0.0beta+1d1040
      </div>
      <a className="hover:underline opacity-50" href="?legal=true">
        Legal Disclaimer
      </a>
    </div>
    <div className="flex flex-col gap-12 sm:flex-row sm:gap-6 sm:items-center">
      <div className="flex gap-3 items-center pr-2">
        <span className="opacity-30">A public good for</span>
        <a
          href="https://optimism.io/"
          target="_blank"
          rel="noreferrer"
          className="hover:opacity-70 flex gap-2 items-center font-bold italic"
        >
          <img src="/svg/op-logo.svg" className="w-5" />
          <span className="opacity-30">OPTIMISM</span>
        </a>
      </div>
    </div>
  </div>
</div>

  )
}

export default Footer;
