export const tokenList = [
    {
      name: "ACX",
      logoSrc: "https://assets.smold.app/api/token/10/0xFf733b2A3557a7ed6697007ab5D11B79FdD1b76B/logo.svg",
      address: "0xff733b2a3557a7ed6697007ab5d11b79fdd1b76b",
      decimals: 18,
      value: 0.14766,
      oracleQuote: "oracle quote per token"
    },
    {
      name: "BITCOIN",
      logoSrc: "https://assets.smold.app/api/token/10/0x8a6039fC7A479928B1d73f88040362e9C50Db275/logo.svg",
      address: "0x8a6039fc7a479928b1d73f88040362e9c50db275",
      decimals: 18,
      value: 0.073385,
      oracleQuote: "oracle quote per token"
    },
    {
      name: "BLU",
      logoSrc: "https://assets.smold.app/api/token/10/0xa50B23cDfB2eC7c590e84f403256f67cE6dffB84/logo.svg",
      address: "0xa50b23cdfb2ec7c590e84f403256f67ce6dffb84",
      decimals: 18,
      value: 0.00038,
      oracleQuote: "oracle quote per token"
    },
    {
        name: "BOB",
        logoSrc: "https://assets.smold.app/api/token/10/0xB0B195aEFA3650A6908f15CdaC7D92F8a5791B0B/logo.svg",
        address: "0xb0b195aefa3650a6908f15cdac7d92f8a5791b0b",
        decimals: 18,
        value: 0.99988,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "BeVELO",
        logoSrc: "https://assets.smold.app/api/token/10/0xfDeFFc7Ad816BF7867C642dF7eBC2CC5554ec265/logo.svg",
        address: "0xfdeffc7ad816bf7867c642df7ebc2cc5554ec265",
        decimals: 18,
        value: 0.01788,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "CHI",
        logoSrc: "https://assets.smold.app/api/token/10/0xCa0E54b636DB823847B29F506BFFEE743F57729D/logo.svg",
        address: "0xca0e54b636db823847b29f506bffee743f57729d",
        decimals: 18,
        value: 0.99654,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "DAI",
        logoSrc: "https://assets.smold.app/api/token/10/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1/logo.svg",
        address: "0xda10009cbd5d07dd0cecc66161fc93d7c9000da1",
        decimals: 18,
        value: 0.99954,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "DAI+",
        logoSrc: "https://assets.smold.app/api/token/10/0x970D50d09F3a656b43E11B0D45241a84e3a6e011/logo.svg",
        address: "0x970d50d09f3a656b43e11b0d45241a84e3a6e011",
        decimals: 18,
        value: 0.99331,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "DF",
        logoSrc: "https://assets.smold.app/api/token/10/0x9e5AAC1Ba1a2e6aEd6b32689DFcF62A509Ca96f3/logo.svg",
        address: "0x9e5aac1ba1a2e6aed6b32689dfcf62a509ca96f3",
        decimals: 18,
        value: 0.04038,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "DHT",
        logoSrc: "https://assets.smold.app/api/token/10/0xAF9fE3B5cCDAe78188B1F8b9a49Da7ae9510F151/logo.svg",
        address: "0xaf9fe3b5ccdae78188b1f8b9a49da7ae9510f151",
        decimals: 18,
        value: 0.10924,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "DOLA",
        logoSrc: "https://assets.smold.app/api/token/10/0x8aE125E8653821E851F12A49F7765db9a9ce7384/logo.svg",
        address: "0x8ae125e8653821e851f12a49f7765db9a9ce7384",
        decimals: 18,
        value: 0.96892,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "DUSD",
        logoSrc: "https://assets.smold.app/api/token/10/0xb396b31599333739A97951b74652c117BE86eE1D/logo.svg",
        address: "0xb396b31599333739a97951b74652c117be86ee1d",
        decimals: 18,
        value: 1.01,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "ERN",
        logoSrc: "https://assets.smold.app/api/token/10/0xc5b001DC33727F8F26880B184090D3E252470D45/logo.svg",
        address: "0xc5b001dc33727f8f26880b184090d3e252470d45",
        decimals: 18,
        value: 0.99348,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "ETH",
        logoSrc: "../tokens/ETH.svg",
        address: "eth",
        decimals: 18,
        value: 2334.19,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "EXA",
        logoSrc: "https://assets.smold.app/api/token/10/0x1e925De1c68ef83bD98eE3E130eF14a50309C01B/logo.svg",
        address: "0x1e925de1c68ef83bd98ee3e130ef14a50309c01b",
        decimals: 18,
        value: 1.13,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "EXTRA",
        logoSrc: "https://assets.smold.app/api/token/10/0x2dAD3a13ef0C6366220f989157009e501e7938F8/logo.svg",
        address: "0x2dad3a13ef0c6366220f989157009e501e7938f8",
        decimals: 18,
        value: 0.01922,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "FIS",
        logoSrc: "https://assets.smold.app/api/token/10/0xD8737CA46aa6285dE7B8777a8e3db232911baD41/logo.svg",
        address: "0xd8737ca46aa6285de7b8777a8e3db232911bad41",
        decimals: 18,
        value: 0.32966,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "FOX",
        logoSrc: "https://assets.smold.app/api/token/10/0xF1a0DA3367BC7aa04F8D94BA57B862ff37CeD174/logo.svg",
        address: "0xf1a0da3367bc7aa04f8d94ba57b862ff37ced174",
        decimals: 18,
        value: 0.0431,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "FRAX",
        logoSrc: "https://assets.smold.app/api/token/10/0x2E3D870790dC77A83DD1d18184Acc7439A53f475/logo.svg",
        address: "0x2e3d870790dc77a83dd1d18184acc7439a53f475",
        decimals: 18,
        value: 0.99883,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "FXS",
        logoSrc: "https://assets.smold.app/api/token/10/0x67CCEA5bb16181E7b4109c9c2143c24a1c2205Be/logo.svg",
        address: "0x67ccea5bb16181e7b4109c9c2143c24a1c2205be",
        decimals: 18,
        value: 8.85,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "GNode",
        logoSrc: "https://assets.smold.app/api/token/10/0x5976d4c3bcFc1c5f90aB1419D7f3dDF109cEA35a/logo.svg",
        address: "0x5976d4c3bcfc1c5f90ab1419d7f3ddf109cea35a",
        decimals: 18,
        value: 0.06716,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "GRAI",
        logoSrc: "https://assets.smold.app/api/token/10/0x894134a25a5faC1c2C26F1d8fBf05111a3CB9487/logo.svg",
        address: "0x894134a25a5fac1c2c26f1d8fbf05111a3cb9487",
        decimals: 18,
        value: 0.98967,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "GRAIN",
        logoSrc: "https://assets.smold.app/api/token/10/0xfD389Dc9533717239856190F42475d3f263a270d/logo.svg",
        address: "0xfd389dc9533717239856190f42475d3f263a270d",
        decimals: 18,
        value: 0.01317,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "HAUS",
        logoSrc: "/svg/coin.svg",
        address: "0x01b8b6384298d4848e3be63d4c9d17830eee488a",
        decimals: 18,
        value: 0.87579,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "HND",
        logoSrc: "https://assets.smold.app/api/token/10/0x10010078a54396F62c96dF8532dc2B4847d47ED3/logo.svg",
        address: "0x10010078a54396f62c96df8532dc2b4847d47ed3",
        decimals: 18,
        value: 0.00031,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "HOP",
        logoSrc: "https://assets.smold.app/api/token/10/0xc5102fE9359FD9a28f877a67E36B0F050d81a3CC/logo.svg",
        address: "0xc5102fe9359fd9a28f877a67e36b0f050d81a3cc",
        decimals: 18,
        value: 0.0547,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "IB",
        logoSrc: "https://assets.smold.app/api/token/10/0x00a35FD824c717879BF370E70AC6868b95870Dfb/logo.svg",
        address: "0x00a35fd824c717879bf370e70ac6868b95870dfb",
        decimals: 18,
        value: 1.25,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "JARVIS",
        logoSrc: "https://assets.smold.app/api/token/10/0x9F46A936BE9114bD2f71965a87C003341448b47E/logo.svg",
        address: "0x9f46a936be9114bd2f71965a87c003341448b47e",
        decimals: 18,
        value: 0.10637,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "JARVIS",
        logoSrc: "https://assets.smold.app/api/token/10/0x1574564fCFd15BCCB3FE04E9818F61131eA74066/logo.svg",
        address: "0x1574564fcfd15bccb3fe04e9818f61131ea74066",
        decimals: 18,
        value: 0.11451,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "JRT",
        logoSrc: "https://assets.smold.app/api/token/10/0x15e770B95Edd73fD96b02EcE0266247D50895E76/logo.svg",
        address: "0x15e770b95edd73fd96b02ece0266247d50895e76",
        decimals: 18,
        value: 0.00878,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "KUJI",
        logoSrc: "https://assets.smold.app/api/token/10/0x3A18dcC9745eDcD1Ef33ecB93b0b6eBA5671e7Ca/logo.svg",
        address: "0x3a18dcc9745edcd1ef33ecb93b0b6eba5671e7ca",
        decimals: 6,
        value: 3.6,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "KWENTA",
        logoSrc: "https://assets.smold.app/api/token/10/0x920Cf626a271321C151D027030D5d08aF699456b/logo.svg",
        address: "0x920cf626a271321c151d027030d5d08af699456b",
        decimals: 18,
        value: 154.22,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "LDO",
        logoSrc: "https://assets.smold.app/api/token/10/0xFdb794692724153d1488CcdBE0C56c252596735F/logo.svg",
        address: "0xfdb794692724153d1488ccdbe0c56c252596735f",
        decimals: 18,
        value: 2.33,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "LUSD",
        logoSrc: "https://assets.smold.app/api/token/10/0xc40F949F8a4e094D1b49a23ea9241D289B7b2819/logo.svg",
        address: "0xc40f949f8a4e094d1b49a23ea9241d289b7b2819",
        decimals: 18,
        value: 0.99366,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "LYRA",
        logoSrc: "https://assets.smold.app/api/token/10/0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb/logo.svg",
        address: "0x50c5725949a6f0c72e6c4a641f24049a917db0cb",
        decimals: 18,
        value: 0.1694,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "MAI",
        logoSrc: "https://assets.smold.app/api/token/10/0xdFA46478F9e5EA86d57387849598dbFB2e964b02/logo.svg",
        address: "0xdfa46478f9e5ea86d57387849598dbfb2e964b02",
        decimals: 18,
        value: 0.65018,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "MET",
        logoSrc: "https://assets.smold.app/api/token/10/0x9a2e53158e12BC09270Af10C16A466cb2b5D7836/logo.svg",
        address: "0x9a2e53158e12bc09270af10c16a466cb2b5d7836",
        decimals: 18,
        value: 0.80836,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "MIM",
        logoSrc: "https://assets.smold.app/api/token/10/0xB153FB3d196A8eB25522705560ac152eeEc57901/logo.svg",
        address: "0xb153fb3d196a8eb25522705560ac152eeec57901",
        decimals: 18,
        value: 0.99696,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "MMY",
        logoSrc: "https://assets.smold.app/api/token/10/0x47536F17F4fF30e64A96a7555826b8f9e66ec468/logo.svg",
        address: "0x47536f17f4ff30e64a96a7555826b8f9e66ec468",
        decimals: 18,
        value: 0.19426,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "MTA",
        logoSrc: "https://assets.smold.app/api/token/10/0x929B939f8524c3Be977af57A4A0aD3fb1E374b50/logo.svg",
        address: "0x929b939f8524c3be977af57a4a0ad3fb1e374b50",
        decimals: 18,
        value: 0.03158,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "NEXT",
        logoSrc: "https://assets.smold.app/api/token/10/0x58b9cB810A68a7f3e1E4f8Cb45D1B9B3c79705E8/logo.svg",
        address: "0x58b9cb810a68a7f3e1e4f8cb45d1b9b3c79705e8",
        decimals: 18,
        value: 0.2194,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "OATH",
        logoSrc: "https://assets.smold.app/api/token/10/0x39FdE572a18448F8139b7788099F0a0740f51205/logo.svg",
        address: "0x39fde572a18448f8139b7788099f0a0740f51205",
        decimals: 18,
        value: 0.03769,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "OATH",
        logoSrc: "https://assets.smold.app/api/token/10/0x00e1724885473B63bCE08a9f0a52F35b0979e35A/logo.svg",
        address: "0x00e1724885473b63bce08a9f0a52f35b0979e35a",
        decimals: 18,
        value: 0.03816,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "OP",
        logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
        address: "0x4200000000000000000000000000000000000042",
        decimals: 18,
        value: 2.15,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "OPP",
        logoSrc: "https://assets.smold.app/api/token/10/0x676f784d19c7F1Ac6C6BeaeaaC78B02a73427852/logo.svg",
        address: "0x676f784d19c7f1ac6c6beaeaac78b02a73427852",
        decimals: 18,
        value: 0.00007,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "OVN",
        logoSrc: "https://assets.smold.app/api/token/10/0x3b08fcd15280e7B5A6e404c4abb87F7C774D1B2e/logo.svg",
        address: "0x3b08fcd15280e7b5a6e404c4abb87f7c774d1b2e",
        decimals: 18,
        value: 20.86,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "OpenX",
        logoSrc: "https://assets.smold.app/api/token/10/0xc3864f98f2a61A7cAeb95b039D031b4E2f55e0e9/logo.svg",
        address: "0xc3864f98f2a61a7caeb95b039d031b4e2f55e0e9",
        decimals: 18,
        value: 0.03167,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "PENDLE",
        logoSrc: "https://assets.smold.app/api/token/10/0xBC7B1Ff1c6989f006a1185318eD4E7b5796e66E1/logo.svg",
        address: "0xbc7b1ff1c6989f006a1185318ed4e7b5796e66e1",
        decimals: 18,
        value: 1.24,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "PEPE",
        logoSrc: "https://assets.smold.app/api/token/10/0x12ff4a259e14D4DCd239C447D23C9b00F7781d8F/logo.svg",
        address: "0x12ff4a259e14d4dcd239c447d23c9b00f7781d8f",
        decimals: 18,
        value: 0.00002,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "PEPE",
        logoSrc: "/svg/coin.svg",
        address: "0xc1c167cc44f7923cd0062c4370df962f9ddb16f5",
        decimals: 18,
        value: 0,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "PERP",
        logoSrc: "https://assets.smold.app/api/token/10/0x9e1028F5F1D5eDE59748FFceE5532509976840E0/logo.svg",
        address: "0x9e1028f5f1d5ede59748ffcee5532509976840e0",
        decimals: 18,
        value: 0.71291,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "POOL",
        logoSrc: "https://assets.smold.app/api/token/10/0x395Ae52bB17aef68C2888d941736A71dC6d4e125/logo.svg",
        address: "0x395ae52bb17aef68c2888d941736a71dc6d4e125",
        decimals: 18,
        value: 0.67632,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "QI",
        logoSrc: "https://assets.smold.app/api/token/10/0x3F56e0c36d275367b8C502090EDF38289b3dEa0d/logo.svg",
        address: "0x3f56e0c36d275367b8c502090edf38289b3dea0d",
        decimals: 18,
        value: 0.00313,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "RED",
        logoSrc: "https://assets.smold.app/api/token/10/0x3417E54A51924C225330f8770514aD5560B9098D/logo.svg",
        address: "0x3417e54a51924c225330f8770514ad5560b9098d",
        decimals: 18,
        value: 0.6065,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "RING",
        logoSrc: "https://assets.smold.app/api/token/10/0x259c1C2ED264402b5ed2f02bc7dC25A15C680c18/logo.svg",
        address: "0x259c1c2ed264402b5ed2f02bc7dc25a15c680c18",
        decimals: 18,
        value: 0.00479,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "SNX",
        logoSrc: "https://assets.smold.app/api/token/10/0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4/logo.svg",
        address: "0x8700daec35af8ff88c16bdf0418774cb3d7599b4",
        decimals: 18,
        value: 4.07,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "SONNE",
        logoSrc: "https://assets.smold.app/api/token/10/0x1DB2466d9F5e10D7090E7152B68d62703a2245F0/logo.svg",
        address: "0x1db2466d9f5e10d7090e7152b68d62703a2245f0",
        decimals: 18,
        value: 0.07247,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "SPELL",
        logoSrc: "https://assets.smold.app/api/token/10/0xE3AB61371ECc88534C522922a026f2296116C109/logo.svg",
        address: "0xe3ab61371ecc88534c522922a026f2296116c109",
        decimals: 18,
        value: 0.00001,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "STG",
        logoSrc: "https://assets.smold.app/api/token/10/0x296F55F8Fb28E498B858d0BcDA06D955B2Cb3f97/logo.svg",
        address: "0x296f55f8fb28e498b858d0bcda06d955b2cb3f97",
        decimals: 18,
        value: 0.55241,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "T",
        logoSrc: "https://assets.smold.app/api/token/10/0x747e42Eb0591547a0ab429B3627816208c734EA7/logo.svg",
        address: "0x747e42eb0591547a0ab429b3627816208c734ea7",
        decimals: 18,
        value: 0.02541,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "TAROT",
        logoSrc: "https://assets.smold.app/api/token/10/0x375488F097176507e39B9653b88FDc52cDE736Bf/logo.svg",
        address: "0x375488f097176507e39b9653b88fdc52cde736bf",
        decimals: 18,
        value: 0.04596,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "TAROT",
        logoSrc: "https://assets.smold.app/api/token/10/0x1F514A61bcde34F94Bc39731235690ab9da737F7/logo.svg",
        address: "0x1f514a61bcde34f94bc39731235690ab9da737f7",
        decimals: 18,
        value: 0.04781,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "THALES",
        logoSrc: "https://assets.smold.app/api/token/10/0x217D47011b23BB961eB6D93cA9945B7501a5BB11/logo.svg",
        address: "0x217d47011b23bb961eb6d93ca9945b7501a5bb11",
        decimals: 18,
        value: 0.43385,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "TKN",
        logoSrc: "https://assets.smold.app/api/token/10/0x3Eb398fEc5F7327C6b15099a9681d9568ded2e82/logo.svg",
        address: "0x3eb398fec5f7327c6b15099a9681d9568ded2e82",
        decimals: 18,
        value: 0.0442,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "TROVE",
        logoSrc: "https://assets.smold.app/api/token/10/0x348Fdfe2c35934A96C1353185F09D0F9efBAdA86/logo.svg",
        address: "0x348fdfe2c35934a96c1353185f09d0f9efbada86",
        decimals: 18,
        value: 0.01661,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "UNIDX",
        logoSrc: "https://assets.smold.app/api/token/10/0x28b42698Caf46B4B012CF38b6C75867E0762186D/logo.svg",
        address: "0x28b42698caf46b4b012cf38b6c75867e0762186d",
        decimals: 18,
        value: 2.75,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "UNLOCK",
        logoSrc: "https://assets.smold.app/api/token/10/0x7aE97042a4A0eB4D1eB370C34BfEC71042a056B7/logo.svg",
        address: "0x7ae97042a4a0eb4d1eb370c34bfec71042a056b7",
        decimals: 18,
        value: 0.00869,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "USD+",
        logoSrc: "https://assets.smold.app/api/token/10/0x73cb180bf0521828d8849bc8CF2B920918e23032/logo.svg",
        address: "0x73cb180bf0521828d8849bc8cf2b920918e23032",
        decimals: 6,
        value: 0.99668,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "USDC",
        logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
        address: "0x7f5c764cbc14f9669b88837ca1490cca17c31607",
        decimals: 6,
        value: 0.999,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "USDC",
        logoSrc: "https://assets.smold.app/api/token/10/0x0b2C639c533813f4Aa9D7837CAf62653d097Ff85/logo.svg",
        address: "0x0b2c639c533813f4aa9d7837caf62653d097ff85",
        decimals: 6,
        value: 0.99937,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "USDGLO",
        logoSrc: "https://assets.smold.app/api/token/10/0x4F604735c1cF31399C6E711D5962b2B3E0225AD3/logo.svg",
        address: "0x4f604735c1cf31399c6e711d5962b2b3e0225ad3",
        decimals: 18,
        value: 0.9997,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "USDT",
        logoSrc: "https://assets.smold.app/api/token/10/0x94b008aA00579c1307B0EF2c499aD98a8ce58e58/logo.svg",
        address: "0x94b008aa00579c1307b0ef2c499ad98a8ce58e58",
        decimals: 6,
        value: 0.99996,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "USDV",
        logoSrc: "https://assets.smold.app/api/token/10/0x323665443CEf804A3b5206103304BD4872EA4253/logo.svg",
        address: "0x323665443cef804a3b5206103304bd4872ea4253",
        decimals: 6,
        value: 0.99813,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "USX",
        logoSrc: "https://assets.smold.app/api/token/10/0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9/logo.svg",
        address: "0xbfd291da8a403daaf7e5e9dc1ec0aceacd4848b9",
        decimals: 18,
        value: 0.99155,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "UTS",
        logoSrc: "/svg/coin.svg",
        address: "0x3b6564b5da73a41d3a66e6558a98fd0e9e1e77ad",
        decimals: 18,
        value: 0.20958,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "VELO",
        logoSrc: "https://assets.smold.app/api/token/10/0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db/logo.svg",
        address: "0x9560e827af36c94d2ac33a39bce1fe78631088db",
        decimals: 18,
        value: 0.05112,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "VITA",
        logoSrc: "https://assets.smold.app/api/token/10/0x7D14206C937E70e19E3A5B94011fAF0d5b3928e2/logo.svg",
        address: "0x7d14206c937e70e19e3a5b94011faf0d5b3928e2",
        decimals: 18,
        value: 3.12,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "WBTC",
        logoSrc: "https://assets.smold.app/api/token/10/0x68f180fcCe6836688e9084f035309E29Bf0A2095/logo.svg",
        address: "0x68f180fcce6836688e9084f035309e29bf0a2095",
        decimals: 8,
        value: 43453.67,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "WETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
        address: "0x4200000000000000000000000000000000000006",
        decimals: 18,
        value: 2334.19,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "WLD",
        logoSrc: "https://assets.smold.app/api/token/10/0xdC6fF44d5d932Cbd77B52E5612Ba0529DC6226F1/logo.svg",
        address: "0xdc6ff44d5d932cbd77b52e5612ba0529dc6226f1",
        decimals: 18,
        value: 2.58,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "YFX",
        logoSrc: "https://assets.smold.app/api/token/10/0x8901cB2e82CC95c01e42206F8d1F417FE53e7Af0/logo.svg",
        address: "0x8901cb2e82cc95c01e42206f8d1f417fe53e7af0",
        decimals: 18,
        value: 0.97934,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "agEUR",
        logoSrc: "https://assets.smold.app/api/token/10/0x9485aca5bbBE1667AD97c7fE7C4531a624C8b1ED/logo.svg",
        address: "0x9485aca5bbbe1667ad97c7fe7c4531a624c8b1ed",
        decimals: 18,
        value: 1.08,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "alETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x3E29D3A9316dAB217754d13b28646B76607c5f04/logo.svg",
        address: "0x3e29d3a9316dab217754d13b28646b76607c5f04",
        decimals: 18,
        value: 2040.2,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "alUSD",
        logoSrc: "https://assets.smold.app/api/token/10/0xCB8FA9a76b8e203D8C3797bF438d8FB81Ea3326A/logo.svg",
        address: "0xcb8fa9a76b8e203d8c3797bf438d8fb81ea3326a",
        decimals: 18,
        value: 0.99141,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "axlUSDC",
        logoSrc: "https://assets.smold.app/api/token/10/0xEB466342C4d449BC9f53A865D5Cb90586f405215/logo.svg",
        address: "0xeb466342c4d449bc9f53a865d5cb90586f405215",
        decimals: 6,
        value: 0.33786,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "fBOMB",
        logoSrc: "/svg/coin.svg",
        address: "0x74ccbe53f77b08632ce0cb91d3a545bf6b8e0979",
        decimals: 18,
        value: 0.00409,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "frxETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x6806411765Af15Bddd26f8f544A34cC40cb9838B/logo.svg",
        address: "0x6806411765af15bddd26f8f544a34cc40cb9838b",
        decimals: 18,
        value: 2333.01,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "jEUR",
        logoSrc: "https://assets.smold.app/api/token/10/0x79AF5dd14e855823FA3E9ECAcdF001D99647d043/logo.svg",
        address: "0x79af5dd14e855823fa3e9ecacdf001d99647d043",
        decimals: 18,
        value: 1.08,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "mooBIFI",
        logoSrc: "https://assets.smold.app/api/token/10/0xc55E93C62874D8100dBd2DfE307EDc1036ad5434/logo.svg",
        address: "0xc55e93c62874d8100dbd2dfe307edc1036ad5434",
        decimals: 18,
        value: 386.36,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "msETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x1610e3c85dd44Af31eD7f33a63642012Dca0C5A5/logo.svg",
        address: "0x1610e3c85dd44af31ed7f33a63642012dca0c5a5",
        decimals: 18,
        value: 2333.47,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "msOP",
        logoSrc: "https://assets.smold.app/api/token/10/0x33bCa143d9b41322479E8d26072a00a352404721/logo.svg",
        address: "0x33bca143d9b41322479e8d26072a00a352404721",
        decimals: 18,
        value: 2.15,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "msUSD",
        logoSrc: "https://assets.smold.app/api/token/10/0x9dAbAE7274D28A45F0B65Bf8ED201A5731492ca0/logo.svg",
        address: "0x9dabae7274d28a45f0b65bf8ed201a5731492ca0",
        decimals: 18,
        value: 0.99749,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "opxVELO",
        logoSrc: "https://assets.smold.app/api/token/10/0xc38464250F51123078BBd7eA574E185F6623d037/logo.svg",
        address: "0xc38464250f51123078bbd7ea574e185f6623d037",
        decimals: 18,
        value: 0.04769,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "opxveVELO",
        logoSrc: "https://assets.smold.app/api/token/10/0x46f21fDa29F1339e0A543763FF683D399e393eC/logo.svg",
        address: "0x46f21fda29f1339e0ab543763ff683d399e393ec",
        decimals: 18,
        value: 0.04233,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "rETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x9Bcef72be871e61ED4fBbc7630889beE758eb81D/logo.svg",
        address: "0x9bcef72be871e61ed4fbbc7630889bee758eb81d",
        decimals: 18,
        value: 2557.21,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "rETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x6c2f7b6110a37b3B0fbdd811876be368df02E8B0/logo.svg",
        address: "0x6c2f7b6110a37b3b0fbdd811876be368df02e8b0",
        decimals: 18,
        value: 2432.98,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "sETH",
        logoSrc: "https://assets.smold.app/api/token/10/0xE405de8F52ba7559f9df3C368500B6E6ae6Cee49/logo.svg",
        address: "0xe405de8f52ba7559f9df3c368500b6e6ae6cee49",
        decimals: 18,
        value: 2333.7,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "sEUR",
        logoSrc: "https://assets.smold.app/api/token/10/0xFBc4198702E81aE77c06D58f81b629BDf36f0a71/logo.svg",
        address: "0xfbc4198702e81ae77c06d58f81b629bdf36f0a71",
        decimals: 18,
        value: 0,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "sUSD",
        logoSrc: "https://assets.smold.app/api/token/10/0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9/logo.svg",
        address: "0x8c6f28f2f1a3c87f0f938b96d27520d9751ec8d9",
        decimals: 18,
        value: 1,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "sfrxETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x484c2D6e3cDd945a8B2DF735e079178C1036578c/logo.svg",
        address: "0x484c2d6e3cdd945a8b2df735e079178c1036578c",
        decimals: 18,
        value: 2482.33,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "stERN",
        logoSrc: "https://assets.smold.app/api/token/10/0x3eE6107d9C93955acBb3f39871D32B02F82B78AB/logo.svg",
        address: "0x3ee6107d9c93955acbb3f39871d32b02f82b78ab",
        decimals: 18,
        value: 1.03,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "tBTC",
        logoSrc: "https://assets.smold.app/api/token/10/0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40/logo.svg",
        address: "0x6c84a8f1c29108f47a79964b5fe888d4f4d0de40",
        decimals: 18,
        value: 43397.54,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "wOptiDoge",
        logoSrc: "/svg/coin.svg",
        address: "0xc26921b5b9ee80773774d36c84328ccb22c3a819",
        decimals: 18,
        value: 0,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "wTBT",
        logoSrc: "https://assets.smold.app/api/token/10/0xdb4eA87fF83eB1c80b8976FC47731Da6a31D35e5/logo.svg",
        address: "0xdb4ea87ff83eb1c80b8976fc47731da6a31d35e5",
        decimals: 18,
        value: 0.99012,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "wUSDR",
        logoSrc: "https://assets.smold.app/api/token/10/0x340fE1D898ECCAad394e2ba0fC1F93d27c7b717A/logo.svg",
        address: "0x340fe1d898eccaad394e2ba0fc1f93d27c7b717a",
        decimals: 9,
        value: 0.52028,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "wUSDR",
        logoSrc: "https://assets.smold.app/api/token/10/0xC03b43d492d904406db2d7D57e67C7e8234bA752/logo.svg",
        address: "0xc03b43d492d904406db2d7d57e67c7e8234ba752",
        decimals: 9,
        value: 0.59993,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "wstETH",
        logoSrc: "https://assets.smold.app/api/token/10/0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb/logo.svg",
        address: "0x1f32b1c2345538c0c6f582fcb022739c4a194ebb",
        decimals: 18,
        value: 2678.29,
        oracleQuote: "oracle quote per token"
      },
      {
        name: "xOpenX",
        logoSrc: "https://assets.smold.app/api/token/10/0x2513486f18eeE1498D7b6281f668B955181Dd0D9/logo.svg",
        address: "0x2513486f18eee1498d7b6281f668b955181dd0d9",
        decimals: 18,
        value: 0.18049,
        oracleQuote: "oracle quote per token"
      }
  ];
