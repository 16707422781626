export const poolData = [
    {
      poolName: "sAMMV2-USDC/sUSD",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "sUSD",
          logoSrc: "https://assets.smold.app/api/token/10/0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9/logo.svg",
          address: "0x8c6f28f2F1A3C87F0f938b96d27520d9751ec8d9",
        },
      ],
      details: {
        apr: 2.64,
        fee: 0.05,
        tvl: 8000247.11,
        volume: 6351539.5,
        fees: "~$3,175.77",
        poolBalance: {
          token1: "3,405,125.66",
          token2: "4,633,798.9",
        },
      },
    },
    {
      poolName: "sAMMV2-msETH/WETH",
      type: "stable",
      tokens: [
        {
          name: "msETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x1610e3c85dd44Af31eD7f33a63642012Dca0C5A5/logo.svg",
          address: "0x1610e3c85dd44Af31eD7f33a63642012Dca0C5A5",
        },
        {
          name: "WETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
          address: "0x4200000000000000000000000000000000000006",
        },
      ],
      details: {
        apr: 16.93,
        fee: 0.05,
        tvl: 6721318.72,
        volume: 1027346.47,
        fees: "~$513.67",
        poolBalance: {
          token1: "1,319.07",
          token2: "1,761.45",
        },
      },
    },
    {
      poolName: "vAMMV2-STG/USDC",
      type: "volatile",
      tokens: [
        {
          name: "STG",
          logoSrc: "https://assets.smold.app/api/token/10/0x296F55F8Fb28E498B858d0BcDA06D955B2Cb3f97/logo.svg",
          address: "0x296F55F8Fb28E498B858d0BcDA06D955B2Cb3f97",
        },
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
      ],
      details: {
        apr: 13.78,
        fee: 0.3,
        tvl: 575249024,
        volume: 1918831.06,
        fees: "~$5,756.49",
        poolBalance: {
          token1: "5,818,942.9",
          token2: "2,877,683.9",
        },
      },
    },
    {
      poolName: "sAMMV2-alETH/WETH",
      type: "stable",
      tokens: [
        {
          name: "alETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x3E29D3A9316dAB217754d13b28646B76607c5f04/logo.svg",
          address: "0x3E29D3A9316dAB217754d13b28646B76607c5f04",
        },
        {
          name: "WETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
          address: "0x4200000000000000000000000000000000000006",
        },
      ],
      details: {
        apr: 8.27,
        fee: 0.05,
        tvl: 5423584.97,
        volume: 389290.49,
        fees: "~$194.65",
        poolBalance: {
          token1: "1,700.06",
          token2: "892.38",
        },
      },
    },
    {
      poolName: "sAMMV2-USDC/alUSD",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "alUSD",
          logoSrc: "https://assets.smold.app/api/token/10/0xCB8FA9a76b8e203D8C3797bF438d8FB81Ea3326A/logo.svg",
          address: "0xCB8FA9a76b8e203D8C3797bF438d8FB81Ea3326A",
        },
      ],
      details: {
        apr: 13.92,
        fee: 0.05,
        tvl: 5070956.14,
        volume: 382885.09,
        fees: "~$191.44",
        poolBalance: {
          token1: "2,146,801.42",
          token2: "2,950,834.66",
        },
      },
    },
    {
      poolName: "vAMMV2-WETH/OP",
      type: "volatile",
      tokens: [
        {
          name: "WETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
          address: "0x4200000000000000000000000000000000000006",
        },
        {
          name: "OP",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
          address: "0x4200000000000000000000000000000000000042",
        },
      ],
      details: {
        apr: 7.28,
        fee: 0.3,
        tvl: 4889441.04,
        volume: 1575495.63,
        fees: "~$4,726.49",
        poolBalance: {
          token1: "1.06",
          token2: "1,158.77",
        },
      },
    },
    {
      poolName: "vAMMV2-OP/USDC",
      type: "volatile",
      tokens: [
        {
          name: "OP",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
          address: "0x4200000000000000000000000000000000000042",
        },
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
      ],
      details: {
        apr: 17.35,
        fee: 0.3,
        tvl: 4834757.59,
        volume: 2266755.73,
        fees: "~$6,800.27",
        poolBalance: {
          token1: "1,155,446.47",
          token2: "2,418,588.03",
        },
      },
    },
    {
      poolName: "vAMMV2-wstETH/WETH",
      type: "volatile",
      tokens: [
        {
          name: "wstETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb/logo.svg",
          address: "0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb",
        },
        {
          name: "WETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
          address: "0x4200000000000000000000000000000000000006",
        },
      ],
      details: {
        apr: 1.59,
        fee: 0.05,
        tvl: 4827285.93,
        volume: 847143.67,
        fees: "~$423.57",
        poolBalance: {
          token1: "966.09",
          token2: "1,108.78",
        },
      },
    },
    {
      poolName: "vAMMV2-USDC/VELO",
      type: "volatile",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "VELO",
          logoSrc: "https://assets.smold.app/api/token/10/0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db/logo.svg",
          address: "0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db",
        },
      ],
      details: {
        apr: 61.49,
        fee: 0.01,
        tvl: 4796250.79,
        volume: 1227168.27,
        fees: "~$12,271.68",
        poolBalance: {
          token1: "2,399,317.72",
          token2: "52,265,381.95",
        },
      },
    },
    {
      poolName: "sAMMV2-USDC/DOLA",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "DOLA",
          logoSrc: "https://assets.smold.app/api/token/10/0x8aE125E8653821E851F12A49F7765db9a9ce7384/logo.svg",
          address: "0x8aE125E8653821E851F12A49F7765db9a9ce7384",
        },
      ],
      details: {
        apr: 16.73,
        fee: 0.3,
        tvl: 3458905.75,
        volume: 137782.02,
        fees: "~$413.35",
        poolBalance: {
          token1: "1,430,345.87",
          token2: "2,060,163.31",
        },
      },
    },
    {
      poolName: "vAMMV2-USDC/VELO",
      type: "volatile",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "VELO",
          logoSrc: "https://assets.smold.app/api/token/10/0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db/logo.svg",
          address: "0x9560e827aF36c94D2Ac33a39bCE1Fe78631088Db",
        },
      ],
      details: {
        apr: 61.49,
        fee: 0.3, // Assuming it's missing or zero, as it wasn't provided
        tvl: 4796250.79,
        volume: 1227168.27,
        fees: "~$12,271.68",
        poolBalance: {
          token1: "2,399,317.72",
          token2: "52,265,381.95",
        },
      },
    },
    {
      poolName: "sAMMV2-USDC/DOLA",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "DOLA",
          logoSrc: "https://assets.smold.app/api/token/10/0x8aE125E8653821E851F12A49F7765db9a9ce7384/logo.svg",
          address: "0x8aE125E8653821E851F12A49F7765db9a9ce7384",
        },
      ],
      details: {
        apr: 16.73,
        fee: 0.3,
        tvl: 3458905.75,
        volume: 137782.02,
        fees: "~$413.35",
        poolBalance: {
          token1: "1,430,345.87",
          token2: "2,060,163.31",
        },
      },
    },
    {
      poolName: "vAMMV2-WETH/USDC",
      type: "volatile",
      tokens: [
        {
          name: "WETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
          address: "0x4200000000000000000000000000000000000006",
        },
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
      ],
      details: {
        fee: 0.3,
        apr: 10.89,
        tvl: 3324756.77,
        volume: 1459399.62,
        fees: "~$4,378.2",
        poolBalance: {
          token1: "764.44 WETH",
          token2: "1,662,354.24 USDC",
        },
      },
    },
    {
      poolName: "vAMMV2-wstETH/OP",
      type: "volatile",
      tokens: [
        {
          name: "wstETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb/logo.svg",
          address: "0x1F32b1c2345538c0c6f582fCB022739c4A194Ebb",
        },
        {
          name: "OP",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000042/logo.svg",
          address: "0x4200000000000000000000000000000000000042",
        },
      ],
      details: {
        apr: 6.46,
        fee: 0.3,
        tvl: 3280879.28,
        volume: 717664.56,
        fees: "~$2,152.99",
        poolBalance: {
          token1: "656.76",
          token2: "783,512.96",
        },
      },
    },
    {
      poolName: "sAMMV2-USDV/USDC",
      type: "stable",
      tokens: [
        {
          name: "USDV",
          logoSrc: "https://assets.smold.app/api/token/10/0x323665443CEf804A3b5206103304BD4872EA4253/logo.svg",
          address: "0x323665443CEf804A3b5206103304BD4872EA4253",
        },
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
      ],
      details: {
        apr: 21.22,
        fee: 0.05,
        tvl: 3180802.58,
        volume: 556134.92,
        fees: "~$278.07",
        poolBalance: {
          token1: "1,739,659.75",
          token2: "1,446,176.12",
        },
      },
    },
    {
      poolName: "sAMMV2-USDC/MAI",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "MAI",
          logoSrc: "https://assets.smold.app/api/token/10/0xdFA46478F9e5EA86d57387849598dbFB2e964b02/logo.svg",
          address: "0xdFA46478F9e5EA86d57387849598dbFB2e964b02",
        },
      ],
      details: {
        apr: 24.09,
        fee: 0.3,
        tvl: 3015625.33,
        volume: 215551.28,
        fees: "~$646.65",
        poolBalance: {
          token1: "852,250.99",
          token2: "3,263,432.05",
        },
      },
    },
    {
      poolName: "vAMMV2-USDC/wUSDR",
      type: "volatile",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "wUSDR",
          logoSrc: "https://assets.smold.app/api/token/10/0xC03b43d492d904406db2d7D57e67C7e8234bA752/logo.svg",
          address: "0xC03b43d492d904406db2d7D57e67C7e8234bA752",
        },
      ],
      details: {
        apr: 10.85,
        fee: 1,
        tvl: 2398746.74,
        volume: 194198.63,
        fees: "~$1,941.99",
        poolBalance: {
          token1: "1,199,973.33",
          token2: "2,011,743.02",
        },
      },
    },
    {
      poolName: "sAMMV2-USDC/USX",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "USX",
          logoSrc: "https://assets.smold.app/api/token/10/0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9/logo.svg",
          address: "0xbfD291DA8A403DAAF7e5E9DC1ec0aCEaCd4848B9",
        },
      ],
      details: {
        apr: 13.24,
        fee: 0.05,
        tvl: 2379049.02,
        volume: 125146.26,
        fees: "~$62.57",
        poolBalance: {
          token1: "937,223.61",
          token2: "1,475,970.46",
        },
      },
    },
    {
      poolName: "sAMMV2-WBTC/tBTC",
      type: "stable",
      tokens: [
        {
          name: "WBTC",
          logoSrc: "https://assets.smold.app/api/token/10/0x68f180fcCe6836688e9084f035309E29Bf0A2095/logo.svg",
          address: "0x68f180fcCe6836688e9084f035309E29Bf0A2095",
        },
        {
          name: "tBTC",
          logoSrc: "https://assets.smold.app/api/token/10/0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40/logo.svg",
          address: "0x6c84a8f1c29108F47a79964b5Fe888D4f4D0dE40",
        },
      ],
      details: {
        apr: 1.53,
        fee: 0.05,
        tvl: 2289208.85,
        volume: 105858.78,
        fees: "~$52.93",
        poolBalance: {
          token1: "25.18",
          token2: "30.61",
        },
      },
    },
    {
      poolName: "sAMMV2-USDC/msUSD",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "msUSD",
          logoSrc: "https://assets.smold.app/api/token/10/0x9dAbAE7274D28A45F0B65Bf8ED201A5731492ca0/logo.svg",
          address: "0x9dAbAE7274D28A45F0B65Bf8ED201A5731492ca0",
        },
      ],
      details: {
        apr: 20.91,
        fee: 0.05,
        tvl: 2268362.91,
        volume: 62514.4,
        fees: "~$31.26",
        poolBalance: {
          token1: "1,020,269.71",
          token2: "1,252,404.74",
        },
      },
    },
    {
      poolName: "vAMMV2-USDC/SNX",
      type: "volatile",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "SNX",
          logoSrc: "https://assets.smold.app/api/token/10/0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4/logo.svg",
          address: "0x8700dAec35aF8Ff88c16BdF0418774CB3D7599B4",
        },
      ],
      details: {
        apr: 31.84,
        fee: 0.3,
        tvl: 2114634.61,
        volume: 867939.75,
        fees: "~$2,603.82",
        poolBalance: {
          token1: "1,057,846.21",
          token2: "290,565.32",
        },
      },
    },
    {
      poolName: "vAMMV2-WETH/KWENTA",
      type: "volatile",
      tokens: [
        {
          name: "WETH",
          logoSrc: "https://assets.smold.app/api/token/10/0x4200000000000000000000000000000000000006/logo.svg",
          address: "0x4200000000000000000000000000000000000006",
        },
        {
          name: "KWENTA",
          logoSrc: "https://assets.smold.app/api/token/10/0x920Cf626a271321C151D027030D5d08aF699456b/logo.svg",
          address: "0x920Cf626a271321C151D027030D5d08aF699456b",
        },
      ],
      details: {
        apr: 53.31,
        fee: 0,
        tvl: 1813981.9,
        volume: 264492.64,
        fees: "~$2,644.93",
        poolBalance: {
          token1: "416.65",
          token2: "8,433.56",
        },
      },
    },
    {
      poolName: "sAMMV2-USDC/DAI",
      type: "stable",
      tokens: [
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
        {
          name: "DAI",
          logoSrc: "https://assets.smold.app/api/token/10/0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1/logo.svg",
          address: "0xDA10009cBd5D07dd0CeCc66161FC93D7c9000da1",
        },
      ],
      details: {
        apr: 5.7,
        fee: 0.05,
        tvl: 1672604.06,
        volume: 204421.49,
        fees: "~$102.21",
        poolBalance: {
          token1: "783,206.34",
          token2: "891,103.18",
        },
      },
    },
    {
      poolName: "vAMMV2-LYRA/USDC",
      type: "volatile",
      tokens: [
        {
          name: "LYRA",
          logoSrc: "https://assets.smold.app/api/token/10/0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb/logo.svg",
          address: "0x50c5725949A6F0c72E6C4a641F24049A917DB0Cb",
        },
        {
          name: "USDC",
          logoSrc: "https://assets.smold.app/api/token/10/0x7F5c764cBc14f9669B88837ca1490cCa17c31607/logo.svg",
          address: "0x7F5c764cBc14f9669B88837ca1490cCa17c31607",
        },
      ],
      details: {
        apr: 21.93,
        fee: 0.3,
        tvl: 1485568.55,
        volume: 688043.89,
        fees: "~$2,064.13",
        poolBalance: {
          token1: "5,823,807.35",
          token2: "743,155.83",
        },
      },
    }
    
  ];