import {
  useEffect,
  useRef,
  useState,
} from 'react';

import { useNavigate } from 'react-router-dom';

import { tokenList } from '../utils/tokendata';
import Tokens from './Tokens';

const Swap = ({Header, Footer }) => {
    const [isToggled, setIsToggled] = useState(false)
    const [selectedSwapToken, setSelectedSwapToken] = useState(tokenList.find(token => token.name === "VELO"))
    const [selectedForToken, setSelectedForToken] = useState(tokenList.find(token => token.name === "OP"))
    const [selectionType, setSelectionType] = useState('')
    const toggleTokenModal = () => {
        isToggled ? setIsToggled(!isToggled) : setIsToggled(isToggled)
    }
    const [outputAmount, setOutputAmount] = useState('')
    const swapAmount = useRef()

    const navigate = useNavigate()


    function calculateOutputAmount(swapAmount){
        return (swapAmount * (1/selectedForToken.value))/(1/selectedSwapToken.value)
    }

    useEffect(() => {
      if (swapAmount.current.value !== 0) {     
        const amount = calculateOutputAmount(swapAmount.current.value)
        setOutputAmount(amount)
      }
    }, [selectedSwapToken])

    useEffect(() => {
        if (selectedSwapToken && selectedForToken) {
          const fromTokenAddress = selectedSwapToken.address;
          const toTokenAddress = selectedForToken.address;
    
          navigate(`/swap?from=${fromTokenAddress}&to=${toTokenAddress}`);
        }
      }, [selectedSwapToken, selectedForToken, navigate]);


    return (
      <>
        <div>
          <div className="w-full mt-24 lg:mb-4 lg:w-1/4 z-[51] sm:bottom-0 fixed">
            <div className="flex flex-col" />
          </div>
          <div className="md:container md:mx-auto px-4 lg:px-8">
            {Header}
            <div className="md:bg-worm md:dark:bg-worm-light bg-contain lg:bg-auto bg-no-repeat bg-[center_bottom_0px]">
              <div className="pt-8 md:pt-16 md:pb-48">
                <div className="lg:max-w-screen-lg m-auto">
                  <div>
                    <div className="lg:flex gap-6">
                      <div className="lg:w-8/12 mb-4 lg:mb-0 bg-white dark:bg-gray-800 p-6 sm:p-12 rounded-lg shadow">
                        <div>
                          <div>
                            <div className="flex flex-col gap-1.5 sm:flex-row justify-between items-baseline mb-3">
                              <div className="text-sm text-gray-700 dark:text-gray-300">
                                Swap
                              </div>
                              <div className="text-xs opacity-80 text-right flex gap-3 items-center pb-1">
                                <button
                                  href=""
                                  color="light"
                                  className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300"
                                >
                                  <div className="flex gap-2 items-center"></div>
                                </button>
                              </div>
                            </div>
                            <div className="flex">
                              <span
                                onClick={() => {
                                  setSelectionType('swap');
                                  setIsToggled(!isToggled);
                                }}
                                className="inline-flex items-center px-3 hover:bg-neutral-50 dark:hover:bg-gray-850 rounded-l-md text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 border border-r-0 border-gray-300 dark:border-gray-600"
                              >
                                <div className="w-28 flex items-center justify-between cursor-pointer">
                                  <div className="flex gap-3 items-center">
                                    <img
                                      alt=""
                                      src={selectedSwapToken.logoSrc}
                                      className="w-5 h-5 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                                    />
                                    <div className="text-sm truncate">
                                      {selectedSwapToken.name}
                                    </div>
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={14}
                                    height={14}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-chevron-down"
                                  >
                                    <path d="m6 9 6 6 6-6" />
                                  </svg>
                                </div>
                              </span>
                              <div className="relative w-full">
                                <input
                                ref={swapAmount}
                                  className="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30 bg-transparent border-gray-300 text-gray-900 hover:border-opacity-70 dark:hover:border-opacity-70 focus:border-gray-400 dark:border-gray-600 dark:bg-transparent dark:text-white dark:focus:border-gray-500 outline-0 rounded-r-lg p-2.5 text-sm"
                                  required=""
                                  defaultValue={0.0}
                                  onInput={(e) => {
                                    const output = calculateOutputAmount(e.target.value)
                                    setOutputAmount(output)
                                  }
                                  }
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="mt-10 mb-6">
                          <div className="border-t border-gray-100 dark:border-gray-700 dark:border-opacity-50 flex justify-center text-gray-600 dark:text-gray-400">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth={2}
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="-mt-3 cursor-pointer rounded-lg bg-gray-100 dark:bg-gray-700 hover:bg-gray-200 dark:hover:bg-gray-600 p-1.5"
                              onClick={() => {
                                const swapToken = selectedSwapToken
                                const forToken = selectedForToken
                                setSelectedSwapToken(forToken)
                                setSelectedForToken(swapToken)
                              }}
                            >
                              <path d="m21 16-4 4-4-4" />
                              <path d="M17 20V4" />
                              <path d="m3 8 4-4 4 4" />
                              <path d="M7 4v16" />
                            </svg>
                          </div>
                        </div>
                        <div className="mb-1">
                          <div>
                            <div className="flex flex-col gap-1.5 sm:flex-row justify-between items-baseline mb-3">
                              <div className="text-sm text-gray-700 dark:text-gray-300">
                                For
                              </div>
                              <div className="text-xs opacity-80 text-right flex gap-3 items-center pb-1">
                                <button
                                  href=""
                                  color="light"
                                  className="text-gray-600 dark:text-gray-400 hover:text-gray-900 dark:hover:text-gray-300"
                                >
                                  <div className="flex gap-2 items-center"></div>
                                </button>
                              </div>
                            </div>
                            <div className="flex">
                              <span
                                onClick={() => {
                                  setSelectionType('for');
                                  setIsToggled(!isToggled);
                                }}
                                className="inline-flex items-center px-3 hover:bg-neutral-50 dark:hover:bg-gray-850 rounded-l-md text-gray-700 hover:text-gray-900 dark:text-gray-400 dark:hover:text-gray-300 border border-r-0 border-gray-300 dark:border-gray-600"
                              >
                                <div className="w-28 flex items-center justify-between cursor-pointer">
                                  <div className="flex gap-3 items-center">
                                    <img
                                      alt=""
                                      src={selectedForToken.logoSrc}
                                      className="w-5 h-5 rounded-full bg-gray-200 dark:bg-gray-700 hover:opacity-80"
                                    />
                                    <div className="text-sm truncate">
                                      {selectedForToken.name}
                                    </div>
                                  </div>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={14}
                                    height={14}
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth={2}
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="lucide lucide-chevron-down"
                                  >
                                    <path d="m6 9 6 6 6-6" />
                                  </svg>
                                </div>
                              </span>
                              <div className="relative w-full">
                                <input
                                  className="block w-full border disabled:cursor-not-allowed disabled:text-opacity-50 dark:disabled:text-opacity-30 bg-transparent border-gray-300 text-gray-900 hover:border-opacity-70 dark:hover:border-opacity-70 focus:border-gray-400 dark:border-gray-600 dark:bg-transparent dark:text-white dark:focus:border-gray-500 outline-0 rounded-r-lg p-2.5 text-sm"
                                  required=""
                                  disabled={true}
                                  placeholder={0.0}
                                  value={outputAmount}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="lg:w-6/12 p-6 sm:p-12 dark:bg-gray-800 bg-gray-50 bg-opacity-70 dark:bg-opacity-50 rounded-lg shadow flex">
                        <div className="w-full">
                          <div className="text-xl mb-4">Swap</div>
                          <div className="flex-col">
                            <div className="pt-2 pl-2">
                              <ol
                                data-testid="timeline-component"
                                className="relative border-l border-gray-200 dark:border-gray-700"
                              >
                                <li
                                  data-testid="timeline-item"
                                  className="mb-10 ml-6"
                                >
                                  <div
                                    data-testid="timeline-point"
                                    className=""
                                  >
                                    <span className="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                                      <div className="text-xs font-bold">1</div>
                                    </span>
                                  </div>
                                  <div
                                    data-testid="timeline-content"
                                    className=""
                                  >
                                    <time className="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                                      Start by selecting the token to{" "}
                                      <strong className="font-bold">
                                        Swap
                                      </strong>{" "}
                                      from and the amount you want to exchange
                                    </time>
                                  </div>
                                </li>
                                <li
                                  data-testid="timeline-item"
                                  className="mb-10 ml-6"
                                >
                                  <div
                                    data-testid="timeline-point"
                                    className=""
                                  >
                                    <span className="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                                      <div className="text-xs font-bold">2</div>
                                    </span>
                                  </div>
                                  <div
                                    data-testid="timeline-content"
                                    className=""
                                  >
                                    <time className="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                                      Pick the token you want to exchange{" "}
                                      <strong className="font-bold">For</strong>
                                    </time>
                                  </div>
                                </li>
                                <li
                                  data-testid="timeline-item"
                                  className="mb-10 ml-6 !mb-0"
                                >
                                  <div
                                    data-testid="timeline-point"
                                    className=""
                                  >
                                    <span className="space-x-2 absolute -left-3 flex h-6 w-6 items-center justify-center rounded-full bg-white ring-0 dark:ring-0 ring-white dark:bg-gray-900 dark:ring-gray-700">
                                      <div className="text-xs font-bold">3</div>
                                    </span>
                                  </div>
                                  <div
                                    data-testid="timeline-content"
                                    className=""
                                  >
                                    <time className="mb-1 text-sm font-normal leading-none text-gray-700 dark:text-gray-400">
                                      The quote will be ready in a moment!
                                    </time>
                                  </div>
                                </li>
                              </ol>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {Footer}
          </div>
        </div>
        {isToggled && (
          <Tokens
            isToggled={isToggled}
            setIsToggled={setIsToggled}
            selectionType={selectionType}
            setSelectedSwapToken={setSelectedSwapToken}
            setSelectedForToken={setSelectedForToken}
          />
        )}
      </>
    );};

export default Swap;